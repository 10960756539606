import { Component, OnInit, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { PkgFabMainService } from '../../services-internal/master-maintenance/pkgfabmain.service'
import { Router } from '@angular/router'
import { MessagePopupToastComponent } from 'src/app/core/message-popup-toast/message-popup-toast.component'
import { MatSnackBar } from '@angular/material/snack-bar'
import { NgxUiLoaderService } from 'ngx-ui-loader'

@Component({
  selector: 'app-add-pkg-fabricator',
  templateUrl: './add-pkg-fabricator.component.html',
  styleUrls: ['./add-pkg-fabricator.component.scss'],
})
export class AddPkgFabricatorComponent implements OnInit {
  nontrade: any[] = []
  supplierCode = []
  code = ''
  name = ''
  isFromDateError = false
  alphaCode = ''
  supplier: any
  lpFab: any
  modalHeader = ''
  buttonValue = ''
  Address = ''
  digits = ''
  selectedNamesString: any
  inputErrorMessage = ''
  inputErrorMessage2 = ''
  inputErrorMessage3 = ''
  inputErrorMessage4 = ''
  inputErrorMessage5 = ''
  inputErrorMessage6 = ''
  pass = false
  errorList: any[] = []
  codeReq= false
  alphaCodeReq=false;
  fabReq=false;
  digitReq=false;
  digitLen=false;
  coo="";
  countries:any = [];
  inputErrorMessage7: string = ''
  constructor(
    public pkgFabMainService: PkgFabMainService,
    public message: MatSnackBar,
    public dialogRef: MatDialogRef<AddPkgFabricatorComponent>,
    private readonly router: Router,
    private readonly spinnerService: NgxUiLoaderService,
    @Inject(MAT_DIALOG_DATA) public dataStore: any,
  ) {}
  ngOnInit(): void {
    this.nontrade = this.dataStore.commodity
    this.nontrade.forEach((item) => {
      item.selected = false;
    });
    if (this.dataStore.mode === 'ADD') {

      this.modalHeader = 'Add Package Fabricator'
      this.buttonValue = 'ADD';
      this.countries = this.dataStore.countries;
    }
    if (this.dataStore.mode === 'UPDATE') {
      this.modalHeader = 'Update Package Fabricator'
      this.buttonValue = 'UPDATE'
      this.code = this.dataStore.field.pkgsupcode
      this.Address = this.dataStore.field.address
      this.name = this.dataStore.field.name
      this.alphaCode = this.dataStore.field.alphacode
      this.lpFab = this.dataStore.field.lpflag
      this.digits = this.dataStore.field.supcode
      this.coo = this.dataStore.field.coo
      const array = this.dataStore.field.commodity.split('')
      this.selectedNamesString = array.join('');
      this.countries = this.dataStore.countires;
      array.forEach((value: any) => {
        this.nontrade.forEach((item) => {
          if (item.value === value) {
            item.selected = true;
          }
        });

      })
    }
  }
  
  validateInput() {
    if (!this.code) {
      this.codeReq=true;
      this.inputErrorMessage = 'Package Fabricator is mandatory.'
    } else {
      this.codeReq=false;
    }
  }
  validateInput2() {
    if (!this.alphaCode) {
      this.alphaCodeReq=true;
      this.inputErrorMessage2 = 'Alpha Code is mandatory.'
    } else {
      this.alphaCodeReq=false;
    }
  }
  validateInput3() {
    if (!this.name) {
      this.fabReq=true;
      this.inputErrorMessage3 = 'Package Fabricator Name is mandatory.'
    } else {
      this.fabReq=false;
    }
  }
  validateInput4() {
    if (!this.digits) {
      this.digitReq=true;
      this.inputErrorMessage4 = 'Digit is mandatory.'
    } else if (this.digits.length !== 5) {
      this.inputErrorMessage4 = 'Must be 5 characters.'
      this.digitReq=false;
      this.digitLen=true;
    } else {
      this.digitLen=false;
      this.inputErrorMessage4 = ''
    }
  }
  validateInput5() {
    if (!this.lpFab) {
      this.inputErrorMessage5 = 'LP is mandatory.'
    } else {
      this.inputErrorMessage5 = ''
    }
  }
  validateInput6() {
    if (!this.selectedNamesString) {
      this.inputErrorMessage6 = 'Commodity is mandatory.'
    } else {
      this.inputErrorMessage6 = ''
    }
  }
  validateInput7() {
    if (!this.coo) {
      this.inputErrorMessage7 = 'Country of Origin is mandatory.'
    } else {
      this.inputErrorMessage7 = ''
    }
  }
  addPackage() {
    this.errorList=[];
    this.validateInput()
    this.validateInput2()
    this.validateInput3()
    this.validateInput4()
    this.validateInput5()
    this.validateInput6()
    this.validateInput7()
    if (
      this.inputErrorMessage ||
      this.inputErrorMessage2 ||
      this.inputErrorMessage3 ||
      this.inputErrorMessage4 ||
      this.inputErrorMessage5 ||
      this.inputErrorMessage6 || this.inputErrorMessage7
    ) {
      return
    }

    const params = {
      PackageSupplierCode: this.code,
      AlphaCode: this.alphaCode,
      Name: this.name,
      SupplierCode: this.digits,
      LPFlag: this.lpFab,
      Address: this.Address,
      Commodities:this.selectedNamesString.replace(/,/g, ''),
      ScreenMode: this.dataStore.mode,
      countryOfOrigin : this.coo
    }
    this.spinnerService.start()
    this.pkgFabMainService.addExchangeRate(params).subscribe({
      next: (res: any) => {
        if (res.status === false) {
          this.errorList.push(res.message)
        } else {
          this.dialogRef.close(res.command)
        }
        this.spinnerService.stop()
      },
      error: (err:any) => {
        this.spinnerService.stop()
        this.errorList.push(err?.error?.error)
      },
    })
  }

  updatePackage() {
    this.errorList=[];
    this.validateInput()
    this.validateInput2()
    this.validateInput3()
    this.validateInput4()
    this.validateInput5()
    this.validateInput6();
    this.validateInput7();
    if (
      this.inputErrorMessage ||
      this.inputErrorMessage2 ||
      this.inputErrorMessage3 ||
      this.inputErrorMessage4 ||
      this.inputErrorMessage5 ||
      this.inputErrorMessage6 || this.inputErrorMessage7
    ) {
      return
    }
    const params = {
      PackageSupplierCode: this.code,
      OldPackageSupplierCode: this.dataStore.field.pkgsupcode,
      AlphaCode: this.alphaCode,
      Name: this.name,
      SupplierCode: this.digits,
      OldSupplierCode: this.dataStore.field.supcode,
      LPFlag: this.lpFab,
      Commodities:this.selectedNamesString.replace(/,/g, ''),
      ScreenMode: this.dataStore.mode,
      Address: this.Address,
      countryOfOrigin : this.coo
    }
    this.spinnerService.start()
    this.pkgFabMainService.updateExchangeRate(params).subscribe({
      next: (res: any) => {
        if (res.status === false) {
          this.errorList.push(res.message)
        } else {
          this.dialogRef.close({ status: res.status, message: res.message })
        }
        this.spinnerService.stop()
      },
      error: () => {
        this.spinnerService.stop()
      },
    })
  }
  openMessageBox(message: string, type: string) {
    this.message.openFromComponent(MessagePopupToastComponent, {
      duration: 3000,
      data: {
        type: type,
        message: message,
      },
    })
  }
  updateBtn() {
    if (this.buttonValue === 'ADD') {
      this.addPackage()
    }
    if (this.buttonValue === 'UPDATE') {
      this.updatePackage()
    }
  }

  close(): void {
    this.dialogRef.close({ event: 'CloseEvent' })
  }

  changeTradesByCategory() {
    const selectedNamesArray = this.nontrade
      .filter((item) => item.selected === true)
      .map((item) => item.value)
    this.selectedNamesString = selectedNamesArray.join('')
  }
  closeDialog() {
    this.dialogRef.close()
  }
  dialogData() {
    this.dialogRef.close()
  }
  help() {
    this.dialogRef.close()
    if (this.modalHeader === 'Add Package Fabricator'){
      this.router.navigate(['./master-help', 5, 'Master Maintenance', 122])
    }
    else if (this.modalHeader === 'Update Package Fabricator'){
      this.router.navigate(['./master-help', 5, 'Master Maintenance', 123])
    }
  }
}
