<div class="popup-container">
  <header>
    <button class="close black-button" (click)="closeDialog()">
      <mat-icon>close</mat-icon>
    </button>
    <div class="d-flex justify-content-between align-items-center header">
      <div class="page-title">{{modalHeader}} </div>
      <div>
        <button class="help-btn" (click)="help()">Help</button>
      </div>
    </div>
  </header>

  <div class="popup-contents">
    <div class="alert alert-danger m-2" *ngIf="errorList.length > 0">
      {{errorList}}
    </div>
    <div class=" row m-3 align-items-start">
      <div class="col-4">
        <div class="row">
          <div class="col-12">
            <label for="ringi" class="catlabel col-3">Code<small style="color:#ff0000">*</small></label>
            <input type="text" id="ringi" [(ngModel)]="code" maxlength="10" />
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="col-3 invisible"></div>
            <small *ngIf="codeReq">
              Package Fabricator is mandatory
            </small>
          </div>
        </div>
      </div>
      <div class="col-4">
        <div class="row">
          <div class="col-12">
            <label for="type" class="catlabel col-3">Alpha Code<small style="color:#ff0000">*</small></label>
            <input type="text" id="type" [(ngModel)]="alphaCode" maxlength="3" />
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="col-3 invisible"></div>
            <small *ngIf="alphaCodeReq">
              Alpha Code is mandatory
            </small>
          </div>
        </div>
      </div>
     
      <div class="col-4">
        <div class="row">
          <div class="col-12">
            <label for="plant" class="catlabel col-3">Name<small style="color:#ff0000">*</small></label>
            <input type="text" id="type" [(ngModel)]="name" maxlength="20" />
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="col-3 invisible"></div>
            <small *ngIf="fabReq">
              Package Fabricator Name is mandatory
            </small>
          </div>
        </div>
      </div>
    </div>
   
    <div class="row mx-3 mb-3 align-items-start col-12">
      <span class="col-2">Commodity<small style="color:#ff0000">*</small></span>

      <div class="d-flex flex-wrap">

        <ng-container *ngFor="let ntrd of nontrade">
          <label class="checkcontainer">
            <input (blur)="validateInput6()" type="checkbox" name="{{ ntrd.name }}" [(ngModel)]="ntrd.selected"
              (change)="changeTradesByCategory()" />{{ ntrd.value }} <span class="checkmark"></span>
          </label>
        </ng-container>
      </div>
      <div class="col-4 d-flex">
        <small *ngIf="inputErrorMessage6" class="small">{{inputErrorMessage6}}</small>
      </div>
    </div>
    <div class="row mx-3 align-items-start">
      <div class="col-4 d-flex">
        <label for="ringi" class="catlabel col-5">Address</label>
        <textarea id="ringi" style="overflow-wrap: break-word;" [(ngModel)]="Address" maxlength="100"></textarea>
      </div>

      <div class="col-4">
        <div class="row">
          <div class="col-12">
            <label for="type" class="catlabel col-3">5 digit<small style="color:#ff0000">*</small></label>
            <input type="text" id="type" [(ngModel)]="digits" maxlength="5" />
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="col-3 invisible"></div>
            <small *ngIf="digitReq">
              Digit is mandatory
            </small>
            <small *ngIf="!digitReq && digitLen">
              Must be 5 characters
            </small>
          </div>
        </div>
      </div>
      <div class="col-4">
        <div class="row">
          <div class="col-12">
            <label for="step" class="col-3">Country</label>
            <mat-select  class="mat-sel pt-2" disableOptionCentering placeholder="Select Country" [(ngModel)]="coo">
              <mat-option *ngFor="let s of countries" [value]="s.countryid">{{s.countryname}}</mat-option>
            </mat-select>
          </div>
        </div>
        <div class="col-4 d-flex">
          <small *ngIf="inputErrorMessage7" class="small">{{inputErrorMessage7}}</small>
        </div>
      </div>
    </div>
    <div class="row mx-3 align-items-start">
      <div class="col-4 d-flex">
        <span>LP<small style="color:#ff0000">*</small></span>
        <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group " [(ngModel)]="lpFab"
          (blur)="validateInput5()">
          <mat-radio-button class="example-radio-button" value="1">
            Yes
          </mat-radio-button>
          <mat-radio-button class="example-radio-button ms-2" value="0">
            No
          </mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="col-4 d-flex"></div>
      <div class="col-4 d-flex"></div>
    </div>
    <div class="row mx-3 mb-3 align-items-start">
      <div class="col-4 d-flex">
        <small *ngIf="inputErrorMessage5" class="small">{{inputErrorMessage5}}</small>
      </div>
    </div>
    <div class="footer d-flex justify-content-end align-items-center fixed-bottom mt-2 pt-2 pb-2">
      <button class="cancel me-3 roboto-bold" (click)="close()">CANCEL</button>
      <button class="submit black-button me-3 roboto-bold" (click)="updateBtn()">
        {{ buttonValue }}
      </button>
    </div>
  </div>
</div>