import { Component, OnInit, SecurityContext } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DialogDivisionComponent } from 'src/app/internal-vpacs/internal-vpacs-dialogs/master-maintenance/division-master/dialog-division/dialog-division.component';
import { PpfServiceInternal } from 'src/app/internal-vpacs/services-internal/ppf.service';
import { ConstantsService } from 'src/app/services/constant.service';
import { ExportStateService } from 'src/app/services/export-state.service';
import { convertAndCheck, toNumber,sumProduct, validate, HEADERS1, HEADERS4, UNITTABLE, setGridUnitView, INTERMEDIATETABLE, setNonEditableInter, setNonEditableGridPalletCase, safeToFixed } from '../../service-parts/servicepartsCalculations';
import { BreadcrumbComponent } from 'src/app/core/breadcrumb/breadcrumb.component';
import { DatePipe, Location, formatDate } from '@angular/common';
import * as FileSaver from 'file-saver-es';
import * as pdfMake from 'pdfmake/build/pdfmake';
import { getSPPF } from '../pdf';
import { ConfirmationComponent } from 'src/app/internal-vpacs/internal-vpacs-dialogs/auth-list/confirmation/confirmation.component';

@Component({
  selector: 'app-service-parts-new-ppf',
  templateUrl: './service-parts-new-ppf.component.html',
  styleUrls: ['./service-parts-new-ppf.component.scss'],
  providers: [BreadcrumbComponent]
})
export class ServicePartsNewPpfComponent implements OnInit {

  //grid1
  form1:any;
  mode = 1;
  headers1 = HEADERS1;
  pValue:string='';
  trackingUrl = 'tracking-list';
  readonly barePart = 'Bare Part';
  readonly polyBag = 'Poly Bag';
  readonly otherBag = 'Other Bag';
  readonly tubeBag = 'Tube Box';
  readonly BUBBLE_BAG = 'Bubble Bag';
  readonly BOTTLE_CAN = 'Bottle/Can';
  readonly SMALL_PARCEL_SHIPPING_BOX = 'Small Parcel Shipping Box';
  readonly BOX_LENGTH  = 'Box (Length ≤ 48")';
  readonly LARGE_BOX = 'Large Box (Length > 48")';
  readonly UN_CERTIFIED_BOX = 'UN Certified Box (Length ≤ 48\")';
  readonly WHITLAM_LABEL = 'Whitlam Label';
  readonly trackingList = this.trackingUrl
  columns1: number[] = [...Array(11).keys()];  
  rows1: number[] = [...Array(9).keys()];
  unitPackageType : any[] = [];
  unitTable = UNITTABLE;
  unitPackageData  = false;
  unitPackageMat = false;
  //grid2
  form2:any;
  columns2: number[] = [...Array(11).keys()];  
  rows2: number[] = [...Array(5).keys()];
  intermediateTable = INTERMEDIATETABLE
  interPackageType : any[] = [];
  isPkgKizen = false;

  inter = false;
  //grid3
  form3:any;
  columns3: number[] = [...Array(11).keys()];
  rows3: number[] = [...Array(9).keys()];  
  readonly blockStyle3048 = '30\" x 48\" Block Style Wood Pallet w/ Flush Bottom';
  readonly blockStyle4860 = '48\" x 60\" Block Style Wood Pallet w/ Flush Bottom';
  readonly blockStyle4890 = '48\" x 90\" Block Style Wood Pallet w/ Flush Bottom';
  readonly blockStyle48120 = '48\" x 120\" Block Style Wood  w/ Flush Bottom';
  readonly _blockstylewood = 'Block Style Wood Pallet Other list here-->';
  readonly U_RETURNABLE = 'U Returnable';
  readonly MU_RETURNABLE = 'MU Returnable';
  readonly HU_RETURNABLE = 'HU Returnable';
  readonly _2U_RETURNABLE = '2U Returnable';
  readonly  MINI_C3_RETURNABLE =  'Mini C3 Returnable';
  readonly C3_RETURNABLE = 'C3 Returnable';
  readonly C4_RETURNABLE = 'C4 Returnable';
  readonly WUL_RETURNABLE = 'WUL Returnable';
  readonly BCR_RETURNABLE = 'BCR Returnable';
  readonly U_MSTERPACK = 'U Masterpack';
  readonly MU_MSTERPACK = 'MU Masterpack';
  readonly HU_MSTERPACK = 'HU Masterpack';
  readonly TWOU_MASTERPACK = '2U Masterpack';
  readonly MINIC3_MASTERPACK = 'Mini C3 Masterpack';
  readonly C3_MASTERPACK = 'C3 Masterpack';
  readonly C4_MASTERPACK = 'C4 Masterpack';
  readonly WUL_MASTERPACK = 'WUL Masterpack';
  readonly BCR_MASTERPACK = 'BCR Masterpack';
  readonly CR90_MODULE = 'CR90 Module'
  readonly smallParcel = 'Small Parcel '
  
  readonly NEW_PART = 'New Part(s)'
  readonly OTHER_S = "Other (Specify in Vendor Comments)"
  readonly NEW_PART_NUMBER_ECI = 'New Part Number(ECI)'
  readonly PACKAGING_KAIZEN = 'Packaging Kaizen'
  readonly UPDATED_COST = 'Updated Cost'
  readonly PPR_COUNTERMEASURE = 'PPR Countermeasure'
  readonly PART_RESOURCING = 'Part Re-Sourcing'
  readonly other = 'Other';
  readonly wrap = 'Wrap';
  requestDropdown = [
    {requestValue:this.NEW_PART, name:this.NEW_PART},
    {requestValue:this.NEW_PART_NUMBER_ECI, name:this.NEW_PART_NUMBER_ECI},
    {requestValue:this.PACKAGING_KAIZEN, name:this.PACKAGING_KAIZEN},
    {requestValue:this.UPDATED_COST, name:this.UPDATED_COST},
    {requestValue:this.PPR_COUNTERMEASURE, name:this.PPR_COUNTERMEASURE},
    {requestValue:this.PART_RESOURCING, name:this.PART_RESOURCING},
    {requestValue:this.other, name:this.other} 
  ]

  palletCaseTable: any[] = [
    { col0: 'RETURNABLE PACK', col1: '', col2: '', col3: '', col4: '', col5: '', col6: '', col7: '', col8: '', col9: '' ,col10: ''},
    { col0: 'EXPENDABLE PACK(NO PALLET)', col1: '', col2: '', col3: '', col4: '', col5: '', col6: '', col7: '', col8: '', col9: '' ,col10: ''},
    { col0: 'WOOD PALLET', col1: '', col2: '', col3: '', col4: '', col5: '', col6: '', col7: '', col8: '', col9: '' ,col10: ''},
    { col0: 'MATERIAL 1' , col1: '', col2: '', col3: '', col4: '', col5: '', col6: '', col7: '', col8: '', col9: '' ,col10: ''},
    { col0: 'MATERIAL 2', col1: '', col2: '', col3: '', col4: '', col5: '', col6: '', col7: '', col8: '', col9: '' ,col10: ''},
    { col0: 'MATERIAL 3', col1: '', col2: '', col3: '', col4: '',  col5: '',col6: '', col7: '', col8: '', col9: '' ,col10: ''},
    { col0: 'MISC.', col1: '', col2: '', col3: '', col4: '', col5: '',col6: '', col7: '', col8: '', col9: '' ,col10: ''},
    { col0: 'CASE LABEL', col1: '', col2: '', col3: '', col4: '', col5: '', col6: '', col7: '', col8: '', col9: '' ,col10: ''},
    { col0: 'HEAVY CASE LABEL(PARTS+PKG>227KG)', col1: '', col2: '', col3: '', col4: '', col5: '', col6: '', col7: '', col8: '', col9: '' ,col10: ''},
  ];
  palletCasePackageType1 : any[] = [];
  palletCasePackageType2 : any[] = [];
  palletCasePackageType3 : any[] = [];
  masterRowValidations = false;
  //grid4
  form4:any;
  headers4 = HEADERS4;
  columns4: number[] = [...Array(8).keys()];
  rows4: number[] = [...Array(3).keys()];
  tableData: any[] = [
    { column1: 'PART SIZE', column2: '', column3: '', column4: '', column5: 'PART',column6:'',column8: '', column7: 'QUANTITY PARTS/UNIT PKG' },
    { column1: 'CONTAINER OUTSIDE DIMENSION', column2: '', column3: '', column5: 'UNIT PKG GROSS (INCLUDE PARTS)', column4: '',column6:'', column8: '', column7: 'QUANTITY PARTS/INTERMEDIATE PKG' },
    { column1: 'CONTAINER RETURN HEIGHT', column2: '', column3: '', column5: 'PALLET/CASE LOAD GROSS (INCLUDE PARTS)', column4: '',column6:'', column8: '', column7: 'QUANTITY PARTS/PALLET/CASE LOAD' },
  ];
  //error message
  errorList: string[] = [];
  //tms Part
  url: any;
  form5:any;
  headers2 = [
    {label: 'TMS PART NUMBER', colspan:1, width:90},
    {label: '11th/12 DIGIT LIST ALL IN SAME CELL', colspan:1, width:90},
    {label: 'TMS PART DESCRIPTION', colspan:1, width:150},       
    {label: 'HOME POSITION(HP)', colspan:1, width:90},
    {label: 'WEIGHT(g)',colspan:1, width:90},
    {label: 'L(mm)', colspan:1, width:90},
    {label: 'W(mm)', colspan:1, width:90},
    {label: 'H(mm)', colspan:1, width:90},
    {label: 'MONTHLY PART VOLUME', colspan:1, width:90},
    {label: 'PART M3', colspan:1, width:90},
    {label: "DELETE", colspan:1, width:90}
  ];
  columns5: number[] = [...Array(11).keys()];
  rows5: number[] =  []; 
  data:any[] = [];
  partType:any = [];
  routeParams: any;
  partNum: any;
  tenDigitValue:any;
  indexValue:any;
  deleteArray :boolean[] =[];
  serPPFPartTable: any[]=[];
  partLengthValidator :boolean[] =[];
  payloadArray:any=[];
  haveAccess: any;
  userInfo: any;
  asyncLoading = true;
  body:any;
  response: any;
  filteredSerPPFDetail: any[]=[];
  filteredSerPPFDetail1: any[]=[];
  filteredSerPPFDetail2: any[]=[];
  // filteredSerPPFDetail3: any[]=[];  
  serPpfDes: any;
  screenMode:any;
  homePositinDrop = [
    {statusValue:'A',name:'A'},{statusValue:'B',name:'B'},{statusValue:'C',name:'C'}, 
    {statusValue:'D',name:'D'},{statusValue:'E',name:'E'},{statusValue:'F',name:'F'}, 
    {statusValue:'G',name:'G'},{statusValue:'H',name:'H'}, {statusValue:'J',name:'J'},
    {statusValue:'K',name:'K'},{statusValue:'L',name:'L'},{statusValue:'M',name:'M'},
    {statusValue:'T',name:'T'},{statusValue:'Z',name:'Z'},
  ];
  approvedate: any;
  supplierdate: any;
  //top section and formula values
  unitpkgcost: any;
  interpkgcost: any;
  masterPalletPkgCost: any;
  unitlabortime: any;
  unitmaterialcost: any;
  unitlaborcost: any;
  interlabortime: any;
  intermaterialcost: any;
  interlaborcost: any;
  masterlabortime: any;
  mastermaterialcost: any;
  masterlaborcost: any;
  form0: any;
  date: any;
  partValues:any;
  partDesc = '';
  homePosition ='';
  partLength: any;
  partWidth: any;
  partHeight: any; 
  formatError: any[] = [];
  imageNames: any[] = [];
  public image: (SafeResourceUrl| null)[] = [];
  zoomImage:any=[];  
  totalUnitPackagingCost:any;
  keyUnitPkgInd = false;
  keyMasterInd=false;
  woodPalletHeightValidation=false;
  clickedPartIndex=0;
  totalCostValue=0;
  affiliate: any;
  approver: any;
  note: any;
  imageValid = false;
  isSave = false;
  pkgtypes: any;
  unitPkg: any;
  intermediatePkg: any;
  returnablePack: any;
  expandablePack: any;
  woodPallet: any;    
  imageValid0 = true;
  imageValid1 = true;
  imageValid3 = true;
  imageValid4 = true;
  ppfPlantCode: any;

  constructor(public sanitizer: DomSanitizer,
    public formbuilder: FormBuilder,private readonly fb: FormBuilder, public router: ActivatedRoute,
    public internalPpfService: PpfServiceInternal,public _location: Location,
    private readonly dialog: MatDialog,public breadcrumb: BreadcrumbComponent,
    private readonly spinner: NgxUiLoaderService,public state: ExportStateService,  
    public readonly datepipe: DatePipe,
    private readonly constants: ConstantsService) {
      this.form0 = this.fb.group({
       venName:[''],
       venCode:[''],
       venPickUpLoc:['',[Validators.required]],
       requestType:['',Validators.required],
       partType:['',Validators.required],
       packagingContact:[''],
       phoneNumber:[''],
       projectCode:[''],
       partsDesc:[''],
       homePosition:[''],
       email:[''],
       tenDigit:[''],
       elevenOrTwelfth:[''],
       prelimOrFinal:[''],
       date:[''],
       totalUnitPkg:[{ value: '', disabled: true }],
       hazmat:['',Validators.required],
       isExtChange: ['']
      });
    //grid1
    this.form1 = this.fb.group({});
    this.unitPkgControls();
    //grid2  
    this.form2 = this.fb.group({});
    for (let i = 0; i < this.rows2.length; i++) {
      for (let j = 0; j < this.columns2.length; j++) {
        this.form2.addControl(`row${i}col${j}`, this.fb.control(''));
      }
    }
    //grid3 
    this.form3 = this.fb.group({});
    for (let i = 0; i < this.rows3.length; i++) {
      for (let j = 0; j < this.columns3.length; j++) {
        this.form3.addControl(`row${i}col${j}`, this.fb.control(''));
      }
    }
    //grid4  
    this.form4 = this.fb.group({});
    this.initializeControls();    
    //tms Part
    this.form5 = this.fb.group({});
  } 

  ngOnInit(): void {  
    //router params    
    this.url = this.breadcrumb.breadcrumbs.length > 0 && this.breadcrumb.breadcrumbs.at(-2)?.url;   
    if(this.breadcrumb.breadcrumbs.length > 0){
      this.pValue = this.breadcrumb.breadcrumbs.find((item:any) => item.label === 'Service Parts PDF' || item.label === 'Accessory Parts PDF')?.label ?? '';	
      this.partType = this.constants.partTypeDropdown(this.pValue);
    }
    this.router.params.subscribe((data: any) => {
      this.routeParams = data;
      this.mode = Number(this.routeParams.mode);
      if(this.routeParams.external === '1'){
        this.partNum  = this.routeParams.partNumber
      }else{
        this.routerSubmethod();       
      }
      this.ppfPlantCode = sessionStorage.getItem("ppfPlantCode") ?? '';
    });       
      this.asyncLoading = true;  
      this.state.userInfoState.subscribe({
        next: (res: any) => {
          if (res) { 
                                 
            if (this.routeParams.external === "1") {
              this.userInfo = res;
              this.haveAccess = true;
              this.externalSPPFOnload();
            }
            else{
              this.userInfo = res; 
              this.internalUserCheck(res);
          }
          }
        },
        error: (_err: any) => {
          this.haveAccess = false;
          this.errorList.push(_err.message)
          this.spinner.stop();
          this.spinner.stop();
          this.asyncLoading = false;
        }
      });
        
  }
  internalUserCheck(res:any){
    if (!res.role?.p_trackingview && !res.role?.p_costview && !res.role?.p_qtyview) {             
      this.haveAccess = false;
      this.errorList = ["You're not authorized to access this page"];
      this.asyncLoading = false;                          
      this.spinner.stop();
    } else {
      this.haveAccess = true;
      this.servicePdfData();
    }
  }
  routerSubmethod(){
    if(this.routeParams.partnumber !== '' && this.routeParams.partnumber !==null && this.routeParams.partnumber !== undefined){
      this.partNum = this.routeParams.partnumber;        
    }
    else{        
      this.partNum = sessionStorage.getItem("partnumber") ?? '';
    }
  }
  //unit pkg controls
  unitPkgControls(){
    for (let i = 0; i < this.rows1.length; i++) {
      for (let j = 0; j < this.columns1.length; j++) {
        this.form1.addControl(`row${i}col${j}`, this.fb.control(''));
      }
    }
  }
  //form 4 validation
  initializeControls(){
    for (let i = 0; i < this.rows4.length; i++) {
      for (let j = 0; j < this.columns4.length; j++) {
        if(i === 0 && j === 7){
          this.form4.addControl(`row${i}col${j}`, this.fb.control('', [Validators.max(10)]));
        }
        else if(i === 1 && j === 7){
          this.form4.addControl(`row${i}col${j}`, this.fb.control('', [Validators.max(200)]));
        }       
        else{
          this.form4.addControl(`row${i}col${j}`, this.fb.control(''));
        }                
      }
    }
  }
  //external onload
  externalSPPFOnload(){
    this.spinner.start();
    this.internalPpfService.externalSPPFOnload(this.routeParams).subscribe({
      next : (res:any) =>{
        this.response = res;
        this.performDataOperations(res);
      },
      error : (_err:any) =>{
        this.spinner.stop();
      },
      complete : () =>{
        this.spinner.stop();
      }
    })
  }

  // download PDF
  pdfDownload(){
    const home = this.homePosition || this.form0.controls['homePosition']?.value;
    const docDefinition: any = getSPPF(this.response,this.image,this.tenDigitValue,this.partNum,this.partDesc,home,this.totalUnitPackagingCost,this.tableData,this.pValue,this.form1.getRawValue());
    pdfMake.createPdf(docDefinition).open();
  }

  //internal onload
  servicePdfData() {
    const body = {
      "PPFCode": this.routeParams?.ppfcode,
      "SpecialistCode": this.userInfo?.info?.specialistcode,
      "PlantCode": this.userInfo?.info?.plantcode,
      "PlantList": this.userInfo?.role?.p_plantList,
      "partnumber": this.partNum
    }
    const body2 = {
      "PPFCode": this.routeParams?.ppfcode,
      "PPFVer": this.routeParams?.ppfver,
      "History": this.routeParams?.history,
      "SpecialistCode": this.userInfo?.info?.specialistcode,
      "PlantCode": this.userInfo?.info?.plantcode,
      "PlantList": this.userInfo?.role?.p_plantList,
      "partnumber": this.partNum
    }
    if (this.url.includes('tracking-list/update-ppf-reason') && this.mode === 2) {
      this.body = body2;
    } else if ((this.url.includes('tracking-list/update-ppf-reason') && this.mode === 1) || (this.url.includes(this.trackingList)) || this.url.includes('tracking-list/send-dept-approval') || this.url.includes('part-list') || this.url.includes('tracking-list/sI-final-dept-approval') || this.url.includes('/tracking-list/hazmat-approval') || this.url.includes('/hazmat-approve-list/hazmat-approval')) {
      this.body = {
        "PPFCode": this.routeParams?.ppfcode,
        "PPFVer": this.routeParams?.ppfver,
        "SpecialistCode": this.userInfo?.info?.specialistcode,
        "PlantCode": this.userInfo?.info?.plantcode,
        "PlantList": this.userInfo?.role?.p_plantList,
        "partnumber": this.partNum
      }
    }  
    else if (this.url.includes('tracking-list/send-ppf') || this.url.includes('dept-approve') || this.url.includes('discripency-list')) {
      this.body = body;
    } else if ((this.url.includes(this.trackingList) && this.mode === 2) || this.url.includes('pur-decision-list') || this.url.includes('decision-list')) {
      this.body = body;
    }
    else {
      this.body = body2
    }

    if (this.url.includes('tracking-list/tracking-history')) {
      this.body = body2
    }
    this.spinner.start();

    this.internalPpfService?.servicePdfOnload(this.body)?.subscribe({
      next: (res: any) => {
        this.performDataOperations(res);
      },
      error: (_err: any) => {
        this.spinner.stop();
      },
      complete: () => {
        this.spinner.stop();
      },
    })
  }
  //onload response
  performDataOperations(res:any){
    const defaultDateFormat='0001-01-01';
    this.response = res;
    this.serPpfDes = res.DisplayData?.serPPF;
    this.screenMode = res.Mode;    
    this.serPPFPartTable = res.DisplayData?.serPPFPart;
    this.pkgtypes = res.DisplayData?.pkgtypes;

    // Unit pkg dropdown
    this.unitPkg = this.pkgtypes.filter((item: any) => item?.rownumber === 1);
    this.unitPackageType.push({'name' : 'Select','value' : ''})
    this.unitPkg.forEach((ele:any) => {
      this.unitPackageType.push({'name' : ele.packagingtype,'value' : ele.packagingtype})
    });

    // Intermediate pkg dropdown
    this.intermediatePkg = this.pkgtypes.filter((item: any) => item?.rownumber === 10);
    this.interPackageType.push({'name' : 'Select','value' : ''})
    this.intermediatePkg.forEach((ele:any) => {
      this.interPackageType.push({'name' : ele.packagingtype,'value' : ele.packagingtype})
    });

    // Returnable pack dropdown
    this.returnablePack = this.pkgtypes.filter((item: any) => item?.rownumber === 15);
    this.palletCasePackageType1.push({'name' : 'Select','value' : ''})
    this.returnablePack.forEach((ele:any) => {
      this.palletCasePackageType1.push({'name' : ele.packagingtype,'value' : ele.packagingtype})
    });

    // Expandable pack dropdown
    this.expandablePack = this.pkgtypes.filter((item: any) => item?.rownumber === 16);
    this.palletCasePackageType2.push({'name' : 'Select','value' : ''})
    this.expandablePack.forEach((ele:any) => {
      this.palletCasePackageType2.push({'name' : ele.packagingtype,'value' : ele.packagingtype})
    });

    // Wood pallet dropdown
    this.woodPallet = this.pkgtypes.filter((item: any) => item?.rownumber === 17);
    this.palletCasePackageType3.push({'name' : 'Select','value' : ''})
    this.woodPallet.forEach((ele:any) => {
      this.palletCasePackageType3.push({'name' : ele.packagingtype,'value' : ele.packagingtype})
    });

    this.affiliate = this.serPpfDes[0]?.affiliate;
    this.approver = this.serPpfDes[0]?.approver;
    this.note = this.serPpfDes[0]?.note;
    this.supplierdate = (this.serPpfDes[0]?.supplierdate && !this.serPpfDes[0]?.supplierdate.includes(defaultDateFormat)) ? this.serPpfDes[0]?.supplierdate?.substring(0, 10) : null;
    this.approvedate = (this.serPpfDes[0]?.approvedate && !this.serPpfDes[0]?.approvedate.includes(defaultDateFormat)) ? this.serPpfDes[0]?.approvedate?.substring(0, 10) : null;
    let sortedSerPPFDetai1l: any[]=[];
    sortedSerPPFDetai1l = res.DisplayData?.serPPFDetail
    sortedSerPPFDetai1l = sortedSerPPFDetai1l?.slice().sort((a, b) => +a.rownumber - +b.rownumber);       
    this.filteredSerPPFDetail = sortedSerPPFDetai1l?.filter((details: { rownumber: string; }) => parseInt(details.rownumber) >= 1 && parseInt(details.rownumber) <= 9);
    this.filteredSerPPFDetail1 = sortedSerPPFDetai1l?.filter((details: { rownumber: string; }) => parseInt(details.rownumber) >= 10 && parseInt(details.rownumber) <= 14);
    this.filteredSerPPFDetail2 = sortedSerPPFDetai1l?.filter((details: { rownumber: string; }) => parseInt(details.rownumber) >= 15 && parseInt(details.rownumber) <= 23);
    this.serPpfDes = res.DisplayData?.serPPF
    this.setGridDes(this.serPpfDes);   
    this.partloop(this.serPPFPartTable,1);    
    this.setGridUnit(this.filteredSerPPFDetail);
    this.setGridInter(this.filteredSerPPFDetail1);
    this.setGridPalletCase(this.filteredSerPPFDetail2);
    this.setGridPkgData();
    this.inputChangeMethods();
    this.getTotalPkgCost();
    this.disableCheckMethods(); 
    this.imageNames = [
      res.DisplayData?.serPPF[0]?.imgbarepart,
      res.DisplayData?.serPPF[0]?.imgpkgdunaroundpart,
      res.DisplayData?.serPPF[0]?.imgintermediatepkgpart,
      res.DisplayData?.serPPF[0]?.imgpalletorcaseloadimg,
      res.DisplayData?.serPPF[0]?.imgunitandintermediatelevel,
    ]
    for (let index = 0; index < 5; index++) {          
      this.imageNames[index] && this.getOnLoadImages(index);
    }
    this.onPartTypeChange(); 
  }  
  //inputChangeMethod
  inputChangeMethods(){
    this.onInputChange1();
    this.onInputChange2();
    this.onInputChange3();
    this.onInputChange5() ;
  }
  //disablecCheckMethods
  disableCheckMethods(){
    this.disableCheck1();
    this.disableCheck2();
    this.disableCheck3();
    this.disableCheck4();
    this.disableCheck5();
  }
  //error field validation
  markFormGroupTouched(formGroup: FormGroup): void {
    Object.values(formGroup.controls).forEach(control => {
      control.markAsTouched();
 
      if (control instanceof FormGroup) {
        this.markFormGroupTouched(control);
      }
    });
  }
  //download excel
  downloadPPF() {    
      const body = {
        "PPFCode": this.routeParams?.ppfcode,
        "PPFVer": this.response?.PPFVer,
        "Mode": this.mode,
        "partnumber":this.partNum
      } 
      this.spinner.start();
      this.internalPpfService.ServicePartsPPFDownload(body).subscribe({
        next: (res: any) => {
          const base64 = res?.result.Data;
          const fileName = res?.Filename;
          const file = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${base64}`;
          FileSaver.saveAs(file, fileName);
          this.spinner.stop();
        },
        error: (_err: any) => {
          this.spinner.stop();
        }
      })    
  }
  //grid1
  unitDropdown(row:any){
    if(row === 0){
      return this.unitPackageType;
    }
    else{
      return null;
    }
  }
  setGridDes(data: any) {
    if (this.routeParams.external === '1') {
      this.date = formatDate(new Date(), 'MM/dd/yyyy', 'en-US', 'UTC');
    }
    else {
      this.date = this.serPpfDes[0]?.regdate;
    }
    this.form0.controls['prelimOrFinal']?.patchValue((data[0]?.preorfinal === '1' || data[0]?.preorfinal === 1 || data[0]?.preorfinal === null) ? 'FINAL' : '');
    this.form0.controls['requestType']?.patchValue(data[0]?.requesttype);
    this.form0.controls['partType']?.patchValue(data[0]?.parttype);
    this.form0.controls['date']?.patchValue(this.date);
    this.form0.controls['venName']?.patchValue(data[0]?.vendorname);
    this.form0.controls['venCode'].patchValue(data[0]?.vendorcode);
    this.form0.controls['projectCode']?.patchValue(data[0]?.projectcode);
    this.form0.controls['email']?.patchValue(data[0]?.submitteremail);
    this.form0.controls['phoneNumber']?.patchValue(data[0]?.submitterphone);
    this.form0.controls['venPickUpLoc']?.patchValue(data[0]?.vendorpickup);
    this.form0.controls['elevenOrTwelfth']?.patchValue(this.partNum.length > 10 ? this.partNum.slice(10) : '');
    this.form0.controls['packagingContact']?.patchValue(data[0]?.submittername);
    this.form0.controls['hazmat']?.patchValue(data[0]?.hazmat);   
    this.totalUnitPackagingCost = safeToFixed(validate(data[0]?.totalunitpackagingcost));
    this.form0.controls['isExtChange']?.patchValue(data[0]?.isextchange);
  }  
  fixToDecimal(i: number, j: number, formValue: 1 | 2 | 3, gridName: string) {
    if (gridName !== 'tmsPart' && gridName !== 'packageData') {
      this.fixedToDecimalSub(i, j, formValue);
    }
    else if (gridName === 'packageData') {
      this.fixedToDecimalPackage(i,j)
    }
    else {
      const controlName = 'row' + i + 'col' + j;
      if (j === 4 || j === 5 || j === 6 || j === 7 || j === 8) {
        const value = this.form5.get(controlName)?.value;
        if (value) {
          this.form5.controls[`row${i}col${j}`].patchValue(safeToFixed(value));
        }
      }
    }
  }
  fixedToDecimalSub(i: number, j: number, formValue: 1 | 2 | 3) {
    if (j !== 0 && j !== 1 && j !== 2 && j !== 8 && j !== 9 && j !== 10) {
      const formMap = {
        1: this.form1,
        2: this.form2,
        3: this.form3,
      };
      const formGroup = formMap[formValue];
      const controlName = 'row' + i + 'col' + j;
      const value = formGroup.get(controlName)?.value;
      if (value) {
        formGroup.controls[`row${i}col${j}`].patchValue(safeToFixed(value));
      }
    }
  }
  fixedToDecimalPackage(i:number, j:number){
    if ((i === 1 && (j === 1 || j === 2 || j === 3)) || (i === 2 && (j === 1 || j === 2 || j === 3))) {
      const controlName = 'row' + i + 'col' + j;
      const value = this.form4.get(controlName)?.value;
      if (value) {
        this.form4.controls[`row${i}col${j}`].patchValue(safeToFixed(value));
      }
    }
  }
  onInputChange1(){   
    this.close();
    for (let i = 0; i < this.unitTable.length; i++) {
      if (i !== 4 && i !== 5) {
        const unitPkgType = this.form1.controls['row0col1'].value;
        if (i === 0) {
          const qty = +this.unitPkg[i]?.qty; //1
          this.unitDropDownValuesPopulate(unitPkgType,qty,i)          
        }        
      }
      else if (i === 4) {
        const unitPkgType = this.form1.controls['row0col1'].value;
        if (unitPkgType !== null && unitPkgType !== undefined && unitPkgType !== "") {
          const qtyValue = 1;
          this.form1.controls[`row${i}col9`].patchValue(qtyValue);         
        }
      }                       
    }
    this.unitPkgChangeMethods();   
  }
  unitPkgChangeMethods(){
    this.unitDropdownError();
    this.unitrow9Error();
    this.unitrow7Error();   
    this.unitHeavyPartLabel();
    this.palletHeavyCaseLabel();
    this.partWeight();
    this.unitPkgGross();
    this.getTotalPkgCost();
  }
  unitDropDownValuesPopulate(unitPkgType:any,qty:number,i:any){
    switch (unitPkgType) {
      case '':
      {
        // Clear all fields and set result to null.
        this.form1.controls[`row${i}col2`]?.patchValue(null);
        this.form1.controls[`row${i}col3`]?.patchValue(null);
        this.form1.controls[`row${i}col4`]?.patchValue(null);
        this.form1.controls[`row${i}col5`]?.patchValue(null);
        this.form1.controls[`row${i}col6`]?.patchValue(null);
        this.form1.controls[`row${i}col7`]?.patchValue(null);
        this.form1.controls[`row${i}col8`]?.patchValue(null);
        this.form1.controls[`row${i}col10`]?.patchValue(null);
        this.form1.controls[`row4col9`]?.patchValue(null);
        break;
      }
      case this.barePart:
        {
          const pkgType = this.unitPkg.find((item: any) => item?.packagingtype === this.barePart);
          const laborTimeValue = +pkgType?.labortime; //labour value for bare part   - 0        
          this.unitpackageDropdown(laborTimeValue, qty, i);
          break;
        }
      case this.polyBag:
      case this.BUBBLE_BAG:
      case this.otherBag:
      case this.BOTTLE_CAN:
      case this.tubeBag:
        {
          const pkgType = this.unitPkg.find((item: any) => item?.packagingtype === this.polyBag);
          const laborTimeValue = +pkgType?.labortime; //labour value for Poly Bag    - 11       
          this.unitpackageDropdown(laborTimeValue, qty, i)
          break;
        }
      case this.SMALL_PARCEL_SHIPPING_BOX:
        {
          const pkgType = this.unitPkg.find((item: any) => item?.packagingtype === this.SMALL_PARCEL_SHIPPING_BOX);
          const laborTimeValue = +pkgType?.labortime; //labour value for Small Parcel Shipping Box - 5
          this.unitpackageDropdown(laborTimeValue, qty, i)
          break;
        }
      case this.BOX_LENGTH:
      case this.UN_CERTIFIED_BOX:
        {
          const pkgType = this.unitPkg.find((item: any) => item?.packagingtype === this.BOX_LENGTH);
          const laborTimeValue = +pkgType?.labortime; //labour value for box length <=48 - 20
          this.unitpackageDropdown(laborTimeValue, qty, i)
          break;
        }
      case this.LARGE_BOX:
        {
          const pkgType = this.unitPkg.find((item: any) => item?.packagingtype === this.LARGE_BOX);
          const laborTimeValue = +pkgType?.labortime; //labour value for large box - 30
          this.unitpackageDropdown(laborTimeValue, qty, i)
          break;
        }
      default:
        {      
          break;
        }
    }
  }
  unitDropdownError(){
    const unitPkgType = this.form1.controls['row0col1'].value;
    const unitCost = this.form1.controls['row0col7']?.value;

    if (validate(unitPkgType) === '' && validate(unitCost)) {
      this.form1.controls['row0col1'].setValidators([Validators.required]);
      this.form1.controls['row0col1'].updateValueAndValidity();
      this.markFormGroupTouched(this.form1);
      this.errorList.push("Please select a packaging type from drop down list. If Bare Part, this cell should be blank");
    }
    else {
      this.form1.controls['row0col1'].clearValidators();
      this.form1.controls['row0col1'].updateValueAndValidity();
    }
  }
  unitrow9Error(){
    const rowValues = [
      this.form1.controls['row1col9'].value,
      this.form1.controls['row2col9'].value,
      this.form1.controls['row3col9'].value,      
    ];
    const rowValues2 = [
      this.form1.controls['row7col9']?.value,
      this.form1.controls['row8col9']?.value,
    ]
    if (rowValues.some(value => validate(value) > 10) || rowValues2.some(value => validate(value) > 10)) {
      this.unitPackageData = true;
      for (let i = 1; i <= 3; i++) {
        const control = this.form1.get(`row${i}col9`);
        control?.setValidators([Validators.max(10)]);
        control?.updateValueAndValidity();
      }
      for(let i = 7; i <=8; i++){
        const control = this.form1.get(`row${i}col9`);
        control?.setValidators([Validators.max(10)]);
        control?.updateValueAndValidity();
      }  
      this.markFormGroupTouched(this.form1);
      this.errorList.push("The value doesn't match the data validation restrictions for this cell.");
    }
    else {
      this.unitPackageData = false;
      for (let i = 1; i <= 3; i++) {
        const control = this.form1.get(`row${i}col9`);
        control?.clearValidators();
        control?.updateValueAndValidity();
      }
      for (let i = 7; i <= 8; i++) {
        const control = this.form1.get(`row${i}col9`);
        control?.clearValidators();
        control?.updateValueAndValidity();
      }       
    }
  }
  unitrow7Error(){
    const rowValues1 = [
      this.form1.controls['row1col7'].value,
      this.form1.controls['row2col7'].value,
      this.form1.controls['row3col7'].value,     
    ];

    if (rowValues1.some(value => validate(value) > 99)) {
      this.unitPackageMat = true;
      for (let i = 1; i <= 3; i++) {
        const control = this.form1.get(`row${i}col7`);
        control?.setValidators([Validators.max(99)]);
        control?.updateValueAndValidity();
      }
      this.errorList.push("Please engage Pkg Engineer for individual packaging components in excess of $100.");
    }
    else {
      this.unitPackageMat = false;
      for (let i = 1; i <= 3; i++) {
        const control = this.form1.get(`row${i}col7`);
        control?.clearValidators();
        control?.updateValueAndValidity();
      }
    }
  }
  setGridUnit(data: any) {
    if (this.mode === 1) {
      // dynamic rows set from row0 to row3
      for (let i = 0; i < 4; i++) {
        this.form1.controls[`row${i}col1`]?.patchValue(validate(data[i]?.packagingtype))
        this.form1.controls[`row${i}col2`]?.patchValue(validate(data[i]?.materialdescription))
        this.form1.controls[`row${i}col3`]?.patchValue(safeToFixed(validate(data[i]?.weight)))
        this.form1.controls[`row${i}col4`]?.patchValue(safeToFixed(validate(data[i]?.length)))
        this.form1.controls[`row${i}col5`]?.patchValue(safeToFixed(validate(data[i]?.width)))
        this.form1.controls[`row${i}col6`]?.patchValue(safeToFixed(validate(data[i]?.height)))
        this.form1.controls[`row${i}col7`]?.patchValue(safeToFixed(validate(data[i]?.unitcost)))
        this.form1.controls[`row${i}col8`]?.patchValue(validate(data[i]?.labortime))
        if (i === 0) {
          this.form1.controls[`row${i}col9`]?.patchValue(validate(1)) // default QTY value for unit package
        }
        else {
          this.form1.controls[`row${i}col9`]?.patchValue(validate(data[i]?.qty))
        }
        this.form1.controls[`row${i}col10`]?.patchValue(validate(data[i]?.comment))
      }
      //row4
      this.form1.controls['row4col1']?.patchValue(this.WHITLAM_LABEL);
      this.form1.controls['row4col7']?.patchValue(0.0540) //Material Unit value for row 4
      this.form1.controls['row4col8']?.patchValue(3) //Labor time
      this.form1.controls['row4col9']?.patchValue(validate(data[4]?.qty))
      this.form1.controls['row4col10']?.patchValue(validate(data[4]?.comment))
      //row5
      this.form1.controls['row5col1']?.patchValue(this.WHITLAM_LABEL);
      this.form1.controls['row5col7']?.patchValue(0.0750) //Material Unit value for row 5
      this.form1.controls['row5col8']?.patchValue(3)  //Labor time
      this.form1.controls['row5col9']?.patchValue(validate(data[5]?.qty))
      this.form1.controls['row5col10']?.patchValue(validate(data[5]?.comment))
      //row 6     
      this.form1.controls['row6col7']?.patchValue(safeToFixed(validate(data[6]?.unitcost)))
      this.form1.controls['row6col8']?.patchValue(3)  //Labor time
      this.form1.controls['row6col9']?.patchValue(validate(data[6]?.qty))
      this.form1.controls['row6col10']?.patchValue(validate(data[6]?.comment))
      //row 7 & row 8
      if (this.pValue === 'Accessory Parts PDF') {       
        this.form1.controls['row7col7']?.patchValue(safeToFixed(validate(data[7]?.unitcost)))//Material Unit value for row 7
        this.form1.controls['row7col8']?.patchValue(3)  //Labor time
        this.form1.controls['row7col9']?.patchValue(validate(data[7]?.qty))
        this.form1.controls['row7col10']?.patchValue(validate(data[7]?.comment))
        //row 6     
        this.form1.controls['row8col7']?.patchValue(safeToFixed(validate(data[8]?.unitcost)))
        this.form1.controls['row8col8']?.patchValue(3)  //Labor time
        this.form1.controls['row8col9']?.patchValue(validate(data[8]?.qty))
        this.form1.controls['row8col10']?.patchValue(validate(data[8]?.comment))
      }
    }
    else {
      setGridUnitView(this.unitTable, data,this.pValue);
    }    
  }
  unitpackageDropdown(laborTimeValue:any,qty:any,i:any){    
    this.form1.controls[`row${i}col8`]?.patchValue(laborTimeValue);
    this.form1.controls[`row${i}col9`]?.patchValue(qty);    
  }  
  disableCheck1() {
    // Define specific controls that need to be disabled
    const controlsToDisable = new Set([
      'row0col8', 'row0col9',
      'row4col2', 'row4col3', 'row4col4', 'row4col5', 'row4col6', 'row4col7', 'row4col8', 'row4col9',
      'row5col2', 'row5col3', 'row5col4', 'row5col5', 'row5col6', 'row5col7', 'row5col8', 'row5col9',
      'row6col1', 'row6col2', 'row6col3', 'row6col4', 'row6col5', 'row6col6', 'row6col8',
      'row7col1', 'row7col2', 'row7col3', 'row7col4', 'row7col5', 'row7col6', 'row7col8',
      'row8col1', 'row8col2', 'row8col3', 'row8col4', 'row8col5', 'row8col6', 'row8col8'
    ]);
    // Loop through all form controls
    for (const key of Object.keys(this.form1.controls)) {
      // Check if the control should be disabled
      if (controlsToDisable.has(key)) {
        this.form1.controls[key]?.disable();
      }
    }
  }
  //close
  navigatePrevious() {
    this._location.back();
    sessionStorage.removeItem('partnumber');
    sessionStorage.removeItem('ppfPlantCode');
  }

  //grid2
  interDropdown(row:any){
    if(row === 0){
      return this.interPackageType;
    }
    else{
      return null;
    }
  }
  onInputChange2() {
    const keyMasterpackValue = validate(this.form4.controls['row1col7']?.value); //j9      
    for (let i = 0; i < this.intermediateTable.length; i++) {
      if(i!==4){
        const interPkgType = this.form2.controls['row0col1']?.value;                  
        if(i === 0){
          this.intermediateDropDownChange(interPkgType,keyMasterpackValue,i)          
        }                   
      }
      else{
        const interPkgType = this.form2.controls['row0col1']?.value;
        if (interPkgType !== null && interPkgType !== undefined && interPkgType !== "") {
          const qtyValue = 1; //quantity value
          this.form2.controls[`row${i}col9`]?.patchValue(qtyValue);          
        }
      }
    }
    this.intermediateChangeMethods();
  }
  intermediateChangeMethods(){
    this.intermediateTableValidation();
    this.palletHeavyCaseLabel(); 
    this.palletCaseLoadGross();
  }
  //Intermediate dropdwon
  intermediateDropDownChange(interPkgType:any,keyMasterpackValue:any,i:any){         
    switch (interPkgType) {
      case '':
        {
          // Clear all fields and set result to null
          this.form2.controls[`row${i}col2`]?.patchValue(null);
          this.form2.controls[`row${i}col3`]?.patchValue(null);
          this.form2.controls[`row${i}col4`]?.patchValue(null);
          this.form2.controls[`row${i}col5`]?.patchValue(null);
          this.form2.controls[`row${i}col6`]?.patchValue(null);
          this.form2.controls[`row${i}col7`]?.patchValue(null);
          this.form2.controls[`row${i}col8`]?.patchValue(null);
          this.form2.controls[`row${i}col9`]?.patchValue(null);
          this.form2.controls[`row${i}col10`]?.patchValue(null);
          this.form2.controls[`row4col9`]?.patchValue(null);
          break;
        }
      case this.polyBag:
      case this.BUBBLE_BAG: {
        const pkgType = this.intermediatePkg.find((item: any) => item?.packagingtype === this.BUBBLE_BAG);
        const laborTimeValueForBag = +(pkgType?.labortime) + 2 * keyMasterpackValue; // labor value for polybag and bubble bag - 6
        this.interPackageDropdown(laborTimeValueForBag,i);
        break;
      }
      case this.wrap: {
        const pkgType = this.intermediatePkg.find((item: any) => item?.packagingtype === this.wrap);
        const laborTimeValueForWrap = +(pkgType?.labortime) + 2 * keyMasterpackValue; // labor value for wrap - 2
        this.interPackageDropdown(laborTimeValueForWrap,i);
        break;
      }
      case this.other: {
        const pkgType = this.intermediatePkg.find((item: any) => item?.packagingtype === this.other);
        const laborTimeValueForWrap = +(pkgType?.labortime) + 2 * keyMasterpackValue; // labor value for Other - 11
        this.interPackageDropdown(laborTimeValueForWrap,i);
        break;
      }
      case this.SMALL_PARCEL_SHIPPING_BOX: {
        const pkgType = this.intermediatePkg.find((item: any) => item?.packagingtype === this.SMALL_PARCEL_SHIPPING_BOX);
        const laborTimeValueForSmallParcel = +(pkgType?.labortime) + 2 * keyMasterpackValue; // labor value for small parcel - 0
        this.interPackageDropdown(laborTimeValueForSmallParcel,i);
        break;
      }
      case this.BOX_LENGTH: {
        const pkgType = this.intermediatePkg.find((item: any) => item?.packagingtype === this.BOX_LENGTH);
        const laborTimeValueForSmallParcel = +(pkgType?.labortime) + 3 * keyMasterpackValue; // labor value for box length <=48 - 15
        this.interPackageDropdown(laborTimeValueForSmallParcel,i);
        break;
      }
      case this.LARGE_BOX: {
        const pkgType = this.intermediatePkg.find((item: any) => item?.packagingtype === this.LARGE_BOX);
        const laborTimeValueForLargeBox = +(pkgType?.labortime) + 3 * keyMasterpackValue; // labor value for large box - 25
        this.interPackageDropdown(laborTimeValueForLargeBox,i);
        break;
      }
      default: {     
        break;
      }
    }
  }
  //intermediateqtyValidatioon
  intermediateTableValidation() {
    const row0col7 = this.form2.controls['row0col7'].value;
    const row0col2 = this.form2.controls['row0col2'].value;
    const rowValues = [
      this.form2.controls['row1col9'].value,
      this.form2.controls['row2col9'].value,
      this.form2.controls['row3col9'].value
    ];
    if (validate(row0col2) === '' && validate(row0col7) !== '' && row0col7 !== 0) {
      this.errorList = [];
      this.inter = true;
      this.form2.controls['row0col7'].patchValue(null);
      this.form2.get('row0col2')?.setValidators([Validators.required]);
      this.form2.get('row0col2')?.updateValueAndValidity();
      this.markFormGroupTouched(this.form2);
      this.errorList.push("Please enter Intermediate Packaging Material Description first.");
    } else if (rowValues.some(value => validate(value) > 6)) {
      this.errorList = [];
      this.inter = true;
      for (let i = 1; i <= 3; i++) {
        const control = this.form2.get(`row${i}col9`);
        control?.setValidators([Validators.max(6)]);
        control?.updateValueAndValidity();
      }
      this.markFormGroupTouched(this.form2);
      this.errorList.push("Any intermediate package material >6 please reach out to your Packaging Engineer.");
    }
    else if (rowValues.some(value => validate(value) <= 6)) {
      this.inter = false;
      if (validate(row0col7) === '' && (validate(row0col2) !== '' || validate(row0col2) === '')) {
        this.form2.get('row0col2')?.clearValidators(['']);
        this.form2.get('row0col2')?.updateValueAndValidity();
        this.close();
      }
      else {        
        for (let i = 1; i <= 3; i++) {
          const control = this.form2.get(`row${i}col9`);
          control?.clearValidators();
          control?.updateValueAndValidity();
        }
        this.close();
      }
    }
    else {
      this.close();
    }
  }
  //interpackageDropdown mapping
  interPackageDropdown(laborTimeValue:any,i:any){
    this.form2.controls[`row${i}col8`]?.patchValue(laborTimeValue);
    this.form2.controls[`row${i}col9`].patchValue(1);
  }
  setGridInter(data: any) {
    if (this.mode === 1) {
      // dynamic rows patch from row0 to row4
      for (let i = 0; i < 4; i++) {
        this.form2.controls[`row${i}col1`].patchValue(validate(data[i]?.packagingtype))
        this.form2.controls[`row${i}col2`].patchValue(validate(data[i]?.materialdescription))
        this.form2.controls[`row${i}col3`].patchValue(safeToFixed(validate(data[i]?.weight)))
        this.form2.controls[`row${i}col4`].patchValue(safeToFixed(validate(data[i]?.length)))
        this.form2.controls[`row${i}col5`].patchValue(safeToFixed(validate(data[i]?.width)))
        this.form2.controls[`row${i}col6`].patchValue(safeToFixed(validate(data[i]?.height)))
        this.form2.controls[`row${i}col7`].patchValue(safeToFixed(validate(data[i]?.unitcost)))
        this.form2.controls[`row${i}col8`].patchValue(validate(data[i]?.labortime))
        this.form2.controls[`row${i}col9`].patchValue(validate(data[i]?.qty))
        this.form2.controls[`row${i}col10`].patchValue(validate(data[i]?.comment))
      }
      // row5
      this.form2.controls['row4col1'].patchValue(this.WHITLAM_LABEL);
      this.form2.controls['row4col7'].patchValue(0.0540) //Material unit for row 4
      this.form2.controls['row4col8'].patchValue(3) //labor time for row 4
      this.form2.controls['row4col9'].patchValue(validate(data[4]?.qty))
      this.form2.controls['row4col10'].patchValue(validate(data[4]?.comment))
    }
    else {
      setNonEditableInter(this.intermediateTable, data)

    }
  }
  //To disable fields
  disableCheck2() {
    // Define specific controls that need to be disabled
    const controlsToDisable = new Set([     
      'row4col2', 'row4col3', 'row4col4', 'row4col5', 'row4col6', 'row4col7', 'row4col8', 'row4col9',
      'row0col8', 'row0col9'
    ]);
    // Loop through all form controls
    for (const key of Object.keys(this.form2.controls)) {
      // Check if the control should be disabled
      if (controlsToDisable.has(key)) {
        this.form2.controls[key]?.disable();
      }
    }
  }
  //grid3
  palletCaseDropdown(row:any){
    switch(row){
      case 0:
        return this.palletCasePackageType1;
      case 1:
        return this.palletCasePackageType2;
      case 2:
        return this.palletCasePackageType3;
      default:
        return null;
    }
  }
  onInputChange3() {
    const pkgType1 = this.form3.controls['row0col1'].value;
    const pkgType2 = this.form3.controls['row1col1'].value; 
    const pkgType3 = this.form3.controls['row2col1'].value;         
    if (validate(pkgType1) !=='' || validate(pkgType2) !=='' || validate(pkgType3) !=='') {    
      this.palletHeavyCaseLabel();
    } 
    const j10 = validate(this.form4.controls['row2col7']?.value);//masterPackvalue is j10
    const j9 = validate(this.form4.controls['row1col7']?.value);   
    let masterOrPallet;
    if (j9 !== '') {
      masterOrPallet = j9;
    }
    else {
      masterOrPallet = j10;
    }
    for (let i = 0; i < this.palletCaseTable.length; i++) {
      if (i !== 7 && i !== 8) {
        const masterPkgType = this.form3.controls['row0col1']?.value;
        const masterPkgType1 = this.form3.controls['row1col1']?.value;
        const masterPkgType2 = this.form3.controls['row2col1']?.value;
        if (i === 0) {
          const retPack = this.returnablePack.filter((item : any) => item.packagingtype === masterPkgType);
          this.palletCaseDropdownMapping(masterPkgType, masterOrPallet, retPack, i)
        }
        else if (i === 1) {
          const expPack = this.expandablePack.filter((item : any) => item.packagingtype === masterPkgType1);
          this.palletCaseDropdown2Mapping(masterPkgType1, masterOrPallet, expPack, i);
        }
        else if (i === 2) { 
          const woodPal = this.woodPallet.filter((item : any) => item.packagingtype === masterPkgType2);
          this.palletCaseDropdown3Mapping(masterPkgType2,masterOrPallet, woodPal, i);         
        }
      }
      else if (i === 7) {
        this.palletCaserow9Value(i);
      }
    }
    this.palletCaseChangeMethods();
  }
  palletCaseChangeMethods(){
    this.palletCasecol9Validation();
    this.woodPalletHeight();
    //formulas    
    this.palletCaseLoadGross();
  }
   //palletDropdown1mapping
  palletCaseDropdownMapping(masterPkgType: any, masterOrPallet: any, retPack: any, i: any) {
    const laborTimeBaseValue = +retPack[0]?.labortime; // 60
    const laborTimeValue = laborTimeBaseValue + 5 * (masterOrPallet);
    const qty = +retPack[0]?.qty; //quantity for row 0
    const materialdescription = retPack[0]?.materialdescription // material desc for row 0 
    const unitCostValue = +retPack[0]?.unitcost; //unit cost value for row 0
    const length = +retPack[0]?.length;
    const width = +retPack[0]?.width;
    const height = +retPack[0]?.height;
    const weight = +retPack[0]?.weight;
    switch (masterPkgType) {
      case '':
        {
          // Clear all fields and set result to null
          this.form3.controls[`row${i}col1`]?.patchValue(null);
          this.form3.controls[`row${i}col2`]?.patchValue(null);
          this.form3.controls[`row${i}col3`]?.patchValue(null);
          this.form3.controls[`row${i}col4`]?.patchValue(null);
          this.form3.controls[`row${i}col5`]?.patchValue(null);
          this.form3.controls[`row${i}col6`]?.patchValue(null);
          this.form3.controls[`row${i}col7`]?.patchValue(null);
          this.form3.controls[`row${i}col8`]?.patchValue(null);
          this.form3.controls[`row${i}col9`]?.patchValue(null);
          this.form3.controls[`row${i}col10`]?.patchValue(null);
          break;
        }
      case this.U_RETURNABLE:
        {
          // Material specification for package type U Returnable
          this.masterDropdown1(materialdescription, weight, length, width, height, i);
          this.masterDropdown1OtherData(unitCostValue,laborTimeValue,qty,i)
          break;
        }
      case this.MU_RETURNABLE:
        {
          // Material specification for package type MU Returnable
          this.masterDropdown1(materialdescription, weight, length, width, height, i);
          this.masterDropdown1OtherData(unitCostValue,laborTimeValue,qty,i)
          break;
        }
      case this.HU_RETURNABLE:
        {
          // Material specification for package type HU Returnable
          this.masterDropdown1(materialdescription, weight, length, width, height, i);
          this.masterDropdown1OtherData(unitCostValue,laborTimeValue,qty,i)
          break;
        }
      case this._2U_RETURNABLE:
        {
          // Material specification for package type 2U Returnable
          this.masterDropdown1(materialdescription, weight, length, width, height, i);
          this.masterDropdown1OtherData(unitCostValue,laborTimeValue,qty,i)
          break;
        }
      case this.MINI_C3_RETURNABLE:
        {
          // Material specification for package type Mini C3 Returnable
          this.masterDropdown1(materialdescription, weight, length, width, height, i);
          this.masterDropdown1OtherData(unitCostValue,laborTimeValue,qty,i)
          break;
        }
      case this.C3_RETURNABLE:
        {
          // Material specification for package type C3 Returnable
          this.masterDropdown1(materialdescription, weight, length, width, height, i);
          this.masterDropdown1OtherData(unitCostValue,laborTimeValue,qty,i)
          break;
        }
      case this.C4_RETURNABLE:
        {
          // Material specification for package type C4 Returnable
          this.masterDropdown1(materialdescription, weight, length, width, height, i);
          this.masterDropdown1OtherData(unitCostValue,laborTimeValue,qty,i)
          break;
        }
      case this.WUL_RETURNABLE:
        {
          // Material specification for package type WUL Returnable
          this.masterDropdown1(materialdescription, weight, length, width, height, i);
          this.masterDropdown1OtherData(unitCostValue,laborTimeValue,qty,i)
          break;
        }
      case this.BCR_RETURNABLE:
        {
          // Material specification for package type BCR Returnable
          this.masterDropdown1(materialdescription, weight, length, width, height, i);
          this.masterDropdown1OtherData(unitCostValue,laborTimeValue,qty,i)
          break;
        }
      default:
        break;
    }
  }
  //dropdown1mapping
  masterDropdown1(materialdescription:any,weight:any,length:any,width:any,height:any,i:any){
    this.form3.controls[`row${i}col2`].patchValue(materialdescription);
    this.form3.controls[`row${i}col3`].patchValue(weight);
    this.form3.controls[`row${i}col4`].patchValue(length);
    this.form3.controls[`row${i}col5`].patchValue(width);
    this.form3.controls[`row${i}col6`].patchValue(height);      
  }
  masterDropdown1OtherData(unitCostValue:any,laborTimeValue:any,qty:any,i:any){
    this.form3.controls[`row${i}col7`].patchValue(unitCostValue); 
    this.form3.controls[`row${i}col8`]?.patchValue(laborTimeValue);
    this.form3.controls[`row${i}col9`].patchValue(qty);
  }
  //palletDropdown2Mapping
  palletCaseDropdown2Mapping(masterPkgType1: any, masterOrPallet: any, expPack: any, i: any) {
    const materialDesc = expPack[0]?.materialdescription;
    const qty = +expPack[0]?.qty;
    const lbTime = +expPack[0]?.labortime;   
    let laborTime;
    switch (masterPkgType1) {
      case '':
        {
          // Handle empty masterPkgType1        
          this.form3.controls[`row${i}col1`]?.patchValue(null);
          this.form3.controls[`row${i}col2`]?.patchValue(null);
          this.form3.controls[`row${i}col3`]?.patchValue(null);
          this.form3.controls[`row${i}col4`]?.patchValue(null);
          this.form3.controls[`row${i}col5`]?.patchValue(null);
          this.form3.controls[`row${i}col6`]?.patchValue(null);
          this.form3.controls[`row${i}col7`]?.patchValue(null);
          this.form3.controls[`row${i}col8`]?.patchValue(null);
          this.form3.controls[`row${i}col9`]?.patchValue(null);
          this.form3.controls[`row${i}col10`]?.patchValue(null);
          break;
        }
      case this.U_MSTERPACK:
        {
          laborTime = lbTime + 5 * (masterOrPallet); //90
          this.masterDropdown2(materialDesc, laborTime, qty, i);
          break;
        }
      case this.MU_MSTERPACK:
        {
          laborTime = lbTime + 5 * (masterOrPallet); //90
          this.masterDropdown2(materialDesc, laborTime, qty, i);
          break;
        }
      case this.HU_MSTERPACK:
        {
          laborTime = lbTime + 5 * (masterOrPallet); //90
          this.masterDropdown2(materialDesc, laborTime, qty, i);
          break;
        }
      case this.TWOU_MASTERPACK:
        {
          laborTime = lbTime + 5 * (masterOrPallet); //90
          this.masterDropdown2(materialDesc, laborTime, qty, i);
          break;
        }
      case this.MINIC3_MASTERPACK:
        {
          laborTime = lbTime + 5 * (masterOrPallet); //90
          this.masterDropdown2(materialDesc, laborTime, qty, i);
          break;
        }
      case this.C3_MASTERPACK:
        {
          laborTime = lbTime + 5 * (masterOrPallet); //90
          this.masterDropdown2(materialDesc, laborTime, qty, i);
          break;
        }
      case this.C4_MASTERPACK:
        {
          laborTime = lbTime + 5 * (masterOrPallet); //90
          this.masterDropdown2(materialDesc, laborTime, qty, i);
          break;
        }
      case this.WUL_MASTERPACK:
        {
          laborTime = lbTime + 5 * (masterOrPallet); //120
          this.masterDropdown2(materialDesc, laborTime, qty, i);
          break;
        }
      case this.BCR_MASTERPACK:
        {
          laborTime = lbTime + 5 * (masterOrPallet); //120
          this.masterDropdown2(materialDesc, laborTime, qty, i);
          break;
        }
      case this.BOX_LENGTH:
        {
          laborTime = lbTime + 5 * (masterOrPallet); // 15
          this.masterDropdown2(materialDesc, laborTime, qty, i);
          break;
        }
      case this.LARGE_BOX:
        {
          laborTime = lbTime + 5 * (masterOrPallet); // 25
          this.masterDropdown2(materialDesc, laborTime, qty, i);
          break;
        }
      case this.CR90_MODULE:
        {
          // materialDesc = ''; coming as Corrugate with Wood Pallet
          laborTime = lbTime + 5 * (masterOrPallet); // 120
          this.masterDropdown2(materialDesc, laborTime, qty, i);
          break;
        }
      case this.smallParcel:
        {
          // materialDesc = ''; coming as No MasterPack - Use Unit/Inter box or Small Package box
          laborTime = lbTime; //60
          this.masterDropdown2(materialDesc, laborTime, qty, i);
          break;
        }
      default:
        break;
    }
  }
  
  //palletdropdown2valuesmap
  masterDropdown2(materialDesc:any,laborTime:any,qty:any,i:any){
    this.form3.controls[`row${i}col2`].patchValue(materialDesc);
    this.form3.controls[`row${i}col8`]?.patchValue(laborTime);
    this.form3.controls[`row${i}col9`].patchValue(qty);     
  }
  //dropdown3Mapping
  palletCaseDropdown3Mapping(masterPkgType2: any, masterOrPallet: any, woodPal: any, i: any) {
    switch (masterPkgType2) {
      case '':
        // Handle the case where pkgType is an empty string        
          this.form3.controls[`row${i}col1`]?.patchValue(null);
          this.form3.controls[`row${i}col2`]?.patchValue(null);
          this.form3.controls[`row${i}col3`]?.patchValue(null);
          this.form3.controls[`row${i}col4`]?.patchValue(null);
          this.form3.controls[`row${i}col5`]?.patchValue(null);
          this.form3.controls[`row${i}col6`]?.patchValue(null);
          this.form3.controls[`row${i}col7`]?.patchValue(null);
          this.form3.controls[`row${i}col8`]?.patchValue(null);
          this.form3.controls[`row${i}col9`]?.patchValue(null);
          this.form3.controls[`row${i}col10`]?.patchValue(null);
        break;

      case this.blockStyle3048:
      case this.blockStyle4860:
      case this.blockStyle4890:
      case this.blockStyle48120:     
        {
          // Handle cases where pkgType matches one of the block styles
          const laborTimeBaseValue = +woodPal[0]?.labortime; // Labor time for row 0 - 70
          const laborTimeValue = laborTimeBaseValue + 5 * (masterOrPallet);
          const length = +woodPal[0]?.length;
          const width = +woodPal[0]?.width;
          this.form3.controls[`row${i}col4`].patchValue(length);
          this.form3.controls[`row${i}col5`].patchValue(width);
          this.form3.controls[`row${i}col8`].patchValue(laborTimeValue);
          this.form3.controls[`row${i}col9`].patchValue(1); 
          this.woodPalletValidation();         
          break;
        }
      case this._blockstylewood:
        {
          const laborTimeBaseValue = +woodPal[0]?.labortime; // Labor time for row 0 - 70
          const laborTimeValue = laborTimeBaseValue + 5 * (masterOrPallet);         
          this.form3.controls[`row${i}col8`].patchValue(laborTimeValue);
          this.form3.controls[`row${i}col9`].patchValue(1);
          this.woodPalletValidation();
          break;
        }
      default:
        break;
    }

  }
  woodPalletValidation(){
    const woodPallet = this.form3.controls[`row2col1`]?.value;
    if(woodPallet !== this._blockstylewood){            
      this.form3.get('row2col4').disable();
      this.form3.get('row2col5').disable();
    }
    else{
      this.form3.get('row2col4').enable();
      this.form3.get('row2col5').enable();
    }
  }
  woodPalletHeight(){
    const row2col6 = this.form3.controls['row2col6']?.value;
    if(row2col6 && row2col6 > 1219.2){
      const control = this.form3.get(`row2col6`);
      control?.setValidators([Validators.max(1219.2)]);
      control?.updateValueAndValidity();
      this.woodPalletHeightValidation = true;
      this.markFormGroupTouched(this.form3);
      this.errorList.push("Please enter the height less than or equal to 1219.2");
    }
    else{
      this.woodPalletHeightValidation = false;
    }
  }
  //palletCase row 9 value mapping
  palletCaserow9Value(i: any) {
    let qtyValue: any
    const masterPkgType = validate(this.form3.controls['row0col1']?.value);
    const masterPkgType1 = validate(this.form3.controls['row1col1']?.value);
    const masterPkgType2 = validate(this.form3.controls['row2col1']?.value)
    if (masterPkgType !== '' || masterPkgType1 !== '' || masterPkgType2 !== '') {
      qtyValue = 1;
    }
    else {
      qtyValue = '';
    }
    this.form3.controls[`row${i}col9`]?.patchValue(qtyValue);
  }
  //palletCase col9 Validation
  palletCasecol9Validation(){
    const row3col9 = this.form3.controls['row3col9']?.value;
    const row4col9 = this.form3.controls['row4col9']?.value;
    const row5col9 = this.form3.controls['row5col9']?.value;
    const row6col9 = this.form3.controls['row6col9']?.value;         
    if (validate(row3col9) > 6  || validate(row4col9) > 6 || validate(row5col9) > 6 || validate(row6col9) > 6) {   
      this.isSaveMethod();
      this.masterRowValidations = true;
      for (let i = 3; i <= 6; i++) {
        const control = this.form3.get(`row${i}col9`);
        control?.setValidators([Validators.max(6)]);
        control?.updateValueAndValidity();
      }
      this.markFormGroupTouched(this.form3);
      this.errorList.push("The value in this cell must be a whole number. If interior packaging is > 6 please reach out to your Packaging Engineer.");
    }
    else if(validate(row3col9) <= 6  || validate(row4col9) <= 6 || validate(row5col9) <= 6 || validate(row6col9) <= 6){
      this.masterRowValidations = false;
      for (let i = 3; i <= 6; i++) {
        const control = this.form3.get(`row${i}col9`);
        control?.clearValidators();
        control?.updateValueAndValidity();
      } 
     this.isSaveMethod();
    }
    else{
      this.isSaveMethod();
    }
  }
  isSaveMethod(){
    if(!this.isSave){
      this.close();
    }
  }
  setGridPalletCase(data: any) {
    if (this.mode === 1) {
      // dynamic rows patch from row0 to row4
      for (let i = 0; i < 7; i++) {
        this.form3.controls[`row${i}col1`].patchValue(validate(data[i]?.packagingtype));
        this.form3.controls[`row${i}col2`].patchValue(validate(data[i]?.materialdescription));
        this.form3.controls[`row${i}col3`].patchValue(safeToFixed(validate(data[i]?.weight)));
        this.form3.controls[`row${i}col4`].patchValue(safeToFixed(validate(data[i]?.length)));
        this.form3.controls[`row${i}col5`].patchValue(safeToFixed(validate(data[i]?.width)));
        this.form3.controls[`row${i}col6`].patchValue(safeToFixed(validate(data[i]?.height)));
        this.form3.controls[`row${i}col7`].patchValue(safeToFixed(validate(data[i]?.unitcost)));
        this.form3.controls[`row${i}col8`].patchValue(validate(data[i]?.labortime));
        this.form3.controls[`row${i}col9`].patchValue(validate(data[i]?.qty));
        this.form3.controls[`row${i}col10`].patchValue(validate(data[i]?.comment));
      }
      // row7
      this.form3.controls['row7col7'].patchValue(0.090) //Material unit cost for row 5
      this.form3.controls['row7col8'].patchValue(3) //Labor Time for row 5
      this.form3.controls['row7col9'].patchValue(validate(data[7]?.qty))
      this.form3.controls['row7col10'].patchValue(validate(data[7]?.comment))
      // row8
      this.form3.controls['row8col7'].patchValue(0.075) //Material unit cost for row 6
      this.form3.controls['row8col8'].patchValue(3) //Labor Time for row 6
      this.form3.controls['row8col9'].patchValue(validate(data[8]?.qty))
      this.form3.controls['row8col10'].patchValue(validate(data[8]?.comment))
    }
    else {
      setNonEditableGridPalletCase(this.palletCaseTable, data);
    }    
  }
  //To disable fields
  disableCheck3() {
    // Define specific controls that need to be disabled
    const controlsToDisable = new Set([     
      'row0col2', 'row0col3', 'row0col4', 'row0col5', 'row0col6', 'row0col7', 'row0col8', 'row0col9',
      'row1col2', 'row1col8', 'row1col9', 'row2col8', 'row2col9','row7col1','row8col1',
      'row7col2', 'row7col3', 'row7col4', 'row7col5', 'row7col6', 'row7col7', 'row7col8', 'row7col9',
      'row8col2', 'row8col3', 'row8col4', 'row8col5', 'row8col6', 'row8col7', 'row8col8', 'row8col9',
    ]);
    // Loop through all form controls
    for (const key of Object.keys(this.form3.controls)) {
      // Check if the control should be disabled
      if (controlsToDisable.has(key)) {
        this.form3.controls[key]?.disable();
      }
    }
  }
  
  clearPalletDropdown(row: number) {
    const rowValues = ['row0', 'row1', 'row2'];
    const selectedRow = rowValues[row];
    const formKeys = Object.keys(this.form3.controls);
    formKeys.forEach(key => {
      // Check if the key belongs to the selected row
      if (!key.includes(selectedRow)) {
        if (key.startsWith('row0') || key.startsWith('row1') || key.startsWith('row2')) {
          this.form3.controls[key]?.patchValue(null); // Clear controls for the selected row
        }
      }
    });
  }
 
  //grid4
  onInputChange4(row:number,col:number){
    this.onInputChange2();
    this.onInputChange3();      
    this.palletCaseLoadGross();
    if(row === 0 && col === 7){
      this.keyUnitPkg();
    }
    else if(row === 1 && col === 7){
      this.keyMasterpackValidation();
    }   
    this.getTotalPkgCost()
  }
  homePositionDropChange(){
    this.getPartDetailsAfterChange(this.clickedPartIndex);
  }
  getPartDetailsAfterChange(i:number){
    if(i >=0){
      this.partDesc = this.form5.controls[`row${i}col2`]?.value;
      this.homePosition = this.form5.controls[`row${i}col3`]?.value;
      this.partLength = this.form5.controls[`row${i}col5`]?.value;
      this.partWidth = this.form5.controls[`row${i}col6`]?.value;
      this.partHeight = this.form5.controls[`row${i}col7`]?.value;
      this.nonEditablePkgGrid();
    }
  }
  //set grid values
  setGridPkgData(){     
      //col7
      this.form4.controls[`row0col7`]?.patchValue(validate(this.serPpfDes[0]?.piecesperunit));
      this.form4.controls[`row1col7`]?.patchValue(validate(this.serPpfDes[0]?.packspermasterpack));
      this.form4.controls[`row2col7`]?.patchValue(validate(this.serPpfDes[0]?.totalunitspermasterpack));
      //col5     
      this.form4.controls[`row1col5`]?.patchValue(safeToFixed(validate(this.serPpfDes[0]?.unitpkggrosswt)));
      this.form4.controls[`row2col5`]?.patchValue(safeToFixed(validate(this.serPpfDes[0]?.palletloadgrosswt)));
      //col3     
      this.form4.controls[`row1col3`]?.patchValue(safeToFixed(validate(this.serPpfDes[0]?.contheight)));
      this.form4.controls[`row2col3`]?.patchValue(safeToFixed(validate(this.serPpfDes[0]?.contretheight)));
      //col2     
      this.form4.controls[`row1col2`]?.patchValue(safeToFixed(validate(this.serPpfDes[0]?.contwidth)));
      this.form4.controls[`row2col2`]?.patchValue(safeToFixed(validate(this.serPpfDes[0]?.contretwidth)));
      //col1      
      this.form4.controls[`row1col1`]?.patchValue(safeToFixed(validate(this.serPpfDes[0]?.contlength)));
      this.form4.controls[`row2col1`]?.patchValue(safeToFixed(validate(this.serPpfDes[0]?.contretlength))) 
      this.nonEditablePkgGrid();
  } 
  nonEditablePkgGrid(){      
    //row0
    if(this.mode === 1){
      this.tableData[0].column2 = this.partLength;
      this.tableData[0].column3 = this.partWidth;
      this.tableData[0].column4 = this.partHeight;
    }
    else{
      this.tableData[0].column2 = this.form4.controls[`row0col1`]?.value;
      this.tableData[0].column3 = this.form4.controls[`row0col2`]?.value;
      this.tableData[0].column4 = this.form4.controls[`row0col3`]?.value;
    }
  
    this.tableData[0].column6 = validate(Math.max(...this.serPPFPartTable.map((o: any) => o.partweight)));
    this.tableData[0].column8 = validate(this.serPpfDes[0]?.piecesperunit);
    //row1  
     this.tableData[1].column2 = safeToFixed(validate(this.serPpfDes[0]?.contlength));
     this.tableData[1].column3 = safeToFixed(validate(this.serPpfDes[0]?.contwidth));
     this.tableData[1].column4 = safeToFixed(validate(this.serPpfDes[0]?.contheight));
     this.tableData[1].column6 = safeToFixed(validate(this.serPpfDes[0]?.unitpkggrosswt));
     this.tableData[1].column8 = validate(this.serPpfDes[0]?.packspermasterpack);
     //row2 
     this.tableData[2].column2 = safeToFixed(validate(this.serPpfDes[0]?.contretlength));
     this.tableData[2].column3 = safeToFixed(validate(this.serPpfDes[0]?.contretwidth));
     this.tableData[2].column4 = safeToFixed(validate(this.serPpfDes[0]?.contretheight));
     this.tableData[2].column6 = safeToFixed(validate(this.serPpfDes[0]?.palletloadgrosswt));
     this.tableData[2].column8 = validate(this.serPpfDes[0]?.totalunitspermasterpack);
  }
  disableCheck4() {
    // Define specific controls that need to be disabled
    const controlsToDisable = new Set([     
      'row0col5', 'row1col5', 'row2col5'
    ]);
    // Loop through all form controls
    for (const key of Object.keys(this.form4.controls)) {
      // Check if the control should be disabled
      if (controlsToDisable.has(key)) {
        this.form4.controls[key]?.disable();
      }
    }
  }
  //error code
  close(){
    this.errorList=[]
  }
  //tms Part
  dynamicPart(data: any) {
    const rowCount = this.mode === 1 ? data.length + 1 : 1;

    for (let i = 0; i < rowCount; i++) {
      for (let j = 0; j < this.columns5.length; j++) {
        this.form5.addControl(`row${i}col${j}`, this.fb.control(''));
      }
    }

    if (this.mode === 1) {
      this.tmsValue();
    }
  }
  dynamicpart2(row:any){
    for (let j = 0; j < this.columns5.length; j++) {
      this.form5.addControl(`row${row}col${j}`, this.fb.control(''));
    }
    this.tmsValue2(row);
  }
  partloop(data:any,flag:number){
    if (flag === 1) {
      const rowCount = this.mode === 1 ? data.length + 1 : 1;
      this.rows5 = [...Array(rowCount).keys()];
      this.dynamicPart(data);

      for (let i = 0; i < rowCount; i++) {
        this.data.push({
          col0: '', col1: '', col2: '', col3: '', col4: '',
          col5: '', col6: '', col7: '', col8: '', col9: '', col10: ''
        });
      }
    }
    if(this.partNum?.length > 10){
      this.tenDigitValue = this.partNum?.substring(0, 10);
    }
    else {
      this.tenDigitValue = this.partNum;
    }  
    this.indexValue = data?.findIndex((item:any) => item?.partnumber === this.tenDigitValue);
    if(this.mode === 1){
      this.setGridOne(data);
    }
    else{
      this.setNonEditableGrid1(data);
    }
  }
  addPart(event:any){
    event.stopPropagation();    
    this.rows5 = [...Array(this.rows5.length + 1).keys()];    
    this.data.push({col0:'', col1: '', col2: '', col3: '', col4: '', col6: '', col7: '', col8: '', col5: '', col9: '', col10:''});    
    this.dynamicpart2(this.rows5.length - 1);
    this.partLengthValidate();
    this.disableCheck5();
    this.addDelete();
  }
  addDelete(){
    this.deleteArray = [];
    for (let i = 0; i < this.rows5.length; i++) {
      this.deleteFun(i)
    }
  }
  onPartDelete(index:any){
    this.openDialog(index);
  }  
  deleteIndex(index: any) {    
    for (let j = 0; j < this.columns5.length; j++) {
      if (index < this.rows5.length - 1) {
        this.form5.patchValue({
          [`row${index}col${j}`]: this.form5.controls[`row${index + 1}col${j}`].value
        });
      } else {
        this.form5.removeControl(`row${index}col${j}`);
      }
    }
   
    this.data = this.data.filter((_, i) => i !== index);
    this.rows5 = [...Array(this.data.length).keys()];  
  }
  openDialog(index: any) {
    const dialogRef = this.dialog.open(DialogDivisionComponent, {
      data: {
        headerName: 'SPDF',
      },
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      const onloadLength = this.serPPFPartTable.length - 1;
      if (result === 'ok') {
        if (index > onloadLength) {
          this.deleteIndex(index);
          this.unitHeavyPartLabel();
          this.palletHeavyCaseLabel();
          this.getTotalPkgCost();
        }
        else {
          const deletedRecord = [];
          deletedRecord.push(this.serPPFPartTable.at(index));
          const body = {
            part : deletedRecord,
            basepartnumber : this.partNum,
            baseplantcode : this.ppfPlantCode ?? ''
          }
          this.internalPpfService.internalDelete(body).subscribe({
            next: (_res: any) => {
              if(_res?.error !== "true"){
                this.serPPFPartTable.splice(index, 1);
                const rowLength = this.rows5.length - 1;
                this.rows5 = [...Array(rowLength).keys()];
                this.partloop(this.serPPFPartTable, 0);
                this.disableCheck5();
                this.unitHeavyPartLabel();
                this.palletHeavyCaseLabel();
                this.getTotalPkgCost();              
              }
              else{
                this.errorList.push(_res?.msg);
                window.scrollTo(0,0);
              }
              this.spinner.stop();
            },
            error: (_err: any) => {
              this.spinner.stop();
            }
          })
        }
      }
    });
  }
  addPartOnload() {
    this.payloadArray = [];
    for (let i = 0; i < this.data.length; i++) {
      const column0 = this.form5.controls[`row${i}col0`]?.value;
      if (column0 !== '' && column0 !== undefined) {
        const twoDigitValue = this.form5.controls[`row${i}col1`]?.value;
        const partDesc =  this.form5.controls[`row${i}col2`]?.value;
        const hPosition = this.form5.controls[`row${i}col3`]?.value;
        const partW = this.form5.controls[`row${i}col4`]?.value;
        const pLength = this.form5.controls[`row${i}col5`]?.value;
        const pWidth = this.form5.controls[`row${i}col6`]?.value;
        const pHeight = this.form5.controls[`row${i}col7`]?.value;
        const pVolume = this.form5.controls[`row${i}col8`]?.value;
        const pM3 = this.form5.controls[`row${i}col9`]?.value;
        this.payloadArray.push({
          "partnumber": this.form5.controls[`row${i}col0`]?.value,
          "twodigits":(typeof twoDigitValue === 'string' ? twoDigitValue.replace(/\s+/g, '') : ''),
          "partdescription": partDesc || null,
          "homeposition": hPosition || null,
          "partweight": partW || null,
          "partlength": pLength || null,
          "partwidth": pWidth || null,
          "partheight": pHeight || null,
          "partvolume": pVolume || null,
          "partm3": pM3 || null,
          "partrow": i + 1
        })
      }
    }
  }
  onInputChange5() {
    this.close();
    for (let i = 0; i < this.data.length; i++) {
      const partLength = this.form5.controls[`row${i}col5`]?.value; // dynamic part length
      const partWidth = this.form5.controls[`row${i}col6`]?.value; // dynamic part width
      const partHeight = this.form5.controls[`row${i}col7`]?.value; // dynamic part height
      const result = convertAndCheck(partLength, partWidth, partHeight);
      this.form5.controls[`row${i}col9`]?.patchValue(toNumber(result));     
    }
    this.partLengthValidate();
    this.partWeight();
    this.unitPkgGross();
    this.palletCaseLoadGross();
    this.getTotalPkgCost();
  }
  partLengthValidate(){
    this.partLengthValidator = []
    for (let i = 0; i < this.data.length; i++){
      const part = validate(this.form5.controls[`row${i}col0`]?.value);
      if (part?.includes('-')) {
        this.partLengthValidator.push(true);
        this.errorList.push('Please do not enter a hyphen (-) in the part number');
        this.markFormGroupTouched(this.form5);
      }       
      else if ((!part || part?.length !== 10) && part?.length !== 0) {
        this.partLengthValidator.push(true)
        this.errorList.push('Please only enter the 10 digit part number');
        this.markFormGroupTouched(this.form5);
      }
      else{
        this.partLengthValidator.push(false)
      }
    }
    this.getPartDetailsAfterChange(this.clickedPartIndex);   
    this.unitHeavyPartLabel();
    this.palletHeavyCaseLabel();
  }
  tmsValue(){  
    const formKeys = Object.keys(this.form5.controls);
    for (const i of formKeys) {
      if (i.includes("col0")) { 
        const part = this.form5.controls[i]?.value;           
        if(part?.length < 10 || part?.length > 10){          
          this.form5.controls[i].setValidators([Validators.minLength(10), Validators.maxLength(10)]);
          this.form5.controls[i].updateValueAndValidity();       
        }
        else{
          this.form5.controls[i]?.clearValidators(); 
          this.form5.controls[i].updateValueAndValidity();            
        }       
      }
    }
  }
  tmsValue2(i:any){ 
    const part = this.form5.controls[`row${i}col0`]?.value;
    if(part?.length < 10 || part?.length > 10){          
      this.form5.controls[`row${i}col0`].setValidators([Validators.minLength(10), Validators.maxLength(10)]);
      this.form5.controls[`row${i}col0`].updateValueAndValidity();       
    }
    else{
      this.form5.controls[`row${i}col0`]?.clearValidators(); 
      this.form5.controls[`row${i}col0`].updateValueAndValidity();            
    }   
  }
  setGridOne(data:any) {
    //dynamic rows
    this.deleteArray = [];
    for (let i = 0; i < this.data.length; i++) {
      if(this.indexValue !== -1){
        this.form5.controls[`row${i}col0`].patchValue(data[i]?.partnumber);
        this.form5.controls[`row${i}col1`].patchValue(data[i]?.twodigits);
        this.form5.controls[`row${i}col2`].patchValue(data[i]?.partdescription);    
        this.form5.controls[`row${i}col3`].patchValue(data[i]?.homeposition);
        this.form5.controls[`row${i}col4`].patchValue(safeToFixed(validate(data[i]?.partweight)));
        this.form5.controls[`row${i}col5`].patchValue(safeToFixed(validate(data[i]?.partlength)));
        this.form5.controls[`row${i}col6`].patchValue(safeToFixed(validate(data[i]?.partwidth)));
        this.form5.controls[`row${i}col7`].patchValue(safeToFixed(validate(data[i]?.partheight)));
        this.form5.controls[`row${i}col8`].patchValue(data[i]?.partvolume);
        this.form5.controls[`row${i}col9`].patchValue(data[i]?.partm3);
        this.deleteFun(i)
      }
    } 
  }
  deleteFun(i:any){
    if(this.tenDigitValue === this.form5.controls[`row${i}col0`]?.value ){
      this.form0.controls['partsDesc'].patchValue(this.form5.controls[`row${i}col2`]?.value);
      this.form0.controls['homePosition'].patchValue(this.form5.controls[`row${i}col3`]?.value);
      this.form0.controls['tenDigit'].patchValue(this.form5.controls[`row${i}col0`]?.value);
      this.clickedPartIndex = i;
      this.getPartDetailsAfterChange(i);
      this.deleteArray.push(false)   
    } else{
      this.deleteArray.push(true);    
    }  
  }
  setNonEditableGrid1(data: any) {
    //dynamic rows
    for (let i = 0; i < this.data.length; i++) {
      if (this.mode !== 1) {
        if (this.indexValue !== -1) {
          const removedItem = data.splice(this.indexValue, 1)[0];
          data.unshift(removedItem);
          const last2Digits = this.partNum.length > 10 ? this.partNum.slice(10) : '';
          if (data[i]?.partnumber === this.tenDigitValue) {
            this.assignDataValues(i, data, last2Digits);
            break;
          }
        }
      }
    }
    this.nonEditablePkgGrid();
  }
  assignDataValues(i: number, data: any, twodigit: string){
    this.data[i].col0 = data[i]?.partnumber;
    this.data[i].col1 = twodigit; 
    this.data[i].col2 = data[i]?.partdescription;
    this.data[i].col3 = data[i]?.homeposition;
    this.data[i].col4 = safeToFixed(validate(data[i]?.partweight));
    this.data[i].col5 = safeToFixed(validate(data[i]?.partlength));
    this.data[i].col6 = safeToFixed(validate(data[i]?.partwidth));
    this.data[i].col7 = safeToFixed(validate(data[i]?.partheight));
    this.data[i].col8 = data[i]?.partvolume;
    this.data[i].col9 = data[i]?.partm3?.toFixed(5);
    this.form0.controls['partsDesc']?.patchValue(data[i]?.partdescription);
    this.form0.controls['homePosition']?.patchValue(data[i]?.homeposition);
    this.form4.controls['row0col1']?.patchValue(safeToFixed(validate(data[i].partlength)));
    this.form4.controls['row0col2']?.patchValue(safeToFixed(validate(data[i].partwidth)));
    this.form4.controls['row0col3']?.patchValue(safeToFixed(validate(data[i].partheight)));
  }  
  disableCheck5() {
    const formKeys = Object.keys(this.form5.controls);
    for (const i of formKeys) {
      if (i.includes("col9")) {
        this.form5.get(i)?.disable();
      }
    }
  } 
  
  getTotalPkgCost() {
    this.unitpkgcost = this.getUnitpkgcost();
    this.interpkgcost = this.getInterpkgcost();
    this.masterPalletPkgCost = this.getMasterPalletPkgCost();
    if (validate(this.form4.controls['row2col7']?.value) && this.form4.controls['row2col7']?.value !== 0) {
      const res = parseFloat(this.getCI(this.form4.controls['row2col7']?.value, this.form4.controls['row0col7']?.value, this.form4.controls['row1col7']?.value, this.unitpkgcost, this.interpkgcost, this.masterPalletPkgCost)) / this.form4.controls['row2col7']?.value;
      if (!isNaN(res) && typeof res === 'number' && validate(res) !== '') {
        this.totalCostValue = parseFloat(res?.toFixed(2));
        this.form0.controls['totalUnitPkg']?.patchValue('$' + parseFloat(res?.toFixed(2)));
      } else {
        this.form0.controls['totalUnitPkg']?.patchValue('');
      }
    } else {
      this.form0.controls['totalUnitPkg']?.patchValue('')
    }
  }
    
    
  getCI(a1: any, b1: any, c1: any, unit: any, inter: any, master: any) {
    const a = Number(a1);
    if (validate(a) !== '') {
      let s1 = 0;
      let s2 = 0;
      let res = 0;
      const b = Number(b1);
      const c = Number(c1);
      if (validate(b) && b !== 0) {
        if (validate(unit) !== '') {
          s1 = parseFloat(((a * Number(unit)) / b).toFixed(4));
        }
      }
      if (validate(inter) !== '') {
        s2 = parseFloat((c * Number(inter)).toFixed(4));
      }
      if (validate(master) !== '') {
        res = (s1 + s2 + Number(master));
      }
      return res.toFixed(4);
    } else {
      return '0';
    }
  }
    
  getUnitpkgcost() {
    const laborRate = 0;
    const M0 = validate(this.form1.controls['row0col7']?.value);
    const M1 = validate(this.form1.controls['row1col7']?.value);
    const M2 = validate(this.form1.controls['row2col7']?.value);
    const M3 = validate(this.form1.controls['row3col7']?.value);
    const M4 = validate(this.form1.controls['row4col7']?.value);
    const M5 = validate(this.form1.controls['row5col7']?.value);
    const M6 = validate(this.form1.controls['row6col7']?.value);
    const M7 = validate(this.form1.controls['row7col7']?.value);
    const M8 = validate(this.form1.controls['row8col7']?.value);
    const L0 = validate(this.form1.controls['row0col8']?.value);
    const q0 = validate(this.form1.controls['row0col9']?.value);
    const L1 = validate(this.form1.controls['row1col8']?.value);
    const q1 = validate(this.form1.controls['row1col9']?.value);
    const L2 = validate(this.form1.controls['row2col8']?.value);
    const q2 = validate(this.form1.controls['row2col9']?.value);
    const L3 = validate(this.form1.controls['row3col8']?.value);
    const q3 = validate(this.form1.controls['row3col9']?.value);
    const L4 = validate(this.form1.controls['row4col8']?.value);
    const q4 = validate(this.form1.controls['row4col9']?.value);
    const L5 = validate(this.form1.controls['row5col8']?.value);
    const q5 = validate(this.form1.controls['row5col9']?.value);
    const L6 = validate(this.form1.controls['row6col8']?.value);
    const q6 = validate(this.form1.controls['row6col9']?.value);
    const L7 = validate(this.form1.controls['row7col8']?.value);
    const q7 = validate(this.form1.controls['row7col9']?.value);
    const L8 = validate(this.form1.controls['row8col8']?.value);
    const q8 = validate(this.form1.controls['row8col9']?.value);
    this.unitlabortime = ((L0 * q0) + (L1 * q1) + (L2 * q2) + (L3 * q3) + (L4 * q4) + (L5 * q5) + (L6 * q6) + (L7 * q7) + (L8 * q8))
    this.unitmaterialcost = Number((M0 * q0) + (M1 * q1) + (M2 * q2) + (M3 * q3) + (M4 * q4) + (M5 * q5) + (M6 * q6) + (M7 * q7) + (M8 * q8))?.toFixed(4)
    this.unitlaborcost = ((this.unitlabortime * laborRate) / 3600)?.toFixed(4);
    return ((Number(this.unitlaborcost) + Number(this.unitmaterialcost))?.toFixed(4));
  }
    
  getInterpkgcost() {
    const laborRate = 0;
    const M0 = validate(this.form2.controls['row0col7']?.value);
    const M1 = validate(this.form2.controls['row1col7']?.value);
    const M2 = validate(this.form2.controls['row2col7']?.value);
    const M3 = validate(this.form2.controls['row3col7']?.value);
    const M4 = validate(this.form2.controls['row4col7']?.value);
    const L0 = validate(this.form2.controls['row0col8']?.value);
    const q0 = validate(this.form2.controls['row0col9']?.value);
    const L1 = validate(this.form2.controls['row1col8']?.value);
    const q1 = validate(this.form2.controls['row1col9']?.value);
    const L2 = validate(this.form2.controls['row2col8']?.value);
    const q2 = validate(this.form2.controls['row2col9']?.value);
    const L3 = validate(this.form2.controls['row3col8']?.value);
    const q3 = validate(this.form2.controls['row3col9']?.value);
    const L4 = validate(this.form2.controls['row4col8']?.value);
    const q4 = validate(this.form2.controls['row4col9']?.value);
    this.interlabortime = ((L0 * q0) + (L1 * q1) + (L2 * q2) + (L3 * q3) + (L4 * q4))
    this.intermaterialcost = Number((M0 * q0) + (M1 * q1) + (M2 * q2) + (M3 * q3) + (M4 * q4))?.toFixed(4)
    this.interlaborcost = ((this.interlabortime * laborRate) / 3600)?.toFixed(4);
    return ((Number(this.interlaborcost) + Number(this.intermaterialcost))?.toFixed(4));
  }
  getMasterPalletPkgCost() {
    const laborRate = 0;
    const masterdrop1 = validate(this.form3.controls['row0col1']?.value);
    const masterdrop2 = validate(this.form3.controls['row1col1']?.value);
    const masterdrop3 = validate(this.form3.controls['row2col1']?.value);
    let M0 = 0; let L0 = 0; let q0 = 0; let M1 = 0; let L1 = 0; let q1 = 0; let L2 = 0; let M2 = 0; let q2 = 0
    if (masterdrop1 !== '') {
      M0 = validate(this.form3.controls['row0col7']?.value);
      L0 = validate(this.form3.controls['row0col8']?.value);
      q0 = validate(this.form3.controls['row0col9']?.value);
    } else if (masterdrop2 !== '') {
      M1 = validate(this.form3.controls['row1col7']?.value);
      L1 = validate(this.form3.controls['row1col8']?.value);
      q1 = validate(this.form3.controls['row1col9']?.value);
    } else if (masterdrop3 !== '') {
      L2 = validate(this.form3.controls['row2col8']?.value);
      q2 = validate(this.form3.controls['row2col9']?.value);
      M2 = validate(this.form3.controls['row2col7']?.value);
    }
    const M3 = validate(this.form3.controls['row3col7']?.value);
    const M4 = validate(this.form3.controls['row4col7']?.value);
    const M5 = validate(this.form3.controls['row5col7']?.value);
    const M6 = validate(this.form3.controls['row6col7']?.value);
    const M7 = validate(this.form3.controls['row7col7']?.value);
    const M8 = validate(this.form3.controls['row8col7']?.value);
    const L3 = validate(this.form3.controls['row3col8']?.value);
    const q3 = validate(this.form3.controls['row3col9']?.value);
    const L4 = validate(this.form3.controls['row4col8']?.value);
    const q4 = validate(this.form3.controls['row4col9']?.value);
    const L5 = validate(this.form3.controls['row5col8']?.value);
    const q5 = validate(this.form3.controls['row5col9']?.value);
    const L6 = validate(this.form3.controls['row6col8']?.value);
    const q6 = validate(this.form3.controls['row6col9']?.value);
    const L7 = validate(this.form3.controls['row7col8']?.value);
    const q7 = validate(this.form3.controls['row7col9']?.value);
    const L8 = validate(this.form3.controls['row8col8']?.value);
    const q8 = validate(this.form3.controls['row8col9']?.value);
    this.masterlabortime = ((L0 * q0) + (L1 * q1) + (L2 * q2) + (L3 * q3) + (L4 * q4) + (L5 * q5) + (L6 * q6) + (L7 * q7) + (L8 * q8))
    this.mastermaterialcost = Number((M0 * q0) + (M1 * q1) + (M2 * q2) + (M3 * q3) + (M4 * q4) + (M5 * q5) + (M6 * q6) + (M7 * q7) + (M8 * q8))?.toFixed(4)
    this.masterlaborcost = ((this.masterlabortime * laborRate) / 3600)?.toFixed(4);
    return ((Number(this.masterlaborcost) + Number(this.mastermaterialcost))?.toFixed(4));
  }
    
  palletHeavyCaseLabel() {
    const J9 = this.form4.controls['row1col7']?.value;
    const J10 = this.form4.controls['row2col7']?.value;
    let maxVal: any;
    const valuesArray: number[] = [];
    for (let i = 0; i < this.data.length; i++) {
      if (this.form5.controls[`row${i}col4`]?.value) {
        valuesArray.push(safeToFixed(validate(this.form5.controls[`row${i}col4`]?.value)));
      }
    }
    if (validate(valuesArray)) {
      const filteredArray = valuesArray.filter(value => value !== null && value !== undefined && !isNaN(value));
      if (filteredArray.length > 0) {
        maxVal = Math.max(...filteredArray);
      }
    } else {
      maxVal = 0;
    }
    const valuesArray1: number[] = [];
    const valuesArray2: number[] = [];
    for (let i = 0; i < 4; i++) {      
        valuesArray1.push(this.form1.controls[`row${i}col3`]?.value);           
        valuesArray2.push(this.form1.controls[`row${i}col9`]?.value)      
    }
    const sumProd = sumProduct(valuesArray1, valuesArray2);

    const valuesArray3: number[] = [];
    const valuesArray4: number[] = [];
    for (let i = 0; i < 4; i++) {     
        valuesArray3.push(this.form2.controls[`row${i}col3`]?.value);   
        valuesArray4.push(this.form2.controls[`row${i}col9`]?.value)      
    }
    const sumProd1 = sumProduct(valuesArray3, valuesArray4);
    const valuesArray5: number[] = [];
    const valuesArray6: number[] = [];
    for (let i = 0; i < 7; i++) {      
        valuesArray5.push(this.form3.controls[`row${i}col3`].value);      
        valuesArray6.push(this.form3.controls[`row${i}col9`].value)    
    }   
    this.palletHeavyCaseLabelsub1(valuesArray5,valuesArray6,sumProd1,maxVal,sumProd,J9,J10);
  }
  palletHeavyCaseLabelsub1(valuesArray5:any,valuesArray6:any,sumProd1:any,maxVal:any,sumProd:any,J9:any,J10:any){
    const sumProd2 = sumProduct(valuesArray5, valuesArray6);
    const masterPkgType = validate(this.form3.controls['row0col1'].value);
    const expendablePkgType = validate(this.form3.controls['row1col1'].value);
    const woodPalPkgType = validate(this.form3.controls['row2col1'].value);
    if (masterPkgType === '' && woodPalPkgType === '') {
      this.form3.controls['row8col9']?.patchValue('')           
    }else if(expendablePkgType !==''){
      this.form3.controls['row8col9']?.patchValue('')
    }
    else {
      // Calculate the expressions
      const expr1 = validate(sumProd2);
      const expr2 = validate(sumProd1) * J9;
      const expr3 = validate(maxVal) * J10;
      const expr4 = validate(sumProd) * J10;     
      // Check the condition to get 1 in qty
      if (Number(expr1 + expr2 + expr3 + expr4) > 227500) {
        this.form3.controls['row8col9']?.patchValue(1)
      } else {        
        this.form3.controls['row8col9']?.patchValue("")
      }
    }
    
  } 

  unitHeavyPartLabel() {
    let maxVal: any;
    const valuesArray: number[] = [];
    for (let i = 0; i < this.data.length; i++) {
      if (this.form5.controls[`row${i}col4`]?.value) {
        valuesArray.push(validate(this.form5.controls[`row${i}col4`]?.value));
      }
    }
    if (validate(valuesArray)) {
      const filteredArray = valuesArray.filter(value => value !== null && value !== undefined && !isNaN(value));
      if (filteredArray.length > 0) {
        maxVal = Math.max(...filteredArray);
      }
    } else {
      maxVal = 0;
    }
    const valuesArray1: number[] = [];
    const valuesArray2: number[] = [];
    for (let i = 0; i < 4; i++) {
        valuesArray1.push(this.form1.controls[`row${i}col3`]?.value);
        valuesArray2.push(this.form1.controls[`row${i}col9`]?.value)
    }
    const sumProd = sumProduct(valuesArray1, valuesArray2);
    if ((maxVal + sumProd) > 22700) {
      this.form1.controls['row5col9'].patchValue(1)
    } else {
      this.form1.controls['row5col9'].patchValue('')
    }
  }

  partWeight() {
    const valuesArray: number[] = [];
    let maxVal: any;
    for (let i = 0; i < this.data.length; i++) {
      if (this.form5.controls[`row0col4`]?.value) {
        valuesArray.push(validate(this.form5.controls[`row${i}col4`]?.value));
      }
    }
    if (validate(valuesArray)) {
      const filteredArray = valuesArray.filter(value => value !== null && value !== undefined && !isNaN(value));
      if (filteredArray.length > 0) {
        maxVal = Math.max(...filteredArray);
      }
    } else {
      maxVal = 0;
    }
    this.form4.controls['row0col5']?.patchValue(safeToFixed(maxVal))

  }

  unitPkgGross() {
    let res: any;
    let maxVal: any;
    const valuesArray: number[] = [];
    if (validate(this.form5.controls.row0col4?.value)) {
      res = this.unitPkgGrossSubMethod(valuesArray, maxVal);
    } else {
      res = 0;
    }
    this.form4.controls['row1col5'].patchValue(safeToFixed(res));
    this.palletCaseLoadGross();
  }

  unitPkgGrossSubMethod(valuesArray: any, maxVal: any) {
    for (let i = 0; i < this.data.length; i++) {
      if (this.form5.controls[`row0col4`]?.value) {
        valuesArray.push(validate(this.form5.controls[`row${i}col4`]?.value));
      }
    }
    if (validate(valuesArray)) {
      const filteredArray = valuesArray.filter((value: any) => value !== null && value !== undefined && !isNaN(value));
      if (filteredArray.length > 0) {
        maxVal = Math.max(...filteredArray);
      }
    } else {
      maxVal = 0;
    }
    let sumProduct = 0;
    for (let i = 0; i < 4; i++) {
      const col3Value = this.form1.controls[`row${i}col3`]?.value;
      const col9Value = this.form1.controls[`row${i}col9`]?.value;
      if (!isNaN(col3Value) && !isNaN(col9Value)) {
        sumProduct += col3Value * col9Value;
      }
    }
    return safeToFixed(maxVal + sumProduct);
  }

  palletCaseGrossWeight(row:number,col:number){
    const limit = 362800;
    if (row === 2 && col === 5) {
      if (Number(this.form4.get('row2col5')?.value) >= Number(limit)) {
        return { setbackgroundPalletGross: true };
      }else{
        return { setbackgroundPalletGross: false };
      }
    }
    return { setbackgroundPalletGross: false };
  }

  palletCaseLoadGross(){
    const sum1 = validate(this.form4.controls['row1col5']?.value) * validate(this.form4.controls['row2col7']?.value);
    let sumProduct1 = 0;
    let sumProduct2 = 0;
    for (let i = 0; i < this.intermediateTable.length; i++) {
      const col3Value = this.form2.controls[`row${i}col3`].value;
      const col9Value = this.form2.controls[`row${i}col9`].value;
      if (!isNaN(col3Value) && !isNaN(col9Value)) {
        sumProduct1 += col3Value * col9Value;
      }
    }
    for (let i = 0; i < this.palletCaseTable.length; i++) {
      const col3Value = this.form3.controls[`row${i}col3`].value;
      const col9Value = this.form3.controls[`row${i}col9`].value;
      if (!isNaN(col3Value) && !isNaN(col9Value)) {
        sumProduct2 += col3Value * col9Value;
      }
    }
    
    const res = (sum1 + (this.form4.controls['row1col7'].value * (sumProduct1) + sumProduct2)) / 1000;      
    this.form4.controls['row2col5']?.patchValue(safeToFixed(res));
  }

  handleImageUpload(base64: any, filename: any, number: any){
    if (base64) {
      const body:any = {
        "PPFCode": this.routeParams?.ppfcode,
        "PPFVer": this.routeParams?.ppfver,
        "pType": number,
        "File": {
          "FileName": filename,
          "Data": base64.split(',')[1]
        }
      };
      if(this.routeParams.external === "1"){
        body['PPFVer'] = -1
      }
      this.spinner.start();
      this.internalPpfService.uploadImageOnload(body).subscribe({
        next: (_res: any) => {
          if (_res.status) {
            this.imageNames[number - 1] = _res?.response?.imageName;
            this.image[number - 1] = this.sanitizer.sanitize(SecurityContext.URL,base64);
          }
          this.spinner.stop();
        },
        error: (_err: any) => {
          this.spinner.stop();
        }
      });
    }
  }

  handleUpload(event: any, imageNumber: any) {
    const file = event.target.files[0];
    const reader: any = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      if (imageNumber) {
        //get file type
        const prefix = 'data:image/';
        const base64Image: any = reader.result
        const base64Parts = base64Image?.split(';base64');
        const contentType = base64Parts[0].substring(prefix.length)
        //get file size
        const fileSizeInBytes = base64Image.length * 0.75;
        const fileSizeInKb = fileSizeInBytes / 1024;
        this.formatError[imageNumber - 1] = ''
        if (['jpeg', 'jpg', 'gif'].includes(contentType)) {
          if (fileSizeInKb < 200) {
            if(file && file.name.length > 50){
              this.formatError[imageNumber - 1] = 'Image file name should be less than 50 Chars'
            }
            else{
              this.handleImageUpload(reader.result, file?.name, imageNumber);
              this.imageValidBorder(imageNumber,true);
            }            
          } else {
            this.formatError[imageNumber - 1] = 'Image size should be less than 200kb'
          }
        } else {
          this.formatError[imageNumber - 1] = 'Image should be of Jpg or Gif format'
        }
      }
    };
    reader.onerror = () => { alert('Error while uploading') }
  }
  imageValidBorder(number: any, boolValue: any) {
    switch (number) {
      case 1: {
        this.imageValid0 = boolValue;
        break;
      }
      case 2: {
        this.imageValid1 = boolValue;
        break;
      }
      case 4: {
        this.imageValid3 = boolValue;
        break;
      }
      case 5: {
        this.imageValid4 = boolValue;
        break;
      }
    }
  }

  handleDelete(imageNumber: number) {
    this.imageNames[imageNumber - 1] = ""
    this.image[imageNumber - 1] = ""
    this.imageValidBorder(imageNumber,false);
  }

  //dynamic images
getImagePayload(imageNumber: number) {
  let payload: any;  
  if (this.url.includes('tracking-list/send-dept-approval') || this.url.includes('part-list') || this.url.includes('tracking-list/sI-final-dept-approval') ||
    this.url.includes(this.trackingUrl) || this.url.includes('tracking-list/tracking-history') || this.url.includes('hazmat-approve-list/hazmat-approval') || this.url.includes('tracking-list/send-ppf')) {
    payload = {
      "PPFCode": this.routeParams?.ppfcode,
      "ImgName": this.imageNames[imageNumber],
      "ImgNum": imageNumber + 1,
      "PPFVer": this.routeParams?.ppfver,
      "ResPPFVer": ''
    }
  } else if(this.routeParams.external === "1" && this.routeParams.viewMode === "1"){
    payload = {
      "PPFCode": this.routeParams?.ppfcode,
      "ImgName": this.imageNames[imageNumber],
      "ImgNum": imageNumber + 1,
      "PPFVer": "-1",
      "ResPPFVer": ''
    }
  }
  else if (this.url.includes('dept-approve') || this.url.includes('discripency-list') ||
    (this.url.includes(this.trackingUrl) && this.mode === 2) || (this.url.includes('update-ppf-reason') && this.mode === 2) || this.routeParams.external === "1") {
    payload = {
      "PPFCode": this.routeParams?.ppfcode,
      "ImgName": this.imageNames[imageNumber],
      "ImgNum": imageNumber + 1,
      "PPFVer": '',
      "ResPPFVer": this.response?.PPFVer
    }
  }
  return payload
}
getOnLoadImages(imageNumber: number) {
  const payload = this.getImagePayload(imageNumber) 
  this.internalPpfService.getImageOnload(payload).subscribe({
    next: (res: any) => {
      if (res ?? res.data) {
        const data = res.data;      
        if (data) {
          const image = this.createBase64Image(data);
          this.image[imageNumber] = image;
          this.zoomImage[imageNumber]=`data:image/jpg;base64,${data}`
        } else {
          this.image[imageNumber] = null;
        }
      } else {
        this.image[imageNumber] = null;
      }
    },    
  })
}

createBase64Image(data: any) {
  return this.sanitizer.sanitize(SecurityContext.URL,`data:image/jpg;base64,${data}`);
}

openWindow(i:any){
  const data = i;
  const w = window.open('about:blank');
  const image = new Image();
  image.src = data;
  setTimeout(function(){
    w?.document.write('<style> img{max-width:100%; height:auto;}</style>'+image.outerHTML);
  }, 0);
}
//unit package validation
keyUnitPkg(){
  this.markFormGroupTouched(this.form4);  
  if(this.form4.controls['row0col7'].value>10){
    this.keyUnitPkgInd=true;       
    this.errorList.push("Please enter 'QUANTITY PARTS/UNIT PKG' < = 10")
  }else{
    this.keyUnitPkgInd = false;
  }
}
//master validation j9
keyMasterpackValidation() {     
  this.markFormGroupTouched(this.form4)
  if (this.form4.controls['row1col7'].value > 200) {    
    this.errorList.push("Please enter value <= 200")
    this.keyMasterInd = true;
  }
  else{
    this.keyMasterInd = false;    
  }
}
//image validation
imageValidation() {
  this.imageValid = true;

  const requiredImages = [
    { index: 0, message: 'BARE PART IMAGE IS REQUIRED' },
    { index: 1, message: 'PKG DUNNAGE AROUND PART IMAGE IS REQUIRED' },
    { index: 3, message: 'PALLET/CASE LOAD AS SHIPPED IMAGE IS REQUIRED' },
    { index: 4, message: 'UNIT & INTERMEDIATE LABEL IMAGE IS REQUIRED' }
  ];

  requiredImages.forEach(({ index, message }) => {
    const isValid = !!this.image[index];
    if (!isValid) {
      this.errorList.push(message);
      this.imageValid = false;
    }

    (this as any)[`imageValid${index}`] = isValid;
  });
}

// part validate payload 

getValidatePartBody() {
  const body: any = {
    "screenMode": this.screenMode,
    "ppfcode": Number(this.routeParams?.ppfcode) || null,
    "ppfversion": Number(this.routeParams?.ppfver) || null,
    "vendorName": this.form0.controls['venName']?.value || null,
    "vendorCode": this.form0.controls['venCode']?.value || null,
    "part": this.payloadArray,
    "basepartnumber" : this.partNum,
    "baseplantcode" : this.ppfPlantCode ?? ''
  }
  return body;
}

//save payload
//save ppf
getSavePPFBody() {
  let updatePPFReasons;

  if(this.routeParams.external === '1'){
    if (sessionStorage.getItem('ppfPassData')) {
      updatePPFReasons = JSON.parse(sessionStorage.getItem('ppfPassData') ?? '');
    }
  }
  else{
    if (sessionStorage.getItem('updatePPFReasons')) {
      updatePPFReasons = JSON.parse(sessionStorage.getItem('updatePPFReasons') ?? '');
    }
  }
  
  const body: any = {
    "screenMode": this.screenMode,
    "ppfcode": Number(this.routeParams?.ppfcode) || null,
    "ppfversion": Number(this.routeParams?.ppfver) || null,
    "date": this.date || null,
    "status": '1',
    "vendorName": this.form0.controls['venName']?.value || null,
    "vendorCode": this.form0.controls['venCode']?.value || null,
    "vendorpickuploc": this.form0.controls['venPickUpLoc']?.value || null,
    "hazmat": this.form0.controls['hazmat']?.value || null,
    "requesttype": this.form0.controls['requestType']?.value || null,
    "parttype": this.form0.controls['partType']?.value || null,
    "programcode": this.form0.controls['projectCode']?.value || null,    
    "laborrate": 0,
    "totalunitpackagingcost": this.totalCostValue || null,
    "basepartnumber": this.partNum,
    "baseplantcode": this.ppfPlantCode ?? '',
    "isextchange":  (this.form0.controls['requestType']?.value === this.PACKAGING_KAIZEN) ? (this.form0.controls['isExtChange']?.value || "N") : "N",

    //images
    "imgbarepart": this.imageNames[0] ? this.imageNames[0] : null,
    "imgpkgdunaroundpart": this.imageNames[1] ? this.imageNames[1] : null,
    "imgintermediatepkgpart": this.imageNames[2] ? this.imageNames[2] : null,
    "imgpalletorcaseloadimg": this.imageNames[3] ? this.imageNames[3] : null,
    "imgunitandintermediatelevel": this.imageNames[4] ? this.imageNames[4] : null,

    //part grid
    "part": this.payloadArray,

    //check
    "contlength": this.form4.controls['row1col1']?.value || null,
    "contwidth": this.form4.controls['row1col2']?.value || null,
    "contheight": this.form4.controls['row1col3']?.value || null,
    "contretlength": this.form4.controls['row2col1']?.value || null,
    "contretwidth": this.form4.controls['row2col2']?.value || null,
    "contretheight": this.form4.controls['row2col3']?.value || null,
    "unitpkggrosswt": this.form4.controls['row1col5']?.value || null,
    "palletloadgrosswt": this.form4.controls['row2col5']?.value || null,
    "piecesperunit": this.form4.controls['row0col7']?.value || null,
    "totalintpermasterpack": this.form4.controls['row1col7']?.value || null,
    "totalunitspermasterpack": this.form4.controls['row2col7']?.value || null,
    //UNIT PKG
    //row0    
    "unitpkgtype": this.form1.controls['row0col1']?.value || null,
    "unitmaterialdescription": this.form1.controls['row0col2']?.value || null,
    "unitweight": this.form1.controls['row0col3']?.value || null,
    "unitlength": this.form1.controls['row0col4']?.value || null,
    "unitwidth": this.form1.controls['row0col5']?.value || null,
    "unitheight": this.form1.controls['row0col6']?.value || null,    
    "unitunitcost": this.form1.controls['row0col7']?.value || null,
    "unitlabortime": this.form1.controls['row0col8']?.value || null,
    "unitqty": this.form1.controls['row0col9']?.value || null,
    "unitcomment": this.form1.controls['row0col10']?.value || null,

    //row1    
    "unitdun1type": this.form1.controls['row1col1']?.value || null,
    "unitdun1description": this.form1.controls['row1col2']?.value || null,
    "unitdun1weight": this.form1.controls['row1col3']?.value || null,
    "unitdun1length": this.form1.controls['row1col4']?.value || null,
    "unitdun1height": this.form1.controls['row1col6']?.value || null,
    "unitdun1width": this.form1.controls['row1col5']?.value || null,
    "unitdun1unitcost": this.form1.controls['row1col7']?.value || null,
    "unitdun1labortime": this.form1.controls['row1col8']?.value || null,
    "unitdun1qty": this.form1.controls['row1col9']?.value || null,
    "unitdun1comment": this.form1.controls['row1col10']?.value || null,

    //row2    
    "unitdun2type": this.form1.controls['row2col1']?.value || null,
    "unitdun2description": this.form1.controls['row2col2']?.value || null,
    "unitdun2weight": this.form1.controls['row2col3']?.value || null,
    "unitdun2length": this.form1.controls['row2col4']?.value || null,
    "unitdun2height": this.form1.controls['row2col6']?.value || null,
    "unitdun2width": this.form1.controls['row2col5']?.value || null,
    "unitdun2unitcost": this.form1.controls['row2col7']?.value || null,
    "unitdun2labortime": this.form1.controls['row2col8']?.value || null,
    "unitdun2qty": this.form1.controls['row2col9']?.value || null,
    "unitdun2comment": this.form1.controls['row2col10']?.value || null,

    //row3
    "unitmisctype": this.form1.controls['row3col1']?.value || null,
    "unitmiscdescription": this.form1.controls['row3col2']?.value || null,
    "unitmiscweight": this.form1.controls['row3col3']?.value || null,
    "unitmisclength": this.form1.controls['row3col4']?.value || null,
    "unitmischeight": this.form1.controls['row3col6']?.value || null,
    "unitmiscwidth": this.form1.controls['row3col5']?.value || null,
    "unitmiscunitcost": this.form1.controls['row3col7']?.value || null,
    "unitmisclabortime": this.form1.controls['row3col8']?.value || null,
    "unitmiscqty": this.form1.controls['row3col9']?.value || null,
    "unitmisccomment": this.form1.controls['row3col10']?.value || null,

    //row4
    "unitlabelunitcost": this.form1.controls['row4col7']?.value || null,
    "unitlabellabortime": this.form1.controls['row4col8']?.value || null,
    "unitlabelqty": this.form1.controls['row4col9']?.value || null,
    "unitlabelcomment": this.form1.controls['row4col10']?.value || null,

    //row5
    "unitheavyunitcost": this.form1.controls['row5col7']?.value || null,
    "unitheavylabortime": this.form1.controls['row5col8']?.value || null,
    "unitheavyqty": this.form1.controls['row5col9']?.value || null,
    "unitheavycomment": this.form1.controls['row5col10']?.value || null,
    //row6
    "unithazmatunitcost":  this.form1.controls['row6col7']?.value || null,
    "unithazmatlabortime":this.form1.controls['row6col8']?.value || null,
    "unithazmatqty":this.form1.controls['row6col9']?.value || null,
    "unithazmatcomment": this.form1.controls['row6col10']?.value || null,
    //row7
    "unitinstallunitcost":  this.form1.controls['row7col7']?.value || null,
    "unitinstalllabortime":this.form1.controls['row7col8']?.value || null,
    "unitinstallqty":this.form1.controls['row7col9']?.value || null,
    "unitinstallcomment": this.form1.controls['row7col10']?.value || null,
    //row8
    "unitfloormatunitcost":  this.form1.controls['row8col7']?.value || null,
    "unitfloormatlabortime":this.form1.controls['row8col8']?.value || null,
    "unitfloormatqty":this.form1.controls['row8col9']?.value || null,
    "unitfloormatcomment": this.form1.controls['row8col10']?.value || null,

    //INTERMEDIATE PKG
    //row0
    "interpkgtype": this.form2.controls['row0col1']?.value || null,
    "intermaterialdescription": this.form2.controls['row0col2']?.value || null,
    "interweight": this.form2.controls['row0col3']?.value || null,
    "interlength": this.form2.controls['row0col4']?.value || null,
    "interheight": this.form2.controls['row0col6']?.value || null,
    "interwidth": this.form2.controls['row0col5']?.value || null,
    "interunitcost": this.form2.controls['row0col7']?.value || null,
    "interlabortime": this.form2.controls['row0col8']?.value || null,
    "interqty": this.form2.controls['row0col9']?.value || null,
    "intercomment": this.form2.controls['row0col10']?.value || null,

    //row1
    "interdun1type": this.form2.controls['row1col1']?.value || null,
    "interdun1description": this.form2.controls['row1col2']?.value || null,
    "interdun1weight": this.form2.controls['row1col3']?.value || null,
    "interdun1length": this.form2.controls['row1col4']?.value || null,
    "interdun1height": this.form2.controls['row1col6']?.value || null,
    "interdun1width": this.form2.controls['row1col5']?.value || null,
    "interdun1unitcost": this.form2.controls['row1col7']?.value || null,
    "interdun1labortime": this.form2.controls['row1col8']?.value || null,
    "interdun1qty": this.form2.controls['row1col9']?.value || null,
    "interdun1comment": this.form2.controls['row1col10']?.value || null,

    //row2
    "interdun2type": this.form2.controls['row2col1']?.value || null,
    "interdun2description": this.form2.controls['row2col2']?.value || null,
    "interdun2weight": this.form2.controls['row2col3']?.value || null,
    "interdun2length": this.form2.controls['row2col4']?.value || null,
    "interdun2height": this.form2.controls['row2col6']?.value || null,
    "interdun2width": this.form2.controls['row2col5']?.value || null,
    "interdun2unitcost": this.form2.controls['row2col7']?.value || null,
    "interdun2labortime": this.form2.controls['row2col8']?.value || null,
    "interdun2qty": this.form2.controls['row2col9']?.value || null,
    "interdun2comment": this.form2.controls['row2col10']?.value || null,

    //row3
    "intermisctype": this.form2.controls['row3col1']?.value || null,
    "intermiscdescription": this.form2.controls['row3col2']?.value || null,
    "intermiscweight": this.form2.controls['row3col3']?.value || null,
    "intermisclength": this.form2.controls['row3col4']?.value || null,
    "intermischeight": this.form2.controls['row3col6']?.value || null,
    "intermiscwidth": this.form2.controls['row3col5']?.value || null,
    "intermiscunitcost": this.form2.controls['row3col7']?.value || null,
    "intermisclabortime": this.form2.controls['row3col8']?.value || null,
    "intermiscqty": this.form2.controls['row3col9']?.value || null,
    "intermisccomment": this.form2.controls['row3col10']?.value || null,

    //row4
    "interlabelunitcost": this.form2.controls['row4col7']?.value || null,
    "interlabellabortime": this.form2.controls['row4col8']?.value || null,
    "interlabelqty": this.form2.controls['row4col9']?.value || null,
    "interlabelcomment": this.form2.controls['row4col10']?.value || null,

    //PALLET/CASE LOAD AS SHIPPED
    //row0
    "returnpkgtype": this.form3.controls['row0col1']?.value || null,
    "returnmaterialdescription": this.form3.controls['row0col2']?.value || null,
    "returnweight": this.form3.controls['row0col3']?.value || null,
    "returnlength": this.form3.controls['row0col4']?.value || null,
    "returnheight": this.form3.controls['row0col6']?.value || null,
    "returnwidth": this.form3.controls['row0col5']?.value || null,
    "returnunitcost": this.form3.controls['row0col7']?.value || null,
    "returnlabortime": this.form3.controls['row0col8']?.value || null,
    "returnqty": this.form3.controls['row0col9']?.value || null,
    "returncomment": this.form3.controls['row0col10']?.value || null,

    //row1
    "expendpkgtype": this.form3.controls['row1col1']?.value || null,
    "expendmaterialdescription": this.form3.controls['row1col2']?.value || null,
    "expendweight": this.form3.controls['row1col3']?.value || null,
    "expendlength": this.form3.controls['row1col4']?.value || null,
    "expendheight": this.form3.controls['row1col6']?.value || null,
    "expendwidth": this.form3.controls['row1col5']?.value || null,
    "expendunitcost": this.form3.controls['row1col7']?.value || null,
    "expendlabortime": this.form3.controls['row1col8']?.value || null,
    "expendqty": this.form3.controls['row1col9']?.value || null,
    "expendcomment": this.form3.controls['row1col10']?.value || null,

    //row2
    "woodpkgtype": this.form3.controls['row2col1']?.value || null,
    "woodmaterialdescription": this.form3.controls['row2col2']?.value || null,
    "woodweight": this.form3.controls['row2col3']?.value || null,
    "woodlength": this.form3.controls['row2col4']?.value || null,
    "woodheight": this.form3.controls['row2col6']?.value || null,
    "woodwidth": this.form3.controls['row2col5']?.value || null,
    "woodunitcost": this.form3.controls['row2col7']?.value || null,
    "woodlabortime": this.form3.controls['row2col8']?.value || null,
    "woodqty": this.form3.controls['row2col9']?.value || null,
    "woodcomment": this.form3.controls['row2col10']?.value || null,

    //row3
    "material1pkgtype": this.form3.controls['row3col1']?.value || null,
    "material1materialdescription": this.form3.controls['row3col2']?.value || null,
    "material1weight": this.form3.controls['row3col3']?.value || null,
    "material1length": this.form3.controls['row3col4']?.value || null,
    "material1height": this.form3.controls['row3col6']?.value || null,
    "material1width": this.form3.controls['row3col5']?.value || null,
    "material1unitcost": this.form3.controls['row3col7']?.value || null,
    "material1labortime": this.form3.controls['row3col8']?.value || null,
    "material1qty": this.form3.controls['row3col9']?.value || null,
    "material1comment": this.form3.controls['row3col10']?.value || null,

    //row4
    "material2pkgtype": this.form3.controls['row4col1']?.value || null,
    "material2materialdescription": this.form3.controls['row4col2']?.value || null,
    "material2weight": this.form3.controls['row4col3']?.value || null,
    "material2length": this.form3.controls['row4col4']?.value || null,
    "material2height": this.form3.controls['row4col6']?.value || null,
    "material2width": this.form3.controls['row4col5']?.value || null,
    "material2unitcost": this.form3.controls['row4col7']?.value || null,
    "material2labortime": this.form3.controls['row4col8']?.value || null,
    "material2qty": this.form3.controls['row4col9']?.value || null,
    "material2comment": this.form3.controls['row4col10']?.value || null,

    //row5
    "material3pkgtype": this.form3.controls['row5col1']?.value || null,
    "material3materialdescription": this.form3.controls['row5col2']?.value || null,
    "material3weight": this.form3.controls['row5col3']?.value || null,
    "material3length": this.form3.controls['row5col4']?.value || null,
    "material3height": this.form3.controls['row5col6']?.value || null,
    "material3width": this.form3.controls['row5col5']?.value || null,
    "material3unitcost": this.form3.controls['row5col7']?.value || null,
    "material3labortime": this.form3.controls['row5col8']?.value || null,
    "material3qty": this.form3.controls['row5col9']?.value || null,
    "material3comment": this.form3.controls['row5col10']?.value || null,

    //row6
    "casemisctype": this.form3.controls['row6col1']?.value || null,
    "casemiscdescription": this.form3.controls['row6col2']?.value || null,
    "casemiscweight": this.form3.controls['row6col3']?.value || null,
    "casemisclength": this.form3.controls['row6col4']?.value || null,
    "casemischeight": this.form3.controls['row6col6']?.value || null,
    "casemiscwidth": this.form3.controls['row6col5']?.value || null,
    "casemiscunitcost": this.form3.controls['row6col7']?.value || null,
    "casemisclabortime": this.form3.controls['row6col8']?.value || null,
    "casemiscqty": this.form3.controls['row6col9']?.value || null,
    "casemisccomment": this.form3.controls['row6col10']?.value || null,

    //row7
    "caselabelunitcost": this.form3.controls['row7col7']?.value || null,
    "caselabellabortime": this.form3.controls['row7col8']?.value || null,
    "caselabelqty": this.form3.controls['row7col9']?.value || null,
    "caselabelcomment": this.form3.controls['row7col10']?.value || null,

    //row8
    "caseheavyunitcost": this.form3.controls['row8col7']?.value || null,
    "caseheavylabortime": this.form3.controls['row8col8']?.value || null,
    "caseheavyqty": this.form3.controls['row8col9']?.value || null,
    "caseheavycomment": this.form3.controls['row8col10']?.value || null,

    "updateMode": this.url.includes('part-list') ? 99 : (this.url.includes('update-ppf-reason') ? 99: null),
    "url_supplierpsd" :this.datepipe.transform(updatePPFReasons?.pkgstartdate, 'yyyy/MM/dd')  || null,
  }
  if (this.routeParams.external === "1") {
    body.ViewMode = this.routeParams.viewMode;
    body.selPartNumber = this.routeParams.partNumber;
    body.guid = this.userInfo?.info[0]?.TOYOTAGUID || null;
    body.supplierapprove = this.userInfo?.info[0]?.FULLNAME || null;
    body.submittername = this.userInfo?.info[0]?.FULLNAME || null;
    body.submitteremail = this.userInfo?.info[0]?.EMAIL || null;
    body.submitterphone = this.userInfo?.info[0]?.PHONE || null;
    body.url_selWhat = updatePPFReasons?.reasonwhat || null;
    body.url_selwhy = updatePPFReasons?.reasonwhy || null;
    body.url_txtwhat = updatePPFReasons?.reasonwhatcomment || null;
    body.url_txtwhy = updatePPFReasons?.reasonwhycomment || null;
    body.saveFrom = !!((updatePPFReasons?.reasonwhat || updatePPFReasons?.reasonwhy))
  } else {
    body.submittername = this.form0.controls['packagingContact']?.value || null;
    body.submitteremail = this.form0.controls['email']?.value || null;
    body.submitterphone = this.form0.controls['phoneNumber']?.value || null;
    body.userSpecialistCode = this.userInfo?.info?.specialistcode || null;
    body.url_selWhat = updatePPFReasons?.what || null;
    body.url_selwhy = updatePPFReasons?.why || null;
    body.url_txtwhat = updatePPFReasons?.whatDesc || null;
    body.url_txtwhy = updatePPFReasons?.whyDesc || null;
  }
  return body;
}

//save 
  savePPF() {
    this.close();
    this.url = this.breadcrumb.breadcrumbs.length > 0 && this.breadcrumb.breadcrumbs.at(-2)?.url;
    this.isSave = true;
    this.intermediateTableValidation();
    this.keyUnitPkg();
    this.partLengthValidate();
    this.keyMasterpackValidation();
    this.imageValidation();
    this.unitrow7Error();
    this.unitrow9Error();
    this.palletCasecol9Validation();
    this.woodPalletHeight();
    this.isSave = false;
    const hasTrueValue = this.partLengthValidator.some(val => val === true);
    if (this.form0.controls['venPickUpLoc']?.value && this.form0.controls['hazmat']?.value && this.form0.controls['requestType']?.value
      && this.form0.controls['partType']?.value && !this.inter && !this.masterRowValidations && !this.unitPackageData && !hasTrueValue && !this.unitPackageMat
      && !this.keyUnitPkgInd && !this.keyMasterInd && this.imageValid && !this.woodPalletHeightValidation) {
      this.spinner.start();
      this.addPartOnload();
      this.getValidatePart();
    }
    else {
      this.markFormGroupTouched(this.form0);
      const requiredFields = [
        { controlName: 'venPickUpLoc', message: 'Please enter Vendor Pick up Location' },
        { controlName: 'hazmat', message: 'Please enter Haz-Mat or Dangerous Goods(DG)' },
        { controlName: 'requestType', message: 'Please enter Request Type' },
        { controlName: 'partType', message: 'Please enter Part Type' }
      ];
      for (const field of requiredFields) {
        const controlValue = this.form0.controls[field.controlName]?.value;
        if (!controlValue) {
          this.errorList.push(field.message);
        }
      }
    }
  }

  getValidatePart() {
    const body = this.getValidatePartBody();      
    this.internalPpfService.ServicePartsValidatePart(body).subscribe({
      next: (_res: any) => {
        if (_res.error === "false") {
          this.finalSave();
        }
        else {
          this.confirm(_res, 'VALIDATE PART');
        }
        this.spinner.stop();
      },
      error: (_err: any) => {
        this.spinner.stop();
      }
    })
  }

  confirm(data: any, action: any) {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      data: {
        action: action,
        data: data
      }
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (!result?.data) {
        this.finalSave();
      }
    })
  }

  finalSave() {
    const body = this.getSavePPFBody();      
    if (this.routeParams.external === "1") {
      this.internalPpfService.ExtServicePartsPPFSave(body).subscribe({
        next: (_res: any) => {
          if (_res.msg === "saved succesfully") {
            this.navigatePrevious();
          }
          else {
            this.errorList.push(_res.message)
          }
          this.spinner.stop();
        },
        error: (_err: any) => {
          this.spinner.stop();
        }
      })
    } else {
      this.internalPpfService.ServicePartsPPFSave(body).subscribe({
        next: (_res: any) => {
          if (_res.msg === "saved succesfully") {
            this.navigatePrevious();
          }
          else {
            this.errorList.push(_res.msg)
          }
          this.spinner.stop();
        },
        error: (_err: any) => {
          this.spinner.stop();
        }
      })
    }
  }

//NaN validation
numValidatorForm(event: KeyboardEvent, keyInd: any, rowStart?: any, rowEnd?: any, colStart?: any, colEnd?: any) {
  const target = event.target as HTMLInputElement;
  const enteredKey = event.key;
  const position = this.getPositionInTable(target);
  if ((position.rowIndex >= rowStart && position.rowIndex <= rowEnd && position.colIndex >= colStart && position.colIndex <= colEnd) || keyInd) {
    // Allow Backspace, Tab, Arrow keys, Copy (Ctrl+C), Paste (Ctrl+V), and Select All (Ctrl+A)
    const allowedKeys = ['Backspace', 'Tab', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'Control', 'Meta', 'Shift', 'a'];    
    // Allow Ctrl+A, Ctrl+C, and Ctrl+V
    if (event.ctrlKey && (enteredKey === 'a' || enteredKey === 'c' || enteredKey === 'v')) return;
    // Validate numeric input and dots
    if (isNaN(Number(enteredKey)) && (enteredKey !== '.' && !allowedKeys.includes(enteredKey))) {
      event.preventDefault();
      const numericValue = parseFloat(target.value);
      target.value = isNaN(numericValue) ? '' : String(numericValue);
      return;
    }
  }
}

getPositionInTable(inputElement: HTMLInputElement): { rowIndex: number, colIndex: number } {
  let rowIndex = -1;
  let colIndex = -1;
  const rowElement = inputElement.closest('tr');
  if (rowElement) {
    rowIndex = Array.from(rowElement.parentElement?.children || []).indexOf(rowElement);
  }
  const cellElement = inputElement.closest('td');
  if (cellElement) {
    colIndex = Array.from(cellElement.parentElement?.children || []).indexOf(cellElement);
  }
  return { rowIndex, colIndex };
}

onPartTypeChange() {
  const reqType = this.form0.controls['requestType']?.value
  this.isPkgKizen = reqType === this.PACKAGING_KAIZEN;
}
}
