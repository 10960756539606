import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { MatDialog } from '@angular/material/dialog'
import { AddPkgFabricatorComponent } from 'src/app/internal-vpacs/internal-vpacs-dialogs/add-pkg-fabricator/add-pkg-fabricator.component'
import { PkgContactBtnCellRender } from 'src/app/internal-vpacs/internal-Vpacs-gridrender/pkg-contact-btn-cell-render'
import { PkgFabMainService } from 'src/app/internal-vpacs/services-internal/master-maintenance/pkgfabmain.service'
import { NgxUiLoaderService } from 'ngx-ui-loader'
import { MatSnackBar } from '@angular/material/snack-bar'
import { FabBtnCellRender } from 'src/app/internal-vpacs/internal-Vpacs-gridrender/fab-btn-cell-render'
import { DeleteFabComponent } from 'src/app/internal-vpacs/internal-vpacs-dialogs/delete-fab/delete-fab.component'
import { ExportStateService } from 'src/app/services/export-state.service'
import { ConstantsService } from 'src/app/services/constant.service'
@Component({
  selector: 'app-pkg-fab-maintenance',
  templateUrl: './pkg-fab-maintenance.component.html',
  styleUrls: ['./pkg-fab-maintenance.component.scss'],
})
export class PkgFabMaintenanceComponent implements OnInit {
  nontrade = true
  cPage = 1;
  tPage = 0;
  rpPage: any;
  totalRecords = 0;
  gridApi: any;
  PackageSupplierCode:any;
  alphaCode:any;
  Name:any
  columnDefs: any[] = [];
  currentPageNo = 1;
  currentPageBoolean = false;
  totalPage = 0;
  displayRowData: any[] = [];
  currentPageForTotalPage!: number;
  isLastPageFound!: boolean;
  rowCount = 0;
  defaultColDef: {};
  gridOptions: any;
  icons: {};
  rowHeight = 50;
  headerHeight = 50;
  paginationPageSize = 50;
  isAccessible = false;
  plant: any;
  plants:{value:string,selected: boolean, disable: boolean}[] = [];
  comm:any;
  digits:any
  editAccess: any;
  viewAccess: any;
  errorList: string[] = [];
  successMessage: string[] = [];
  apiFromSearch=false;
  isAll=false;
  countries = [];

  public domLayout: any = '';
  public overlayLoadingTemplate = `<div class="spinner-border" role="status"></div>`;
  public overlayNoRowsTemplate = `
  <div>
    <img src="../../../assets/images/noun_Empty_1735753.svg" alt="NoData" />
    <p class="fw-bold fs-5">No data available</p>
    <span>Please select the above filters to get the results.</span>
  </div>
  `
  public rowSelection: 'single' | 'multiple' = 'multiple';
  pageNumber = 0;
  lastRowIndex = 0;
  rowClassRules: any;
  radioVal = 1;
  selectedNamesString = '';
  commodityList: any[] = [];
  checkAllNonTrades = false;
  iscommadity = "1";
  deleteAccess: any;
  filterdata: any;
  originalPage: any;
  constructor(
    public pkgFabMainService: PkgFabMainService,
    private readonly spinnerService: NgxUiLoaderService,
    public router: Router,
    public route: ActivatedRoute,
    public dialog: MatDialog,
    public message: MatSnackBar,
    public state: ExportStateService,
    public constants: ConstantsService
  ) {
    const nullToDash = this.constants.nullToDashFormatter
    this.defaultColDef = {
      suppressMovable: true,
      wrapText: true,
      suppressPaginationPanel: true,
      autoHeight: true,
      valueFormatter: (params: any) => {
        if (!params.node.group) {
          return nullToDash(params)
        } else {
          return null;
        }
      },
      filterParams: { newRowsAction: 'keep' }
    }
    this.icons = {
      sortUnSort: '<img src="assets/images/Icon awesome-sort.svg">',
    }
    this.rowClassRules = {
      'row-even': 'node.rowIndex % 2 === 0',
      'row-odd': 'node.rowIndex % 2 !== 0',
    }
  }

  ngOnInit(): void {
    this.rpPage = 50;
    this.state.userInfoState.subscribe({
      next: (res: any) => {
        if (res) {
          this.editAccess = res?.role?.p_usersave;
          this.viewAccess = res?.role?.p_userview;
          this.deleteAccess = res?.role.p_userdelete;
          if (this.viewAccess) {
            this.spinnerService.start()
            this.filterdata = this.pkgFabMainService.getfabFilters();
            if(this.filterdata){
              this.PackageSupplierCode = this.filterdata?.PackageSupplierCode;
              this.Name = this.filterdata?.Name;
              this.alphaCode = this.filterdata?.AlphaCode;
              this.digits = this.filterdata?.SupplierCode;
              this.selectedNamesString = this.filterdata?.Commodities;
              if(this.selectedNamesString?.length > 0){
                this.nontrade = false;
                this.plants = this.pkgFabMainService.getfabCommodities();
                this.plants.forEach((item) => {
                  if(this.selectedNamesString.includes(item.value)){
                    item.selected = true;
                    item.disable = false;
                  }
                });
              }
              else{
                this.commodityCall()
              }
            }
            else{
              this.commodityCall()
            }
            this.apiCall()
            this.getColumnDefs()
          } else {
            this.spinnerService.stop()
          }
        }
      },
      error: (_err: any) => {
        this.spinnerService.stop();
        this.editAccess = false;
        this.viewAccess = false;
        this.deleteAccess = false;
      }
    })
  }

  getColumnDefs() {
    this.columnDefs = [
      {
        headerName: 'CODE',
        field: 'pkgsupcode',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
        unSortIcon: true,
        minWidth: 90
      },
      {
        headerName: 'Alpha Code',
        field: 'alphacode',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
        unSortIcon: true,
        minWidth: 90,
      },
      {
        headerName: 'Name',
        field: 'name',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
        unSortIcon: true,
        minWidth: 140,
      },
      {
        headerName: 'Commodity',
        field: 'commodity',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
        unSortIcon: true,
        minWidth: 320,
      },
      {
        headerName: '5 digit	',
        field: 'supcode',
        floatingFilterComponentParams: { suppressFilterButton: true },
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
        unSortIcon: true,
        minWidth: 90,
      },
      {
        headerName: 'COUNTRY',
        field: 'countryname',
        minWidth: 110,
      },
      {
        headerName: 'CONTACT',
        field: 'contact',
        sortable: false,
        unSortIcon: false,
        minWidth: 110,
        cellRendererFramework: PkgContactBtnCellRender,
        cellRendererParams: {
          clicked: (field?: any) => {
            this.ContactReg(field)
          },
        },
      },
      {
        headerName: 'UPDATE',
        field: 'UPDATE',
        sortable: false,
        unSortIcon: false,
        minWidth: 110,
        cellRendererFramework: FabBtnCellRender,
        cellRendererParams: {
          clicked: (field: any) => {
            this.dialogOpen(field)
          },
        },
      },
      {
        headerName: 'DELETE',
        field: 'delete',
        sortable: false,
        minWidth: 100,
        unSortIcon: false,

        cellRendererFramework: FabBtnCellRender,

        cellRendererParams: {
          clicked: (field?: any) => {
            this.delete(field)
          },
        },
      },
    ]
  }
  formatNameWithCommas(param: any) {
    const inputString = param;
    const stringWithoutCommas = inputString.replace(/,/g, '');
    const characters = stringWithoutCommas.split(''); 
    return characters.join(',');
  }
  
  sizeToFit() {
    this.gridApi?.sizeColumnsToFit()
  }

  commodityCall() {
    this.pkgFabMainService.commadityBtn().subscribe({
      next: (res: any) => {
       this.plants=res.map((item: { value: any; }) => ({ value: item.value, selected: false, disable: true }));
       this.pkgFabMainService.setfabCommodities(this.plants)
      }})
  }

  getParams()
  {
    return {
      PackageSupplierCode:this.PackageSupplierCode,
      Name:this.Name,
      AlphaCode:this.alphaCode,
      SupplierCode:this.digits,
      Commodities: this.nontrade ? '' : this.selectedNamesString,
      isCommodities: (this.nontrade || this.selectedNamesString.length> 0) ? "1" : "0"
    }
  }

  apiCall(){
    const param=this.getParams();
    this.pkgFabMainService.setfabFilters(param);
    this.pkgFabMainService.onloadPkgFab(param).subscribe({
      next: (res: any) => {
          this.countries = Array.isArray(res.countriesList.result) && res.countriesList.result
          const result = Array.isArray(res.data.result) &&res.data.result.map((item: any)=> ({
            ...item,
            modifyAccess: this.editAccess,
            deleteAccess: this.deleteAccess ,
            commodity:this.formatNameWithCommas(item.commodity)
          })) 
          this.displayRowData = result;
          this.rowCount = this.displayRowData.length;
          if(!this.pkgFabMainService.getNavFlag()){
            this.totalRecords = res.data.result.length;
            this.tPage = Math.ceil(this.totalRecords / this.rpPage);
          }
          this.spinnerService.stop();
      },
    })
  }

  delete(field: any) {
    this.close();
    const dialogRef = this.dialog.open(DeleteFabComponent, {
      data: { field, mode: 'Fab' },
    })
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result.data === 'data') {
        this.spinnerService.start();
        const params = {
          PackageSupplierCode: field.pkgsupcode,
        }
        this.pkgFabMainService.deleteExchangeRate(params).subscribe({
          next: (_res: any) => {
            this.successMessage=['File Deleted successfully']
            this.apiCall()
          },
          error: () => {
            this.errorList=['Error deleting the file']          },
        })
      }
    })
  }
 
  onFilterChanged(_params: any) {
    if(!this.pkgFabMainService.getNavFlag()){
      this.cPage = 1;
      this.totalRecords = this.gridApi.getDisplayedRowCount();
      this.tPage = this.isAll ? 1 : Math.ceil(this.totalRecords / this.rpPage);
      this.rpPage = this.isAll ? 'ALL' : this.rpPage
      const obj = {
        cPage: this.cPage,
        tPgae: this.tPage,
        rpPage: this.rpPage,
        totalRecords: this.totalRecords
      }
      this.pkgFabMainService.setPaginationValues(obj);
      this.pkgFabMainService.setNavFlag(false);
    }
      
    const allFilterModels = this.gridApi?.getFilterModel();
    this.pkgFabMainService.setfilter(allFilterModels);
    const isFilterCleared = Object.keys(allFilterModels).length === 0;
    const pagination = this.pkgFabMainService.getPaginationValues();
    
    if (!isFilterCleared && pagination !== undefined) {
      this.cPage = pagination?.cPage;
      this.totalRecords = pagination?.totalRecords;
      this.tPage = pagination?.tPgae;
      this.rpPage = pagination?.rpPage === this.displayRowData?.length ? 'ALL' : pagination?.rpPage;
    }
    else{
      if (this.originalPage === undefined) {
        this.originalPage = this.cPage;
      }
  
      if (isFilterCleared) {
        this.cPage = this.originalPage;
        this.originalPage = undefined;
        this.totalRecords = this.displayRowData?.length;
        this.tPage = Math.ceil(this.totalRecords / this.rpPage);
        this.pkgFabMainService.clearPaginationValues();
        this.pkgFabMainService.setNavFlag(false);
      }
    }
  }
  onGridReady(params: any) {
    this.gridApi = params.api
    this.gridApi.sizeColumnsToFit()
    if (this.pkgFabMainService.getfilter()) {
      const retrivedValue = this.pkgFabMainService.getfilter();
      this.gridApi?.setFilterModel(retrivedValue);
      this.gridApi?.onFilterChanged();
      this.gridApi?.refreshCells();
    }
  }

  //pagination changes
  async changePaginationValues(values: any) {
    this.isAll = values?.rpPage === 'ALL' ? true : false;
    this.cPage = values?.cPage;
    this.tPage = values?.tPage;
    this.rpPage = !this.isAll ? values?.rpPage : this.totalRecords;
    this.spinnerService.start();
    if (this.isAll) {
      this.displayRowData = []
      for (let i = 0; i < Math.ceil(this.totalRecords / 5000); i++) {
        this.cPage = i + 1
          this.tPage = 1
          this.rpPage = 5000
        await this.onLoadAll()
      }
      this.rowCount = this.totalRecords;
      this.tPage = 1;
      this.cPage = 1;
      this.rpPage = this.totalRecords;
      this.gridApi?.paginationSetPageSize(this.totalRecords);
    }
    else {
      this.gridApi?.paginationGoToPage(this.cPage - 1);
      this.gridApi?.paginationSetPageSize(this.rpPage);
      this.onSearch();
    }
  }

  async onLoadAll() {
    this.spinnerService.start();
    const param=this.getParams();
    this.pkgFabMainService.onloadPkgFab(param).subscribe({
      next: (pakageFab: any) => {
        const gridData: any[] = []
          gridData.push(...pakageFab.result)
          this.displayRowData.push(...gridData)
          this.gridApi.setRowData(this.displayRowData)
          if (this.apiFromSearch) {
            this.totalRecords = this.gridApi.getDisplayedRowCount();
            this.apiFromSearch = false;
          }
      },
      error: () => {
        this.spinnerService.stop();
      },
      complete: () => {
        this.spinnerService.stop();
      }
    })
    if (this.apiFromSearch) {
      this.totalRecords = this.gridApi.getDisplayedRowCount();
      this.apiFromSearch = false;
    }
  }

  onSearch() {
    this.spinnerService.start();
    if (this.isAll) {
      this.apiFromSearch = true;
      const values = {
        cPage: 1,
        tPage: 1,
        rpPage: 'ALL',
      }
      this.changePaginationValues(values);
    }
    this.apiCall()
  }

  addPackgeFab() {
    this.close()
    const jsonString = JSON.stringify(this.plants);
    const myObject = JSON.parse(jsonString);
    const dialogRef = this.dialog.open(AddPkgFabricatorComponent, {
      data: {
        mode: 'ADD',
        tableData: this.displayRowData,
        commodity: myObject,
        countries : this.countries
      },
    })
    dialogRef.afterClosed().subscribe((result: any) => {
      if(result==="INSERT"){
        this.apiCall()
        this.successMessage=['Data Updated Successfully']
      }
    })
  }
  dialogOpen(field: any) {
    this.close();
    const jsonString = JSON.stringify(this.plants);
    const myObject = JSON.parse(jsonString);
    const dialogRef = this.dialog.open(AddPkgFabricatorComponent, {
      data: { mode: 'UPDATE', field, commodity: myObject,countires : this.countries },
    })
    dialogRef.afterClosed().subscribe((result: any) => {
      if(result?.status){
        this.apiCall()
        this.successMessage=[result.message]
      }    })
  }
  ContactReg(field: any) {
    this.close();
    const pkgsupcode = field.pkgsupcode
    const alphacode = field.alphacode
    const name = field.name
    const supcode = field.supcode
    if(pkgsupcode && alphacode && name && supcode){
      this.pkgFabMainService.setNavFlag(true);
    this.router.navigate([
      '/package-fabricator/package-fabricator-contact',
      pkgsupcode,
      alphacode,
      name,
      supcode,
    ])
  }else{
    this.errorList=['No Data Found']
  }
  }
  changeTradesByCategory(_event: any) {
    const selectedNamesArray = this.plants.filter(item => item.selected === true).map(item => item.value);
    this.selectedNamesString = selectedNamesArray.join(',');
  }


  allNonTrades(_event: any) {
    if(!this.nontrade) {
      this.plants.forEach((item) => {
        item.selected = false;
        item.disable = false;
      });
    } else {
      this.plants.forEach((item) => {
        item.selected = false;
        item.disable = true;
      });
      this.selectedNamesString = '';
    }
  }
  close(){
    this.errorList=[];
    this.successMessage=[];
  }
  help(){
    this.router.navigate(['./master-help',5,"Master Maintenance",121])
  }
}
