import { Component} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { PpfService } from 'src/app/services/ppf/ppf.service';
import { SelectFabricatorComponent } from '../dialogs/select-fabricator/select-fabricator.component';
import { SelectPackagePPFComponent } from '../dialogs/select-package-ppf/select-package-ppf.component';
import { GlobalStateService } from 'src/app/services/service/global-state.service';

@Component({
  selector: 'ppf-cell-render',
  template: `
    <div *ngIf="this.readOnly">
      <span *ngIf="this.colId === 'MANUFACTURER'">{{params.data?.MANUFACTURER}}</span>
      <span *ngIf="this.colId === 'PACKAGECODE'">{{params.data?.PKGCODE}}</span>
    </div>
    <div class="d-flex gap-1 col-12 align-items-center" *ngIf="!this.readOnly">
        <input type="text" 
          *ngIf = "this.colId === 'MANUFACTURER'" 
          class="col-8" 
          style="height: 30px" 
          [value]="params.data?.MANUFACTURER"
          [disabled] = "true"
        />
        <div *ngIf = "this.colId === 'MANUFACTURER' && params.rowIndex === 0 " class="d-flex">
          <mat-icon style="height:30px; font-size: 21px"
          class="{{!disabled1 ? 'vpacs-btn-table d-flex justify-content-center align-items-center' : 'vpacs-btn-table vpacs-btn-table-disabled  d-flex justify-content-center align-items-center'}}" 
          (click) = "openDialog()">more_vert</mat-icon>
          <img src="../../../../assets/images/noun_edit_1570019.svg" alt="D" class="{{!disabled1 ? 'vpacs-btn-table' : 'vpacs-btn-table vpacs-btn-table-disabled'}}" (click)="onDelete(this.params)"/>
        </div>
        <div *ngIf = "this.colId === 'MANUFACTURER' && params.rowIndex === 1 " class="d-flex">
          <mat-icon style="height:30px; font-size: 21px"
          class="{{!disabled2 ? 'vpacs-btn-table d-flex justify-content-center align-items-center' : 'vpacs-btn-table vpacs-btn-table-disabled  d-flex justify-content-center align-items-center'}}" 
          (click) = "openDialog()">more_vert</mat-icon>
          <img src="../../../../assets/images/noun_edit_1570019.svg" alt="D" class="{{!disabled2 ? 'vpacs-btn-table' : 'vpacs-btn-table vpacs-btn-table-disabled'}}" (click)="onDelete(this.params)"/>
        </div>
        <div *ngIf = "this.colId === 'MANUFACTURER' && ![1,0].includes(params.rowIndex)" class="d-flex">
          <mat-icon style="height:30px; font-size: 21px"
          class='vpacs-btn-table d-flex justify-content-center align-items-center' 
          (click) = "openDialog()">more_vert</mat-icon>
          <img src="../../../../assets/images/noun_edit_1570019.svg" alt="D" class="vpacs-btn-table" (click)="onDelete(this.params)"/>
        </div>
        <input type="text" 
          *ngIf = "this.colId === 'PACKAGECODE'" 
          class="col-8" 
          style="height: 30px" 
          [value]="params.data?.PKGCODE"
          [disabled] = "true"
        />
        <div *ngIf = "this.colId === 'PACKAGECODE'" class="d-flex">
        <mat-icon style="height:30px; font-size: 21px"
        class="vpacs-btn-table d-flex justify-content-center align-items-center'" 
        (click) = "openDialog()">more_vert</mat-icon>
        <img src="../../../../assets/images/noun_edit_1570019.svg" alt="D" class="vpacs-btn-table" (click)="onDelete(this.params)"/>
      </div>
    </div>
  `,
})

export class PpfCellRenderer implements ICellRendererAngularComp {
  public readOnly = false;
  constructor(private readonly dialog: MatDialog, public router:ActivatedRoute,public ppfService:PpfService,public globalState:GlobalStateService
    ){
    if(this.router.snapshot.params['readOnly'] === 'readOnly'){
      this.readOnly = true;
    }
  }
    refresh(params: ICellRendererParams): boolean {
		throw new Error(`Error while refreshing renderer component. INFO-${params}`);
	}

  protected params: any;
  protected colId = '';
  disabled1 = false;
  disabled2 = false;
  manufacturerDisable = false;
  agInit(params: any): void {
    this.params = params;      
    this.colId = params.column.colId;
    if(this.params.column.colId === 'MANUFACTURER'){
      if(this.nullUndefinedConverter(params.data.ppfData.FABCODE)){
        this.disabled1 = true;
      }
      if(this.nullUndefinedConverter(params.data.ppfData.DUN1FABCODE)){
        this.disabled2 = true;
      }
      // JSP code reference
      // if(![0,2].includes(params.data?.EDITMODE) && this.params.data?.PLANTCODE !== "21TMC"){
      //   this.disabled1 = true;
      //   this.disabled2 = true;
      // }
    }
    if((params.data?.VIEWMODE === 1 && params.data?.EDITMODE === 1)){
      this.readOnly = true;
    }
    else if((params.data?.VIEWMODE === 1 && params.data?.EDITMODE === 2)){
      if(['MANUFACTURER'].includes(this.params.column.colId)){
        this.readOnly = true;
      }
      else {
        this.readOnly = false;
      }
    }
    // JSP code reference
    // else if(![1,3,4].includes(this.params.data?.VIEWMODE) && this.params.column.colId === 'MANUFACTURER'){
    //   this.readOnly = true;
    // }
    else if((params.data?.VIEWMODE === 2 && params.data?.EDITMODE === 0)){
      this.readOnly = true;
    }
    else if((params.data?.VIEWMODE === 1 && params.data?.EDITMODE === 0)){
      this.readOnly = false;
    }
  }
  nullUndefinedConverter(data:any){
    if([null,undefined,'null','undefined'].includes(data)){
      return '';
    }
    return data;
  }

  openDialog(){
    let dialogData:any;
    const rowData:any=[]
    //retaining grid1 data
    this.params.api?.forEachNode((node:any) => rowData.push(node.data));
    this.globalState.setGrid1Data(rowData)
    //end
    if(this.params.column.colId === 'MANUFACTURER'){
      dialogData = this.dialog.open(SelectFabricatorComponent,{ data:{params: this.params,componentName: this.params.data.COMPONENT}, width: '85%'});
      dialogData.afterClosed().subscribe((result:any) =>{     
        if(result){
          this.params.data.MANUFACTURER = result?.data?.PKGSUPCODE
          const array:any = [];
          this.params.api.forEachNode((x:any) => array.push(x.data))
          this.params.api.setRowData(array);
          this.params.change({...result?.data});
        }
      })
    }
    if(this.params.column.colId === 'PACKAGECODE'){
      dialogData = this.dialog.open(SelectPackagePPFComponent,{ data:{componentName: this.params.data.COMPONENT, rowNumber: this.params.rowIndex + 1,supplierCode:this.params.data?.SUPPLIERCODE}, width: '85%'});
      dialogData.afterClosed().subscribe((result:any) =>{
        if(result){          
          const array:any = [];
          const row = this.params.rowIndex;
          this.params.data.PKGCODE = result?.data?.PACKAGECODE;
          this.params.api.forEachNode((x:any) => array.push(x.data))
          this.ppfService.getCost(array[row]?.MANUFACTURER, array[row]?.PKGCODE).subscribe({
            next: (res:any) =>{
              array[row].COST = (res.costEstimate && res.costEstimate!=='null') ? parseFloat(res?.costEstimate) : 0;
              array[row].DESCRIPTION = result.data?.DESCRIPTION;
              array[row].MATERIAL = result.data?.MATERIAL;
              array[row].LEADTIME = result.data?.LEADTIME;
              array[row].RETEXP = result.data?.RETURNABLE;
              array[6].QUANTITY = Number(result.data?.QTYKHOLDER);
              this.params.api.setRowData(array);
              this.params.changed({...result?.data, row: row});
            }
          })         
        }
      })
    }

  }

  onDelete(params:any){
    const colId = params.column.colId;
    const row = params.rowIndex;
    this.params.data[colId] = '';
    if(colId === 'PACKAGECODE'){
      const array:any = [];
      this.params.data.PKGCODE = '';
      this.params.api.forEachNode((x:any) => array.push(x.data))
      array[row].DESCRIPTION = '';
      array[row].MATERIAL = '';
      array[row].LEADTIME = '';
      array[row].RETEXP = '';
      array[row].COST = 0;
      this.params.api.setRowData(array);
      this.params.changed({type : 'delete', OUTERHEIGHT : 0, WEIGHT : 0,row : row});
    }
    if(colId === 'MANUFACTURER'){
      const array:any = []
      this.params.data.MANUFACTURER = '';
      this.params.api.forEachNode((x:any) => array.push(x.data))
      this.params.api.setRowData(array);
      this.params.change('delete');
    }
  }
}




