import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ITooltipParams } from 'ag-grid-community';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DialogDivisionComponent } from 'src/app/internal-vpacs/internal-vpacs-dialogs/master-maintenance/division-master/dialog-division/dialog-division.component';
import { SelectSpecialistComponent } from 'src/app/internal-vpacs/internal-vpacs-dialogs/tracking/select-specialist/select-specialist.component';
import { CommentEditor } from 'src/app/internal-vpacs/internal-Vpacs-gridrender/authorize/commentEditor';
import { DecCellRender } from 'src/app/internal-vpacs/internal-Vpacs-gridrender/authorize/dec-cell-render';
import { DecHeaderRender } from 'src/app/internal-vpacs/internal-Vpacs-gridrender/authorize/dec-header-render';
import { DecisionLinkBtnCellRenderer } from 'src/app/internal-vpacs/internal-Vpacs-gridrender/authorize/decision-link-cell-render';
import { DecListService } from 'src/app/internal-vpacs/services-internal/authorize/dec-list.service';
import { dollarComparator, PurDecdataComparator } from 'src/app/internal-vpacs/services-internal/authorize/pur-dec-list.service';
import { dateComparator } from 'src/app/internal-vpacs/services-internal/tracking/tracking-list/tracking-list.service';
import { ExportStateService } from 'src/app/services/export-state.service';

@Component({
  selector: 'app-decision-list',
  templateUrl: './decision-list.component.html',
  styleUrls: ['./decision-list.component.scss'],
})
export class DecisionListComponent implements OnInit {
  gridApi: any;
  columnDefs: any[] = [];
  displayRowData: any[] = [];
  rowCount = 0;
  defaultColDef: {};
  gridOptions: any;
  icons: {};
  isAccessible = false;
  successMessage: string[] = [];
  radioGroup = ['approve', 'reject', 'reserve'];

  public overlayLoadingTemplate = `<div class="spinner-border" role="status"></div>`;
  public overlayNoRowsTemplate = `
  <div>
    <img src="../../../assets/images/noun_Empty_1735753.svg" alt="NoData" />
    <p class="fw-bold fs-5">No data available</p>
    <span>Please select the above filters to get the results.</span>
  </div>
  `;
  errorList: string[] = [];
  tracking: any = true;
  cPage = 1;
  tPage = 0;
  rpPage: any = 50;
  totalRecords = 0;
  specialist: any = '';
  cost: any = true;
  response: any = [];
  haveAccess: any;
  userInfo: any;
  comment: any;
  data: any;
  headerStyle = 'child-header-color no-border';
  constructor(
    private readonly decListService: DecListService,
    public dialog: MatDialog,
    public router: Router,
    private readonly state: ExportStateService,
    public spinnerService: NgxUiLoaderService
  ) {
    this.defaultColDef = {
      suppressMovable: true,
      wrapText: true,
      suppressPaginationPanel: true,
      autoHeight: true,
      sortable: true,
      unSortIcon: true,
      floatingFilterComponentParams: { suppressFilterButton: true },
      filter: 'agTextColumnFilter',
      floatingFilter: true,
      filterParams: { newRowsAction: 'keep' },
    };
    this.icons = {
      sortUnSort: '<img src="../../../assets/images/Icon awesome-sort.svg">',
    };
  }
  decision: any = '';

  ngOnInit(): void {
    this.spinnerService.start()
    this.state.userInfoState.subscribe({
      next: (res: any) => {
        if(res?.info){
        this.userInfo = res;
        this.haveAccess = res.info?.title !== '0';
        this.haveAccess && this.getcolumndef();
        const data = this.decListService.getDecFilter();
        if (this.haveAccess && data) {
          this.specialist = data.specialist;
          this.tracking = data.tracking === '1' ? 1 : 0;
          this.cost = data.cost === '1' ? 1 : 0;
          this.onSearch();
          return;
        }
        this.haveAccess && this.onLoad();
      }
        !this.haveAccess && this.spinnerService.stop();
      },
      error: (_err: any) => {
        this.haveAccess = false;
        this.errorList=[_err.message];
        this.spinnerService.stop();
      },
    });
  }
  getcolumndef() {
    this.columnDefs = [
      {
        headerName: '',
        field: 'modelname',
        minWidth: 80,
        children: [
          {
            headerName: 'MODEL',
            field: 'modelname',
            headerClass: this.headerStyle,
            minWidth: 80,
            valueGetter: (params: any) => {
              return params?.data?.modelname ? params?.data?.modelname : '-';
            },
          },
        ],
      },
      {
        headerName: '',
        field: 'suppliername',
        minWidth: 100,
        children: [
          {
            headerName: 'SUPPLIER',
            field: 'suppliername',
            headerClass: this.headerStyle,
            minWidth: 100,
          },
        ],
      },
      {
        headerName: '',
        field: 'partnumber',
        minWidth: 100,
        children: [
          {
            headerName: 'PART NUMBER',
            field: 'partnumber',
            cellRendererFramework: DecisionLinkBtnCellRenderer,
            cellStyle: { cursor: 'pointer', color: '#2688DA' },
            headerClass: this.headerStyle,
            minWidth: 140,
          },
        ],
      },
      {
        headerName: '',
        field: 'specialist',
        minWidth: 100,
        children: [
          {
            headerName: 'SPECIALIST',
            field: 'specialist',
            headerClass: this.headerStyle,
            minWidth: 100,
          },
        ],
      },
      {
        headerName: '',
        field: 'authdate',
        minWidth: 80,
        children: [
          {
            headerName: 'DATE',
            field: 'authdate',
            comparator: dateComparator,
            headerClass: this.headerStyle,
            minWidth: 80,
          },
        ],
      },
      {
        headerName: '',
        field: '',
        minWidth: 80,
        children: [
          {
            headerName: 'Decision TYPE',
            field: 'decisiontype',
            headerClass: this.headerStyle,
            minWidth: 150,
            valueFormatter: this.decisionType,
          },
        ],
      },
      {
        headerName: '',
        field: '',
        minWidth: 80,
        children: [
          {
            headerName: 'AUTH#',
            field: 'authnumber',
            cellRendererFramework: DecisionLinkBtnCellRenderer,
            cellStyle: { cursor: 'pointer', color: '#2688DA' },
            headerClass: this.headerStyle,
            minWidth: 150,
          },
        ],
      },
      {
        headerName: '',
        field: '',
        minWidth: 80,
        children: [
          {
            headerName: 'AUTH TYPE',
            field: 'authtype',
            headerClass: this.headerStyle,
            minWidth: 150,
            valueFormatter: this.getAuthType,
          },
        ],
      },
      {
        headerName: '',
        field: '',
        minWidth: 80,
        children: [
          {
            headerName: 'UNIT$',
            field: 'authunit',
            headerClass: this.headerStyle,
            minWidth: 150,
            comparator:dollarComparator
          },
        ],
      },
      {
        headerName: '',
        field: '',
        minWidth: 80,
        children: [
          {
            headerName: 'QTY',
            field: 'authqty',
            headerClass: this.headerStyle,
            minWidth: 150, 
            valueGetter :  this.formatDecimal,
            comparator:PurDecdataComparator
          },
        ],
      },
      {
        headerName: '',
        field: '',
        minWidth: 80,
        children: [
          {
            headerName: 'TOTAL$',
            field: 'authtotal',
            headerClass: this.headerStyle,
            minWidth: 150,
            valueGetter :  this.formatDecimal2,
            comparator:dollarComparator
          },
        ],
      },

      {
        headerName: 'Approve',
        field: 'approve',
        minWidth: 80,
        children: [
          {
            headerName: 'ALL',
            field: 'approve',
            headerClass: this.headerStyle,
            minWidth: 100,
            floatingFilter: false,
            sortable: false,
            cellRendererFramework: DecCellRender,
            headerComponentFramework: DecHeaderRender,
           
          },
        ],
      },
      {
        headerName: 'Reject',
        field: 'reject',
        minWidth: 80,
        children: [
          {
            headerName: 'ALL',
            field: 'reject',
            headerClass: this.headerStyle,
            minWidth: 100,
            cellRendererFramework: DecCellRender,
            headerComponentFramework: DecHeaderRender,
            floatingFilter: false,
            sortable: false,
           
          },
        ],
      },
      {
        headerName: 'Reserve',
        field: 'reserve',
        minWidth: 90,
        children: [
          {
            headerName: 'ALL',
            field: 'reserve',
            headerClass: this.headerStyle,
            minWidth: 100,
            floatingFilter: false,
            sortable: false,
            cellRendererFramework: DecCellRender,
            headerComponentFramework: DecHeaderRender,
           
          },
        ],
      },
      {
        headerName: '',
        field: '',
        minWidth: 80,
        children: [
          {
            headerName: 'COMMENT',
            field: 'comment',

            headerClass: this.headerStyle,
            minWidth: 150,
            suppressKeyboardEvent: (_params: any) => true,
            tooltipValueGetter: (p: ITooltipParams) => p.value,
            cellRendererFramework: CommentEditor,
            cellRendererParams: {
              clicked: (comment: any, id: any) => {
                this.comment = comment;
                this.updateComment(this.comment, id);
              },
            },
          },
        ],
      },
    ];
  }

  formatDecimal(params:any){
    const value = parseFloat(params.data.authqty);
    if(value){
      return value % 1 === 0 ?  value.toFixed(0) : value.toString();
    }
    return 0;
  }

  formatDecimal2(params:any){
    if(params.data.authtotal){
      return parseFloat(params.data.authtotal.substring(1)).toFixed(3)
    }
    return 0;
  }

  updateDecision(data: any) {
    if (data?.reserve === 1) {
      this.decision = 2;
    } else if (data?.reject === 1) {
      this.decision = 1;
    } else if (data?.approve === 1) {
      this.decision = 0;
    } else {
      this.decision = -1;
    }
    this.gridApi.setRowData(this.displayRowData);
  }
  updateComment(data: any, id: any) {
    const authnumber = id;
    const newComment = data;
    const rowToUpdate = this.displayRowData.find(
      (row) => row.authnumber === authnumber
    );
    if (rowToUpdate) {
      rowToUpdate.comment = newComment;
    }
  }

  dateFormatter(params: any) {
    if (params === undefined|| params === "undefined" || params === null) {
      return '';
    } else {
      const date = new Date(params);
      return formatDate(date, 'MM/dd/YYYY', 'en-US', 'UTC'); 
    }
  }
 

  dollarFormatter(params: any) {
    if (params === 'undefined' || params === null) {
      return '';
    } else {
      const stringWithoutSpaces = String(params).replace(/\s/g, '');
      return '$' + stringWithoutSpaces;
    }
  }
  decisionType(params: any) {
    if (params === 'undefined' || params === null) {
      return '';
    } else if (params.data?.decisiontype === 1) {
      return this.getTrackingStatus(params?.data);
    } else {
      return 'Cost';
    }
  }
  getTrackingStatus(params: any) {
    if (params?.step04status === '2' || params?.step04status === '3') {
      return 'Proposal';
    }
    if (params?.step07status === '2' || params?.step07status === '3') {
      return 'Trial';
    }
    if (params?.step09status === '2' || params?.step09status === '3') {
      return 'Final';
    }
    return '';
  }
  getAuthType(params: any): string {
    if (params?.data?.authtype === '0') {
      return 'Dispose';
    } else if (params?.data?.authtype === '1') {
      return 'Maintenance';
    } else if (params?.data?.authtype === '2') {
      return 'Move';
    } else if (
      params?.data?.authtype === '4' ||
      params?.data?.authtype === '5'
    ) {
      return 'Purchase';
    } else if (params?.data?.authtype === '3') {
      return 'Prototypes';
    } else if (params?.data?.authtype === '10') {
      return 'Backup';
    } else {
      return '';
    }
  }
  onLoad() {
    this.spinnerService.start();
    this.close();
    this.displayRowData = [];
    const obj = {
      loggedInSpecialistCode: this.userInfo.info?.specialistcode,
    };
    this.decListService.getDecisionList(obj).subscribe({
      next: (res: any) => {
         if(res?.status) {
          this.loadData(res);
        }
      },
      error: (_err: any) => {
        this.errorList =[ _err?.error?.error];
        this.spinnerService.stop();
      },
      complete :()=> {
        this.spinnerService.stop();
      },
    });
  }
  sizeToFit() {
    this.gridApi?.sizeColumnsToFit();
  }
  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
    if (this.decListService.getDecGridfilter()) {
      const retrivedValue = this.decListService.getDecGridfilter();
      this.gridApi?.setFilterModel(retrivedValue);
      this.gridApi?.onFilterChanged();
      this.gridApi?.refreshCells();
    }
  }
  changePaginationValues(values: any) {
    this.cPage = values?.cPage;
    this.tPage = values?.tPage;
    this.rpPage = values?.rpPage === 'ALL' ? this.totalRecords : values?.rpPage;
    this.gridApi?.paginationGoToPage(this.cPage - 1);
    this.gridApi?.paginationSetPageSize(this.rpPage);
  }
  onFilterChanged(_params: any) {
    this.cPage = 1;
    this.totalRecords = this.gridApi.getDisplayedRowCount();
    this.tPage = Math.ceil(this.totalRecords / this.rpPage);
    const allFilterModels = this.gridApi?.getFilterModel();
    this.decListService.setDecGridfilter(allFilterModels);
  }
  onSearch() {
    this.close();
    this.spinnerService.start();
    this.displayRowData = [];
    const obj = {
      loggedInSpecialistCode: this.userInfo.info.specialistcode,
      specialist: this.specialist,
      tracking: this.tracking ? '1' : '0',
      cost: this.cost ? '1' : '0',
    };
    this.decListService.setDecFilter(obj);
    this.decListService.searchDecisionList(obj).subscribe({
      next: (res: any) => {
        this.loadData(res);
      },
      error: (_err: any) => {
        this.errorList = [_err?.error?.error];
        this.spinnerService.stop();
      },
    });
  }
  loadData(res: any) {
    const track = res.response?.tracking;
    const cost = res.response?.cost;
    this.response = [
      ...(Array.isArray(track) ? track : []),
      ...(Array.isArray(cost) ? cost : [])
    ];
        const result = this.response.map((item: any) => {
      const data = { ...item };
      data.authtotal = this.dollarFormatter(data.authtotal);
      data.authunit = this.dollarFormatter(data.authunit);
      data.authdate = this.dateFormatter(data.authdate);
      data.approve = 'approve';
      data.reject = 'reject';
      data.reserve = 'reserve';
      data.selectedValue = 'reserve';
      data.headerApprove=false;
      data.headerReject=false;
      data.headerReserve=true;
      return data;
    });
    this.displayRowData = result;
    this.rowCount = this.displayRowData.length;
    this.totalRecords = this.response.length;
    this.tPage = Math.ceil(this.totalRecords / this.rpPage);
    this.gridApi?.hideOverlay();

  }
  do() {
    const modifiedDecisionList = this.displayRowData.map((rowData) => {
      const modifiedRowData = { ...rowData };
      if (modifiedRowData.selectedValue === 'approve') {
        modifiedRowData.decision = '0';
      } else if (modifiedRowData.selectedValue==='reject') {
        modifiedRowData.decision = '1';
      } else if (modifiedRowData.selectedValue==='reserve') {
        modifiedRowData.decision = '2';
      }
      return modifiedRowData;
    });

    const body: any = {
      loggedInSpecialistCode: this.userInfo.info.specialistcode,
      loggedUserName:this.userInfo.info.firstname,
      decisionList: modifiedDecisionList.map((modifiedRowData) => ({
        decision: modifiedRowData.decision,
        authType: modifiedRowData.authtype,
        plantCode: modifiedRowData.plantcode,
        supplier: modifiedRowData.supplier,
        partNumber: modifiedRowData.partnumber,
        pkgStartDate: this.dateFormatter(modifiedRowData.pkgstartdate),
        modelCode: modifiedRowData.modelcode ? modifiedRowData.modelcode : '',
        decisionType: modifiedRowData.decisiontype,
        authNumber: modifiedRowData.authnumber,
        rejectReason: this.comment ? this.comment : '',
        specialistCode: modifiedRowData.specialistcode,
        Step04Status: modifiedRowData.step04status,
        Step07Status: modifiedRowData.step07status,
        Step09Status: modifiedRowData.step09status,
        authTotal: modifiedRowData.authtotal,
        familyCode: modifiedRowData.familycode,
        Step: '',
      })),
    };
    const dialogRef = this.dialog.open(DialogDivisionComponent, {
      data: {
        headerName: 'decisionDelete',
        data: body,
      },
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      this.errorList=[]
      if (result && !result.status ) {
        this.errorList = [result.message[0]];
      } else if(result && result.status){
        this.onLoad();
    }
    });
  }
  selectSpecialist() {
    const dialogRef = this.dialog.open(SelectSpecialistComponent, {
      data: this.specialist,
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      this.specialist = result;
    });
  }
  close() {
    this.errorList = [];
    this.successMessage = [];
  }
  help() {
    this.router.navigate(['./master-help', 3, 'Cost Management', 49]);
  }
}
