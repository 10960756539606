import { Component, OnInit } from '@angular/core';
import { TrackingListService } from 'src/app/internal-vpacs/services-internal/tracking/tracking-list/tracking-list.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ActivatedRoute, Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { MessagePopupToastComponent } from 'src/app/core/message-popup-toast/message-popup-toast.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConstantsService } from 'src/app/services/constant.service';

@Component({
  selector: 'app-tracking-history',
  templateUrl: './tracking-history.component.html',
  styleUrls: ['./tracking-history.component.scss'],
})
export class TrackingHistoryComponent implements OnInit {
  checkList: any[] = [];
  panelOpenState = true;
  panelOpenState2 = true;
  panelOpenState3 = true;
  partInfo: any[] = [];
  partInfoContents: any[] = [];
  historyDetails: any;
  comment: any;
  paramData: any;
  requestPerson: any;
  headerList: any[] = [];
  contentList: any[] = [];
  contentListArr: any[] = [];
  isSaveDisabled = false;
  content: any;
  errorMessage: any;
  DATE_FORMAT = 'MM/dd/YYYY';
  UTC = 'UTC';
  errorList: any[] = [];
  sortedArray: any[] = [];
  
  constructor(
    private readonly trackingListService: TrackingListService,
    public readonly route: ActivatedRoute,
    public readonly router: Router,
    private readonly spinnerService: NgxUiLoaderService,
    public message: MatSnackBar,
    public datepipe: DatePipe,
    private readonly constants: ConstantsService
  ) { }

  ngOnInit(): void {
    this.spinnerService.start();
    this.route.queryParams.subscribe(
      () => {
        this.paramData = JSON.parse(sessionStorage.getItem('trackingParams') || '{}')
        if (!this.canUseComment()) {
          this.isSaveDisabled = true;
        }
      }
    )
    this.loadTrackingHistory();

    this.checkList = [
      { name: 'Initial Part List', value: '1', checked: true },
      { name: 'Send PPF to Supplier', value: '2', checked: true },
      { name: 'Toyota Receives Pre PPF', value: '3', checked: true },
      { name: 'Toyota Approves Pre PPF', value: '4', checked: true },
      { name: 'Trial Order', value: '5', checked: true },
      { name: 'Ship To Toyota', value: '6', checked: true },
      { name: 'Package Trial Approved', value: '7', checked: true },
      { name: 'Toyota Receives Final PPF', value: '8', checked: true },
      { name: 'Final PPF Approval', value: '9', checked: true },
      { name: 'Tracking Correction Information', value: '90', checked: true },
      { name: 'Update PPF', value: '20', checked: true },
      { name: 'Update Supplier Code', value: '70', checked: true },
      { name: 'Hazmat Approval', value: '', checked: true },
      { name: 'Update PPF From Supplier', value: '19', checked: true },
    ];

    this.partInfo = ['Plant', 'Model Code', 'Part Number', 'Supplier'];
  }

  canUseComment() {
    if (this.hasLeader()) {
      return false;
    }
    return true;
  }

  hasLeader() {
    if (this.paramData.leaderplant === '' || this.paramData.leaderplant === null) {
      return false;
    }
    return true;
  }

  loadTrackingHistory() {
    this.partInfoContents = [];
    this.contentListArr = [];
    this.contentList = [];
    this.headerList = [];
    this.sortedArray = [];
    this.spinnerService.start();
    const loadObj = {
      PlantCode: this.paramData?.plantcode,
      PartNumber: this.paramData?.partnumber,
      SupplierCode: this.paramData?.suppliercode,
      PkgStartDate: this.paramData?.pkgstartdate
    };
    this.trackingListService.loadTrackingHistory(loadObj).subscribe({
      next: (res: any) => {
        this.historyDetails = res;
        const items = [this.paramData?.plantcode, this.paramData?.modelcode, this.paramData?.partnumber, this.historyDetails?.supplierLongName];
        this.partInfoContents.push(...items);
        this.comment = this.historyDetails?.createComment ?? '';
        if (this.historyDetails?.createTableData) {
          let i = 0;
          this.checkList.forEach(checkItem => {
            if (checkItem.checked) {
              this.historyDetails?.createTableData.forEach((e: any, index: any) => {
                if (checkItem.value === e.step) {
                  if (e.step === '90') { // Tracking Correction Information
                    this.headerList.push(this.checkList[i].name.toUpperCase());
                    this.createContent(this.historyDetails.createTableData, checkItem.value, index);
                  }
                  else if (e.step === '20') { // Case: Update PPF
                    this.headerList.push(this.checkList[i].name.toUpperCase());
                    this.createContent(this.historyDetails.createTableData, "20", index);
                  }
                  else if (e.step === '70') { // Case: Update Supplier Code
                    this.headerList.push(this.checkList[i].name.toUpperCase());
                    this.createContent(this.historyDetails.createTableData, "70", index);
                  }
                  else if(e.step === '19'){
                    this.headerList.push(this.checkList[i].name.toUpperCase());
                    this.createContent(this.historyDetails.createTableData, "19", index);
                  }
                  else {
                    this.headerList.push(`${this.checkList[i].name.toUpperCase()} ( Step ${(i + 1)} )`);
                    this.createContent(this.historyDetails.createTableData, checkItem.value, index);
                  }
                }
                else if(checkItem.value === ''){
                  if (e.step === '21') { // Case: hz update ppf
                    this.headerList.push(`${this.checkList[i].name.toUpperCase()} ( Update PPF )`);
                    this.createContent(this.historyDetails.createTableData, "21", index);
                  }
                  else if (e.step === '24') { // Case: step 4 hz
                    this.headerList.push(`${this.checkList[i].name.toUpperCase()} ( Step 4 )`);
                    this.createContent(this.historyDetails.createTableData, "24", index);
                  }
                  else if (e.step === '27') { // Case: step 7 hz
                    this.headerList.push(`${this.checkList[i].name.toUpperCase()} ( Step 7 )`);
                    this.createContent(this.historyDetails.createTableData, "27", index);
                  }
                  else if (e.step === '29') { // Case: step 9 hz
                    this.headerList.push(`${this.checkList[i].name.toUpperCase()} ( Step 9 )`);
                    this.createContent(this.historyDetails.createTableData, "29", index);
                  }
                }
              });
            }
            i++;
          });
        }
        else {
          this.errorList.push('No Data Found !!!');
        }
        this.contentListArr.forEach((e: any, i: any) => {
          const obj = {
            title: this.headerList[i],
            content: e
          }
          this.sortedArray.push(obj)
        })
        this.sortedArray.sort((a, b) => {
          return new Date(b.content[0].value).getTime() - new Date(a.content[0].value).getTime();
        });
        this.sortedArray.forEach(element => {
          element.content[0].value = this.datepipe.transform(element.content[0].value, this.DATE_FORMAT, this.UTC)
        });
        this.spinnerService.stop();
      },
      error: () => {
        this.spinnerService.stop();
      }
    });
  }

 changeNullData(data: any) {
    if(data === null || data?.length === 0 || data === undefined) {
      return " - ";
    } else {
      return data;
    }	
  }

  createContent(table: any, step: any, index: any) {
    const working = this.getWorking(table[index].working);
    this.requestPerson = table[index]?.specialistname;
    if (table[index]?.working === '1') {
      this.requestPerson = table[index]?.supplierrequest;
    }
    const supName = 'Supplier Name/Supplier Code';
    const attachName = 'Attachment File Name';
    const reqChange = 'Requested Changes';
    const reasonReq = 'Reason Requested';

    this.contentList = [];
    const supplierNameCode =  `${this.changeNullData(table[index].suppliername)}/${this.changeNullData(table[index].suppliercode)}`;
    if (step === '1') { // Initial part list

      const contentHead = ['Date','Specialist', 'File', supName]
      const contentVal = [table[index].ins_date,table[index].specialistname, table[index].ppfver, supplierNameCode]
      contentHead.forEach((e: any, i: any) => {
        const cont = {
          name: e,
          value: contentVal[i]
        }
        this.contentList.push(cont);
      });
      this.contentListArr.push(this.contentList);
      return this.contentListArr;
    }
    else if (step === '2') { // Send PPF to Supplier
      if (table[index].step03status === '5') { // request as Final
        const contentHead = ['Date', 'From', 'Reason of Request as Final', 'Comment', attachName, 'Working', 'File', supName]
        const contentVal = [
          table[index].ins_date,
          this.changeNullData(table[index].fromperson),
          this.changeNullData(table[index].finalreason),
          this.changeNullData(table[index].note),
          table[index].createContent?.attach_file_list,
          working,
          table[index].ppfver,
          supplierNameCode
        ]
        contentHead.forEach((e: any, i: any) => {
          const cont = {
            name: e,
            value: contentVal[i]
          }
          this.contentList.push(cont);
        });
        this.contentListArr.push(this.contentList);
        return this.contentListArr;
      }
      else {
        const contentHead = ['Date', 'From', 'Comment', attachName, 'Working', 'File', supName]
        const contentVal = [
          table[index].ins_date,
          this.changeNullData(table[index].fromperson),
          this.changeNullData(table[index].note),
          table[index].createContent?.attach_file_list,
          working,
          table[index].ppfver,
          supplierNameCode
        ]
        contentHead.forEach((e: any, i: any) => {
          const cont = {
            name: e,
            value: contentVal[i]
          }
          this.contentList.push(cont);
        });
        this.contentListArr.push(this.contentList);
        return this.contentListArr;
      }
    }
    else if (step === '3') { // TOYOTA Receives Pre. PPF
      return this.contentListFun(table, index);
    }
    else if (step === '4') { // TOYOTA Approves Pre. PPF
      const resultStr = this.changeTrackingResult(table[index].result, table[index].parentpartnumber);
      if (table[index].step08status === '5') { // request as Final
        const contentHead = ['Date', 'From', 'Reason of approve as final', 'Result', 'Requested',
          reqChange, 'Reason', reasonReq, 'Comment', attachName, 'Working', 'File', supName]
        const contentVal = [
          table[index].ins_date,
          this.changeNullData(table[index].fromperson),
          this.changeNullData(table[index].finalreason),
          this.changeNullData(resultStr),
          this.changeNullData(table[index].createContent?.chk_request_str),
          this.changeNullData(table[index].requestmessage),
          this.changeNullData(table[index].createContent?.chk_reason_str),
          this.changeNullData(table[index].reasonmessage),
          this.changeNullData(table[index].note),
          table[index].createContent?.attach_file_list,
          working,
          table[index].ppfver,
          supplierNameCode
        ]
        contentHead.forEach((e: any, i: any) => {
          const cont = {
            name: e,
            value: contentVal[i]
          }
          this.contentList.push(cont);
        });
        this.contentListArr.push(this.contentList);
        return this.contentListArr;
      }
      else {
        const contentHead = ['Date', 'From', 'Reason of approve as final', 'Result',
          reqChange, 'Reason', reasonReq, 'Comment', attachName, 'Working', 'File', supName]
        const contentVal = [
          table[index].ins_date,
          this.changeNullData(table[index].fromperson),
          this.changeNullData(resultStr),
          this.changeNullData(table[index].createContent?.chk_request_str),
          this.changeNullData(table[index].requestmessage),
          this.changeNullData(table[index].createContent?.chk_reason_str),
          this.changeNullData(table[index].reasonmessage),
          this.changeNullData(table[index].note),
          table[index].createContent?.attach_file_list,
          working,
          table[index].ppfver,
          supplierNameCode
        ]
        contentHead.forEach((e: any, i: any) => {
          const cont = {
            name: e,
            value: contentVal[i]
          }
          this.contentList.push(cont);
        });
        this.contentListArr.push(this.contentList);
        return this.contentListArr;
      }
    }
    else if (step === '5') { // Trial Order
      const contentHead = ['Date', 'Qty of Package', 'Qty Parts/Container', 'Attention Plant',
        'Dock Code', 'Attention Specialist', 'Cost', 'File', supName]
      const contentVal = [
        table[index].ins_date,
        this.changeNullData(table[index].pkgprototypes),
        this.changeNullData(table[index].trialqty),
        this.changeNullData(table[index].attnplant),
        this.changeNullData(table[index].dockcode),
        this.changeNullData(table[index].attnspecialist),
        `$ ${this.changeNullData(table[index].cost1)}`,
        table[index].ppfver,
        supplierNameCode
      ]
      contentHead.forEach((e: any, i: any) => {
        const cont = {
          name: e,
          value: contentVal[i]
        }
        this.contentList.push(cont);
      });
      this.contentListArr.push(this.contentList);
      return this.contentListArr;
    }
    else if (step === '6') { // Ship to TOYOTA
      const contentHead = ['Date', 'Attention Specialist', 'Arrive Date', 'Carrier of Name',
        'Comment', 'File', supName]
      const contentVal = [
        table[index].ins_date,
        this.changeNullData(table[index].fullname),
        this.changeNullData(this.datepipe.transform(table[index]?.arrivedate, this.DATE_FORMAT, this.UTC)),
        this.changeNullData(table[index].carriername),
        this.changeNullData(table[index].note),
        table[index].ppfver,
        supplierNameCode
      ]
      contentHead.forEach((e: any, i: any) => {
        const cont = {
          name: e,
          value: contentVal[i]
        }
        this.contentList.push(cont);
      });
      this.contentListArr.push(this.contentList);
      return this.contentListArr;
    }
    else if (step === '7') { // Pkg Trial Approved
      const resultStr = this.changeTrackingResult(table[index].result, table[index].parentpartnumber);
      const contentHead = ['Date', 'From', 'Result', 'Requested', reqChange, 'Reason',
        reasonReq, 'Comment', attachName, 'Working', 'File', supName]
      const contentVal = [
        table[index].ins_date,
        this.changeNullData(table[index].fromperson),
        this.changeNullData(resultStr),
        this.changeNullData(table[index].createContent?.chk_request_str),
        this.changeNullData(table[index].requestmessage),
        this.changeNullData(table[index].createContent?.chk_reason_str),
        this.changeNullData(table[index].reasonmessage),
        this.changeNullData(table[index].note),
        table[index].createContent?.attach_file_list,
        working,
        table[index].ppfver,
        supplierNameCode
      ]
      contentHead.forEach((e: any, i: any) => {
        const cont = {
          name: e,
          value: contentVal[i]
        }
        this.contentList.push(cont);
      });
      this.contentListArr.push(this.contentList);
      return this.contentListArr;
    }
    else if (step === '8') { // TOYOTA Receives Final PPF
      return this.contentListFun(table, index);
    }
    else if (step === '9') { //	Final PPF Approval
      const resultStr = this.changeTrackingResult(table[index].result, table[index].parentpartnumber);
      const contentHead = ['Date', 'From', 'Result', 'Requested', reqChange, 'Reason',
        reasonReq, 'Comment', attachName, 'File', supName]
      const contentVal = [
        table[index].ins_date,
        this.changeNullData(table[index].fromperson),
        this.changeNullData(resultStr),
        this.changeNullData(table[index].createContent?.chk_request_str),
        this.changeNullData(table[index].requestmessage),
        this.changeNullData(table[index].createContent?.chk_reason_str),
        this.changeNullData(table[index].reasonmessage),
        this.changeNullData(table[index].note),
        table[index].createContent?.attach_file_list,
        table[index].ppfver,
        supplierNameCode
      ]
      contentHead.forEach((e: any, i: any) => {
        const cont = {
          name: e,
          value: contentVal[i]
        }
        this.contentList.push(cont);
      });
      this.contentListArr.push(this.contentList);
      return this.contentListArr;
    }
    else if (step === '90') { // Tracking Correction
      const contentHead = ['Date', 'From', 'Correction Information', supName]
      const contentVal = [
        table[index].ins_date,
        this.changeNullData(table[index].specialistname),
        this.changeNullData(table[index].correctinfo),
        supplierNameCode
      ]
      contentHead.forEach((e: any, i: any) => {
        const cont = {
          name: e,
          value: contentVal[i]
        }
        this.contentList.push(cont);
      });
      this.contentListArr.push(this.contentList);
      return this.contentListArr;
    } else if (step === '70') { // Update Supplier Code
      const contentHead = ['Date',supName, 'Supplier Request', 'Specialist Name'] 
      const contentVal = [
        table[index].ins_date,
        supplierNameCode,
        this.changeNullData(table[index].supplierrequest),
        this.changeNullData(table[index].specialistname)
      ]
      contentHead.forEach((e: any, i: any) => {
        const cont = {
          name: e,
          value: contentVal[i]
        }
        this.contentList.push(cont);
      });
      this.contentListArr.push(this.contentList);
      return this.contentListArr;
    } else if (step === '20' || step === "19") { // Update PPF
      const resultStr = this.changeTrackingResultforStep20(table[index].result, table[index].parentpartnumber);
      const contentHead = ['Date', 'Request Person', 'Result', 'Reason(what)', 'Reason(what)',
        'Reason(why)', 'Reason(why)', 'Reject Reason', 'File', supName]
      const contentVal = [
        table[index].ins_date,
        this.changeNullData(this.requestPerson),
        this.changeNullData(resultStr),
        this.changeNullData(table[index].createContent?.chk_request_str),
        this.changeNullData(table[index].requestmessage),
        this.changeNullData(table[index].createContent?.chk_reason_str),
        this.changeNullData(table[index].reasonmessage),
        this.changeNullData(table[index].rejectreason),
        table[index].ppfver,
        supplierNameCode
      ]
      contentHead.forEach((e: any, i: any) => {
        const cont = {
          name: e,
          value: contentVal[i]
        }
        this.contentList.push(cont);
      });
      this.contentListArr.push(this.contentList);
      return this.contentListArr;
    } else if (step === '21' || step === '24' || step === '27' || step === '29') { // Hazmat
      const contentHead = ['Date', 'Specialist', 'Hazmat Comment', 'Result', 'File', 'Supplier Name/Supplier Code']
      const contentVal = [table[index].ins_date,
        this.changeNullData(this.requestPerson),
        this.changeNullData(table[index].reasonmessage),
        this.changeNullData(table[index].rejectreason),
        table[index].ppfver,
        supplierNameCode
      ]
      contentHead.forEach((e: any, i: any) => {
        const cont = {
          name: e,
          value: contentVal[i]
        }
        this.contentList.push(cont);
      });
      this.contentListArr.push(this.contentList);
      return this.contentListArr;
    }
    this.spinnerService.stop();
    return null;
  }

  getWorking(work: any) {
    if (work === '1') {
      return "Work Supplier -> Confirm Fabricator";
    } else if (work === '2') {
      return "Work Fabricator -> Confirm Supplier";
    } else if (work === '3') {
      return "Work Supplier and Fabricator";
    } else if (work === '4') {
      return "Work Supplier -> ReadOnly Fabricator";
    } else if (work === '5') {
      return "Work Fabricator -> ReadOnly Supplier";
    } else if (work === '6') {
      return "Work Supplier -> Ignore Fabricator";
    } else if (work === '7') {
      return "Work Fabricator -> Ignore Supplier";
    }
    return "";
  }

  changeTrackingResult(result: any, siblingPart: any) {
    if (result === "0") {
      return "Approve";
    }
    else if (result === "1") {
      return "Approve with Changes";
    }
    else if (result === "2") {
      return "Reject";
    }
    else if (result === "3") {
      return `Trial skip  [sibling part is ${siblingPart} ]`;
    }
    else if (result === "4") {
      return "Approve as final";
    }
    else {
      return "";
    }
  }

  changeTrackingResultforStep20(result: any, _siblingPart: any) {
    if (result === "1") {
      return "Approve";
    }
    else if (result === "2") {
      return "Reject";
    }
    else {
      return "";
    }
  }

  changeReasonCheckCode(code: any) {
    if (code === "1") {
      return "Safety";
    } else if (code === "2") {
      return "Quality";
    } else if (code === "3") {
      return "Workability";
    } else if (code === "4") {
      return "Over Weight";
    } else if (code === "5") {
      return "Poor Efficiency";
    } else if (code === "0") {
      return "Other";
    }
    return "";
  }

  changeRequestCheckCode(code: any) {
    if (code === "1") {
      return "Container Type";
    } else if (code === "2") {
      return "Qty. per Container";
    } else if (code === "3") {
      return "Container Size";
    } else if (code === "4") {
      return "Dunnage";
    } else if (code === "5") {
      return "Part Orientation";
    } else if (code === "0") {
      return "Other";
    }

    return "";
  }

  save() {
    this.spinnerService.start();
    const loadObj = {
      PlantCode: this.paramData?.plantcode,
      PartNumber: this.paramData?.partnumber,
      SupplierCode: this.paramData?.suppliercode,
      PkgStartDate: this.paramData?.pkgstartdate,
      comment: this.comment
    };
    this.trackingListService.btnSave(loadObj).subscribe({
      next: (res: any) => {
        if (res.response?.status) {
          this.openMessageBox(res.response?.msg, 'success')
          this.router.navigateByUrl('/tracking-list')
        }
        this.spinnerService.stop();
      },
      error: (err: any) => {
        this.spinnerService.stop();
        this.errorMessage = { errorCode: `API-${err.status}`, childErrorCode: err.status }
        window.scrollTo(0, 0);
      }
    });
  }

  openMessageBox(message: string, type: string) {
    this.message.openFromComponent(MessagePopupToastComponent, {
      duration: 3000,
      data: {
        type: type,
        message: message
      }
    })
  }

  onCancel() {
    this.router.navigateByUrl('/tracking-list');
  }

  onChange(event: any, value: any) {
    if (!event.target.checked) {
      this.checkList[value].checked = false;
    }
    else{
      this.checkList[value].checked = true;
    } 
  }

  viewPPF(ppfVer: any){
    const plantCode = this.constants.getTypeFromPlantCode(this.paramData?.plantcode)
    sessionStorage.setItem('ppfcloseFromTracking','0')  
    if (plantCode === 'SERVICE') {
      sessionStorage.setItem('partnumber',this.paramData?.partnumber)
      this.router.navigate(['/tracking-list/tracking-history/serviceparts-pdf',this.paramData.ppfcode, ppfVer, 1, 2])
    }else if(plantCode === 'ASSY'){
      sessionStorage.setItem('partnumber', this.paramData?.partnumber)
      this.router.navigate(['/tracking-list/tracking-history/accessoryParts-pdf', this.paramData.ppfcode, ppfVer, 1, 2])
    }
    else {
      window.open(`/tracking-list/tracking-history/ppf/${this.paramData.ppfcode}/${ppfVer}/1/2`, '_blank');
    }
    sessionStorage.setItem('ppfcloseFromTracking','1')  
  }

  help() {
    this.router.navigate(['./master-help', 2, "Tracking", 24])
  }

  contentListFun(table: any, index: any) {
    const supplierNameCode =  `${this.changeNullData(table[index].suppliername)}/${this.changeNullData(table[index].suppliercode)}`;
    const contentHead = ['Date', 'From', 'Comment', 'File', 'Supplier Name/Supplier Code']
    const contentVal = [table[index].ins_date,
     this.changeNullData(table[index].fullname),
      this.changeNullData(table[index].note),
       table[index].ppfver,
       supplierNameCode
      ]
    contentHead.forEach((e: any, i: any) => {
      const cont = {
        name: e,
        value: contentVal[i]
      }
      this.contentList.push(cont);
    });
    this.contentListArr.push(this.contentList);
    return this.contentListArr;
  }
}
